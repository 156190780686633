import { ActionTypes } from "../constants/action-types"

const initialState ={
    products:[]
}



export const productReducer= (state=initialState,{type,payload })=>{
switch (type) {
case ActionTypes.SET_PRODUCTS:
    return {...state,products: payload}





    default :
    return state;

}

}
export const subcatReducer= (state={},{type,payload })=>{

    switch (type) {
    case ActionTypes.SUBCAT_ID:
        return {...state, product:payload}


        default :
        return state;



    }

    }

    export const productsIDReducer= (state={},{type,payload })=>{

        switch (type) {
        case ActionTypes.PRODUCT_ID:
            return {...state, product_id:payload}


            default :
            return state;



        }

        }
export const ownProductsReducer = (state = {}, { type, payload }) => {

    switch (type) {
        case ActionTypes.OWN_PRODUCT:
            return { ...state, own_product: payload }


        default:
            return state;



            }

        }
    export const checkboxIDReducer= (state={},{type,payload })=>{

      switch (type) {
      case ActionTypes.CHECKBOX_ID:
          return {...state, checkbox_id:payload}


          default :
          return state;



      }

    }
export const searchIdReducer = (state = {}, { type, payload }) => {

    switch (type) {
        case ActionTypes.SEARCH_STATE:
            return { ...state, search_state: payload }


        default:
            return state;



    }

}
export const offerIdReducer = (state = {}, { type, payload }) => {

    switch (type) {
        case ActionTypes.OFFER_STATE:
            return { ...state, offer_state: payload }


        default:
            return state;



    }

}
