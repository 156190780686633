import React, { useState, useEffect } from "react";
import axios from "axios";
import ScrollAnimation from "react-animate-on-scroll";
import Search from "./searchbox";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CLink } from "@coreui/react";
const Seller = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const getLang = localStorage.getItem("language");
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=Seller&lang=${getLang}`
      )
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  }, [loading, getLang]);
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="cstm_header seller_header">
        <Search />
      </div>
      <div className="buyer_seller_cont">
        <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="heading" data-aos="fade-up">
                  {t("staticPages.seller")}
                </h2>
              </div>
            </div>
            <div className="buyer_seller_cont_outer" data-aos="fade-up">
              {data.map((item) => {
                return (
                  <>
                    {item.language.code === getLang
                      ? item.section.map((el, index) => {
                          return (
                            <>
                              <div className="left">
                                <img
                                  width=""
                                  height=""
                                  src={`${el.image_upload}`}
                                  alt="timeline"
                                />
                              </div>
                              <div className="right">
                                <h4>{el.subtitle}</h4>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: `${el.description_html}`,
                                  }}
                                ></p>
                                <CLink to="/signup">
                                  <button className="web-btn mt-3">
                                    {t("indexPage.section2.sellerText")}
                                  </button>
                                </CLink>
                              </div>
                            </>
                          );
                        })
                      : null}
                  </>
                );
              })}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Seller;
