import React from 'react'
import {
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import { useHistory } from "react-router";
import errorImg from "../../../../assets/images/error.svg"
const PaymentError = () => {
    const history = useHistory();
    return (
      <div className="c-app bg-white c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center text-center">
            <CCol md="6">
              <div className="clearfix">
                <img
                  width=""
                  height=""
                  src={errorImg}
                  alt="thank you"
                  className="thankyou_img"
                />
              </div>
              <div>
                <h2 className="pt-3 display-3">PAYMENT FAILED!</h2>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => history.push({ pathname: "/index" })}
                >
                  Return Home
                </button>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
}

export default PaymentError
