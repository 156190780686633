import {combineReducers} from 'redux';
import { paymentReducer } from './paymentReducer';
import { productReducer, subcatReducer, productsIDReducer, checkboxIDReducer, ownProductsReducer, searchIdReducer, offerIdReducer } from './productsReducer';
import { contractsReducer } from './contractsReducer';
import { userReducer } from './userReducers';
const reducers = combineReducers({
    allProducts: productReducer,
    getPayments: paymentReducer,
    product:subcatReducer,
    product_id:productsIDReducer,
    checkbox_id: checkboxIDReducer,
    contrcats: contractsReducer,
    own_product: ownProductsReducer,
    search_state: searchIdReducer,
    offer_state: offerIdReducer,
    username: userReducer
})


export default reducers;
