import React, { useState, useEffect } from "react";
import {
    own_product,
    product_id,
} from "../../../../redux/actions/Productactions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import NoImage from "../../../../assets/images/no-image.jpg";
import { useTranslation } from "react-i18next";

const Card = ({ object }) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState();
    const [symbol, setSymbol] = useState("$");
    let lang = localStorage.getItem("language");
    // const [productName, setProductName] = useState([])
    let productName = [];
    useEffect(() => {
      if (object.image.length === 0) {
        setImage(NoImage);
        setLoading(false);
      } else {
        setImage(object.image[0]);
        setLoading(false);
      }
      if (object.price_currency === "DONG") {
        setSymbol("₫");
      }
      if (object !== undefined) {
        for (let obj of object.product_name) {
          if (obj.language.code === lang) {
            productName.push({ name: obj.name });
          }
        }
      }
      // eslint-disable-next-line
    }, [loading, productName]);

    const dispatch = useDispatch();
    return (
      <div
        onClick={() => {
          dispatch(product_id(object.id));
          dispatch(own_product(object.current_user_product));
          history.push({ pathname: `/products/${object.id}` });
        }}
        className="product-card"
      >
        <div className="img-outer">
          <img
            width="100"
            height="auto"
            src={image}
            alt="Product"
            className="product_img"
          />
          {object.estimate_time > 0 ? (
            <div className="time">
              {object.estimate_time} {t("myproduct.product.day")}
            </div>
          ) : (
            <div className="time"> {t("myproduct.product.product_ready")}</div>
          )}
        </div>
        <div className="name">
          {object?.product_name.map((item, index) =>
            item.language.code === lang ? (
              <span key={index}>
                {item.name.length > 10
                  ? item.name.substring(0, 10) + "..."
                  : item.name}
              </span>
            ) : null
          )}
        </div>
        <div className="price">
          {object?.pricing_type === "FIXED" ? (
            <span>
              {" "}
              {symbol} {object?.price_to} /KG
            </span>
          ) : (
            <>
              <span>
                {" "}
                {symbol} {object?.price_from} - {symbol} {object?.price_to} /KG
              </span>
            </>
          )}
        </div>
      </div>
    );
};

export default Card;
