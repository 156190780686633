import React, { useEffect, useState } from "react";
import Search from "./searchbox";
import "./index.css";
import ReactFaqList from "react-faq-list/dist";
import Aos from "aos";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Works = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  let getLang = localStorage.getItem("language");

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=How It Works&lang=${getLang}`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/faq/?lang=${getLang}`)
      .then((res) => {
        setFaqData(res.data.data);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  }, [getLang]);

  return (
    <div>
      <div className="cstm_header how_it_works_header mais">
        <Search />
      </div>

      <div>
        <div className="container">
          <ScrollAnimation animateIn="fade-up" animateOnce={true}>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="heading">{t("staticPages.how_it_work")}</h2>
              </div>
            </div>
          </ScrollAnimation>
          <div className="row">
            <div className="col-lg-8 m-auto">
              <ul className="timeline">
                {data.map((item, i) => {
                  return (
                    <div key={i}>
                      {item.language.code === getLang
                        ? item.section.map((el, index) => {
                            return index % 2 ? (
                              <li key={index}>
                                <div className="direction-l">
                                  <div className="flag-wrapper">
                                    <span
                                      className="hexa"
                                      data-aos="zoom-in"
                                    ></span>

                                    <ScrollAnimation
                                      animateIn="fadeInUp"
                                      animateOnce={true}
                                    >
                                      <h6>{index + 1}</h6>
                                    </ScrollAnimation>

                                    <ScrollAnimation
                                      animateIn="fadeInRight"
                                      animateOnce={true}
                                    >
                                      <img
                                        width="100"
                                        height="auto"
                                        src={`${el.image_upload}`}
                                        alt="timeline"
                                      />
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                      animateIn="fade-right"
                                      animateOnce={true}
                                    >
                                      <h4 data-aos="fade-left">{el.title}</h4>

                                      <p data-aos="fade-left">{el.subtitle}</p>
                                    </ScrollAnimation>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              <li key={index}>
                                <div className="direction-r">
                                  <div className="flag-wrapper">
                                    <span
                                      className="hexa"
                                      data-aos="zoom-in"
                                    ></span>
                                    <ScrollAnimation
                                      animateIn="fade-up"
                                      animateOnce={true}
                                    >
                                      <h6 data-aos="fade-up">{index + 1}</h6>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                      animateIn="fadeInLeft"
                                      animateOnce={true}
                                    >
                                      <img
                                        width="150"
                                        height="auto"
                                          src={`${el.image_upload}`}
                                        alt="timeline"
                                      />
                                    </ScrollAnimation>

                                    <ScrollAnimation
                                      animateIn="fade-right"
                                      animateOnce={true}
                                    >
                                      <h4 data-aos="fade-left">{el.title}</h4>

                                      <p data-aos="fade-left">{el.subtitle}</p>
                                    </ScrollAnimation>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        : null}
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container faqs_cont direita">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="heading">{t("staticPages.faq")}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ReactFaqList
              questionStyles
              answerStyles
              data={faqData}
              questionKeyName={"question"}
              answerKeyName={"answer"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
