import React, { useState } from "react";
import { CButton, CInput, CLink } from "@coreui/react";
import Logo from "../../../assets/images/newLogo.png";
import verify from "../../../assets/images/verify.png";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import ChangePassword from "./change-password";
import { useTranslation } from "react-i18next";

const Verify = (val) => {
	const { t } = useTranslation('common');
	const lang = localStorage.getItem("language");
	const [data, setData] = useState({
		code: "",
	});
	const [token, settoken] = useState(null);
	const [variable, setVariable] = useState(false);

	const getotp = async () => {
		let value;

		if (val.value.phone !== null) {
			value = {};
			value.email_or_phone = val.value.phone;
			value.verification_type = "sms";
		} else if (val.value.email !== null) {
		  value = {};
		  value.email_or_phone = val.value.email;
		  value.verification_type = "email";
	  }
		await axios
			.post(`${process.env.REACT_APP_BASE_URL}auth/forgetpassword/?lang=${lang}`, value)
			.then((res) => {
				if (res.status === 200) {
					toast.success(`${t("errorMessages.signup.verification_success")}`);
				}
			})
			.catch((err) => {
				toast.error("an error occured");
			});
	};
	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setData({ ...data, [name]: value });
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		let value;

		if (val.value.phone !== null) {
			value = {};
			value.email_or_phone = val.value.phone;
			value.verification_type = "sms";
			value.otp = data.code;
			value.page = "forgot_password";
		} else if (val.value.email !== null) {
			value = {};
			value.email_or_phone = val.value.email;
			value.verification_type = "email";
			value.otp = data.code;
			value.page = "forgot_password";
		}

		await axios
			.post(`${process.env.REACT_APP_BASE_URL}auth/verify-otp/?lang=${lang}`, value)
			.then((res) => {
				if (res.status === 200) {
				  settoken(res.data.token);
				  setVariable(true);
		  }
		  })
		  .catch((err) => {
			  if (err.response !== undefined) {
				  for (let i of err.response.data.message) {
					  toast.error(`${i.error}`);
				}
			} else {
				toast.error("an error occured");
			}
		});
	};
  return (
    <div>
      {variable === false && (
        <div className="login_signup" style={{ backgroundColor: "white" }}>
          <div className="left" data-aos="zoom-in">
            <img width="100" height="auto" src={verify} alt="timeline" />
          </div>
          <div className="right" data-aos="fade-left">
            <CLink to="/">
              <img width="100" height="auto" src={Logo} alt="Logo" />
            </CLink>
            <h2 className="heading">{t("Verify.title")}</h2>
            <p>
              {t("Verify.sub_title")}
              <br />
            </p>
            <form className="verify_form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="code">{t("Verify.label")}</label>
                <CInput
                  name="code"
                  id="code"
                  onChange={handleChange}
                  className="form-control"
                  placeholder="----"
                />
              </div>
              <div className="form-group">
                <CButton type="submit" id="verify" className="btn cstm_btn">
                  {t("Verify.buttonText")}
                </CButton>
              </div>
              <ToastContainer />
            </form>
            <h6>
              {t("Verify.footerText")}{" "}
              <CLink onClick={getotp} className="head">
                {t("Verify.resend")}
              </CLink>
            </h6>
          </div>
        </div>
      )}
      {variable === true && <ChangePassword props={token} val={val} />}
    </div>
  );
};

export default Verify;
