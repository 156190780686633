import React, { useState, useEffect } from "react";
import Search from "../../searchbox";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import dummyProfile from "../../../../../assets/images/profile.jpeg";
import Loader from "../../../../Components/loader/index";
import { useHistory } from "react-router";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";

const ServiceCategoryUserList = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const paramId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState([]);
  const [cateogory_value, setCateogary_Value] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  // const [symbol, setSymbol] = useState("$");
  const MAX_LENGTH = 150;
  const lang = localStorage.getItem("language");

  const getData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/serviceprovider-from-category/?category_id=${paramId}&lang=${lang}`
      )
      .then((res) => {
        setBody(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response);
      });
    // if (body) {
    //     if (body.price_currency === "DONG") {
    //         setSymbol("₫");
    //     }
    // }
  };
  useEffect(() => {
    getData();
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/category-subcategory/?type=SERVICE&lang=${lang}`
      )
      .then((res) => {
        setCateogary_Value(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [loading, lang]);

  useEffect(() => {
    if (cateogory_value !== undefined) {
      for (let i = 0; i < cateogory_value.length; i++) {
        if (cateogory_value[i].id === paramId) {
          setCategoryTitle(cateogory_value[i].title[0].name);
        }
      }
    }
  }, [loading, cateogory_value, paramId]);
  const serviceView = (value) => {
    history.push({ pathname: `/servicecategory/serviceList/${value}` });
  };
  return (
    <>
      <div className="service_detail">
        <div className="cstm_header service_details_header">
          <Search />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="service_provider_cont">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2
                    className="heading aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    {categoryTitle}{" "}
                    {t("serviceCategory.serviceCategoryList.title")}
                  </h2>
                </div>
              </div>
              <div className="row">
                {body.length > 0 ? (
                  body.map((item, index) => {
                    return (
                      <div
                        className="col-md-6"
                        key={index}
                        onClick={() => serviceView(item.username)}
                      >
                        <div
                          className="service_provider_cont_box aos-init"
                          data-aos="fade-up"
                        >
                          <div className="info_outer">
                            <div className="info">
                              <img
                                width=""
                                height=""
                                src={
                                  item.profile_image !== null &&
                                  item.profile_image.length > 0
                                    ? item.profile_image
                                    : dummyProfile
                                }
                                alt="dummy"
                              />
                              <div className="d-flex flex-column justify-content-center">
                                <div className="name">
                                  {item.first_name === null ||
                                  item.first_name.length === 0
                                    ? item.username
                                    : item.first_name}{" "}
                                  {item.last_name}
                                </div>

                                <div className="rating d-flex">
                                  <ReactStars
                                    size={20}
                                    count={5}
                                    value={
                                      item.rating.length === 0 ||
                                      item.rating.rate__avg === null
                                        ? 0
                                        : item.rating.rate__avg
                                    }
                                    edit={false}
                                    a11y={true}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={
                                      <i className="fas fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fas fa-star"></i>}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="provider_details w-100">
                            {item.profile_summary.length === 0 ? (
                              <p>No Summary avaibale</p>
                            ) : (
                              <>
                                {" "}
                                {item.profile_summary.length > MAX_LENGTH ? (
                                  <p>
                                    {`${item.profile_summary.substring(
                                      0,
                                      MAX_LENGTH
                                    )}...`}
                                  </p>
                                ) : (
                                  <spam
                                    onClick={() => serviceView(item.username)}
                                    className=""
                                  >
                                    Read More{" "}
                                    <i className="fas fa-chevron-right"></i>
                                  </spam>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center">
                    <h2>{t("serviceCategory.serviceCategoryList.no_found")}</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ServiceCategoryUserList;
