import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import { useHistory } from "react-router";
const Page500 = () => {
  const history = useHistory();
  return (
    <div className="c-app bg-white c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center text-center">
          <CCol md="6">
            <span className="clearfix">
              <h1 className="display-2 mr-4">500</h1>
            </span>
            <div>
              <h2 className="pt-3 display-3">SERVER ERROR</h2>
              <p className="text-muted float-left">
                Sorry, the resource you're looking for cannot be
                displayed.Please refresh this page or try again later.
              </p>
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => history.push({ pathname: "/index" })}
              >
                Return Home
              </button>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page500;
