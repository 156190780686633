import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    CCol,
    CContainer,
    CRow
} from '@coreui/react';
import { useHistory } from "react-router";
import thankImg from "../../../../assets/images/Successful purchase-cuate.png"
import Rating from "../Rating/Rating";
import Modal from "../confirmModal/ConfirmModal";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Loader from "../../../Components/loader/index";
const Thankyou = (props) => {
  const paramId = props.match.params.id;
  console.log(paramId);
  const url = props.match.url;
  const currentValue = url.split("/");
  const current_value = currentValue[currentValue.length - 2];
  console.log(current_value, "current_value");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [productuser, setProductuser] = useState("");
  const [ratingPop, setRatingPop] = useState(true);
  const [userName, setUserName] = useState();
  const lang = localStorage.getItem("language");

  let token = localStorage.getItem("accessToken");
  useEffect(() => {
    console.log("fsd");
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/order-b/?id=${paramId}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data, "rtesssssssssssss");
        setProductuser(res.data.data.product);
        setUserName(res.data.data.user);
        setLoading(false);
      })
      .catch((err) => toast.error(err.response.data.detail));
    // eslint-disable-next-line
  }, [loading]);
  const onCancel = () => {
    setRatingPop(false);
    history.push({ pathname: "../order" });
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="c-app bg-white c-default-layout flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center text-center">
              <CCol md="6">
                <div className="clearfix">
                  <img
                    width=""
                    height=""
                    src={thankImg}
                    alt="thank you"
                    className="thankyou_img"
                  />
                </div>
                <div>
                  <h2 className="pt-3 display-3"> SUCCESSFULLY PAID!</h2>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => history.push({ pathname: "/order" })}
                  >
                    Return Home
                  </button>
                </div>
              </CCol>
            </CRow>
          </CContainer>
          <Modal
            displayModal={ratingPop}
            closeModal={() => onCancel()}
            modalBody={
              <>
                <div className="m-auto p-4 service_modal">
                  <h4 className="mt-3 mb-3">Rate your experience</h4>
                  <h4>to</h4>

                  <h4 className="user_name">{productuser.user?.username}</h4>
                  <div className="react-star">
                    <Rating
                      username={userName.username}
                      closeModal={() => onCancel()}
                      productId={userName.id}
                    />
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}
    </>
  );
};
export default Thankyou;