import React, { useState } from "react";
import { subcatId } from "../../../../redux/actions/Productactions";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

function Cateogery({ title, subCategories }) {
  const [hasClicked, setHasClicked] = useState(false);
  const dispatch = useDispatch();
  const lang = localStorage.getItem("language");
  return (
    <div className="category">
      <ul>
      {title.length > 0 && title !== null
          ? title.map((item, index) =>
          item != null ? (
              <li
                key={index}
              className="category__header"
              onClick={() => {
                setHasClicked(!hasClicked);
              }}
            >
              <h3>{item}</h3>

              {hasClicked ? (
                <ArrowDropUpIcon className="dropdown" />
              ) : (
                <ArrowDropDownIcon className="dropdown" />
              )}

              </li>

          ) : null
        )
        : null}
        <div className={`${hasClicked ? "sub" : "subCat"}`}>
        <ul className="category_list">
            {subCategories.map((value, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  dispatch(subcatId(value?.id));
                }}
              >
                {value?.title.map((el) => {
                  return el.language.code === lang && el.name;
                })
                }
              </li>
            );
          })}
        </ul>
      </div>

      </ul>
    </div>
  );
}

export default Cateogery;
