import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const Search = (props) => {
  const { t } = useTranslation("common");
  const [searchData, setSearchData] = useState("");
  useEffect(() => {
    setSearchData(props.value);
  }, [props]);
  const history = useHistory();
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };
  const onglobalSearch = (e) => {
    e.preventDefault();
    history.push({ pathname: `/s?k=${searchData}` });
    history.push(`/s?k=${searchData}`);
  };

  return (
    <>
      <div className="searchbar">
        <form id="search" onSubmit={(e) => onglobalSearch(e)}>
          <input
            type="text"
            value={searchData}
            name="k"
            id="search_input"
            className="form-control"
            placeholder={t("searchPage.search_placeholder")}
            onChange={handleChange}
            required
          />
          <button
            type="submit"
            name=""
            id=""
            value=""
            className="search_btn"
            // onClick={(e) => onglobalSearch(e)}
          ></button>
        </form>
      </div>
    </>
  );
};

export default Search;
