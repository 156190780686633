import React from "react";
import { useHistory } from "react-router";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Rating = (props) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    let token = localStorage.getItem("accessToken");
    const lang = localStorage.getItem("language");
    const ratingChanged = (newRating) => {
        const payload = {
            user: props.username,
          rate: newRating,
      };
      axios
          .post(
          `${process.env.REACT_APP_BASE_URL}auth/profile/rating/?lang=${lang}`,
          payload,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
      )
        .then((res) => {
            if (res.status === 200) {
            toast.success(`${t("errorMessages.rating_success")}`);
            history.push({ pathname: "../index" });
            props.closeModal();
        }
      })
        .catch((err) => {
            if (err.response !== undefined) {
                for (let i of err.response.data.message) {
                    toast.error(`${i.error}`);
                }
            }
        });
    };
    return (
        <>
            <ReactStars
                classNames="reactStar"
                size={40}
                count={5}
              edit={true}
              a11y={true}
              isHalf={true}
              onChange={ratingChanged}
              emptyIcon={<i className="fas fa-star"></i>}
              halfIcon={<i className="fas fa-star-half-alt"></i>}
              fullIcon={<i className="fas fa-star"></i>}
              activeColor="#ffd700"
          />
      </>
  );
};
export default Rating;
