export const ActionTypes = {
    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCTS:"SELECTED_PRODUCTS",
    SUBCAT_ID:"SUBCAT_ID",
    PRODUCT_ID:"PRODUCT_ID",
    CHECKBOX_ID: "CHECKBOX_ID",
    OWN_PRODUCT: "OWN_PRODUCT",
    SEARCH_STATE: "SEARCH_STATE",
    OFFER_STATE: "OFFER_STATE",

};
export const PaymentActionTypes = {
    SET_PAYMENT: "SET_PAYMENT",
    GET_PAYMENTS: "GET_PAYMENTS"
}
export const ContractsActionTypes = {
    GET_CONTRACTS: "GET_CONTRACTS",
    GET_CONTRACT: "GET_CONTRACT"
};
export const UserActionTypes = {
    USER_NAME: "USER_NAME"
}