import React, { useState, useEffect } from "react";
import { CInput, CLabel } from "@coreui/react";
import Modal from "../confirmModal/ConfirmModal";
import axios from "axios";
import Loader from "../../../Components/loader/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ShippingAddress from "./shippingAddress";
import deleteImg from "../../../../assets/images/delete.svg";
import editImg from "../../../../assets/images/edit.svg";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const AddressDetail = (props) => {
    const history = useHistory();
    const { t } = useTranslation('common');
    const [new_address, setNew_Address] = useState(false);
    const [body, setBody] = useState();
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("accessToken");
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [deletedId, setDeletedId] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [checked, setChecked] = useState(true);
    const [shippingAddress, setShippingAddress] = useState(false);
    const lang = localStorage.getItem("language");
    const [details, setDetails] = useState({
        address: "",
        country: "",
        city: "",
        state: "",
        postal_code: "",
    });
    useEffect(() => {
      handleGet();
      // eslint-disable-next-line
    }, []);

    const handleGet = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}payment/billing/?lang=${lang}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setBody(res.data.data);
                setLoading(false);
      })
            .catch((err) => console.log(err));
    };
    const validate = (values) => {
        let errors = {};

      if (!values.address) {
          errors.address = `${t("errorMessages.required_field")}`;
      }
      if (!values.city) {
          errors.city = `${t("errorMessages.required_field")}`;
      }

        if (!values.country) {
            errors.country = `${t("errorMessages.required_field")}`;
        }
        if (!values.state) {
            errors.state = `${t("errorMessages.required_field")}`;
        }
        return errors;
    };
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setDetails({ ...details, [name]: value });
    };
    const handleSubmit = async (event) => {
        setFormErrors(validate(details));
        event.preventDefault();

      document.getElementById("form").reset();

      const payload = {
          address: details.address,
          country: details.country,
          city: details.city,
          state: details.state,
          postal_code: details.postal_code,
    };

      if (Object.keys(validate(details)).length === 0) {
          await axios
              .post(
                  `${process.env.REACT_APP_BASE_URL}payment/billing/?lang=${lang}`,
                  payload,
                  {
                      headers: {
                          Authorization: ` Bearer ${token}`,
                      },
                  }
        )
          .then((res) => {
              if (res.status === 200 || res.status === 201) {
                  toast.success(`${t("errorMessages.success_added")}`);
                  setNew_Address(false);
                  handleGet();

              setDetails({
                  address: "",
                  country: "",
                  city: "",
                  state: "",
                  postal_code: "",
              });
          }
        })
          .catch((err) => {
              if (err.response !== undefined) {
                  for (let i of err.response.data.message) {
                      toast.error(`${i.error}`);
                  }
              }
          });
    }
    };
    let order_id = localStorage.getItem("Order_id");
    let productId = localStorage.getItem("productId");
    let productCurrency = localStorage.getItem("productCurrency");
    let productQuantity = localStorage.getItem("productQuantity");
    let productQuantityPrice = localStorage.getItem("quantityPrice");
    let productQuantityUnit = localStorage.getItem("productUnit");
    const handleDelivery = (value) => {
        localStorage.setItem("billingAddress", value);

      const payload = {
          product: Number(productId),
          earnest_amount: 0,
          final_amount: Number(productQuantityPrice),
          currency: productCurrency,
          quantity: Number(productQuantity),
          unit: productQuantityUnit,
          type: "PRODUCT"
    };

      if (!order_id) {
          axios
              .post(
                  `${process.env.REACT_APP_BASE_URL}payment/offer-create/?lang=${lang}`,
                  payload,
                  {
                      headers: {
                          Authorization: ` Bearer ${token}`,
                      },
                  }
        )
          .then((res) => {
              localStorage.setItem("OrderId", res.data.data);

            if (res.data.status === true) {
                history.push("./final-checkout");
          }
            setLoading(false);
        })
          .catch((err) => {
              if (err.response !== undefined) {
                  for (let i of err.response.data.message) {
                      toast.error(`${i.error}`);
                  }
              }
        });
    } else {
          history.push({ pathname: "./final-checkout" });
    }
  };

    const [editId, setEditId] = useState();
    const handleEdit = (id) => {
        setEdit(true);
        setEditId(id);
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}payment/billing/?id=${id}&lang=${lang}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
      )
        .then((res) => {
            setData(res.data.data);

          setLoading(false);
      })
            .catch((err) => console.log(err));
    };
    const handleEdited = (e) => {
      e.preventDefault();
      const payload = {
        address: details.address || data.address,
        country: details.country || data.country,
        city: details.city || data.city,
        state: details.state || data.state,
        postal_code: details.postal_code || data.postal_code,
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}payment/billing/?id=${editId}&lang=${lang}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("successfully updated");
          handleGet();
          setEdit(false);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    const onAddressShow = () => {
      setNew_Address(true);
    };
    const handleDelete = (id) => {
      setShow(true);
      setDeletedId(id);
    };
    const handleDeleted = () => {
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}payment/billing/?id=${deletedId}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("successfully deleted");
          setShow(false);
          handleGet();
        })
        .catch((err) => toast.error(err.message));
    };
    const onCancel = () => {
      setFormErrors({});
      setShow(false);
      setEdit(false);
      setNew_Address(false);
      document.getElementById("form").reset();
      document.getElementById("editForm").reset();
    };
    const isChecked = () => {
      setChecked(!checked);
      setShippingAddress(true);
    };
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {body.length > 0 ? (
              <>
                <div className="address scroll_div">
                  <div className="d-flex">
                    <h5 className="border-bottom w-100">
                      {" "}
                      {t("address.title")}
                    </h5>
                  </div>
                  <div className="d-grid">
                    {body.map((item, index) => {
                      return (
                        <div className="grid-address mt-4" key={index}>
                          <p>
                            <strong>
                              {t("address.addressForm.address")} :&nbsp;
                            </strong>{" "}
                            {item.address}
                          </p>
                          <div className="d-flex justify-content-between flex-wrap">
                            <p>
                              {" "}
                              <strong>
                                {" "}
                                {t("address.addressForm.city")}:
                              </strong>{" "}
                              &nbsp;{item.city}
                            </p>
                            <p>
                              {" "}
                              <strong>
                                {" "}
                                {t("address.addressForm.state")}:
                              </strong>
                              &nbsp;{item.state}
                            </p>
                            <p>
                              {" "}
                              <strong>
                                {" "}
                                {t("address.addressForm.country")}:
                              </strong>
                              &nbsp;
                              {item.country}
                            </p>
                            <p>
                              {" "}
                              <strong>
                                {" "}
                                {t("address.addressForm.postal_code")}:{" "}
                              </strong>
                              &nbsp;
                              {item.postal_code}
                            </p>
                          </div>

                          {productId && (
                            <button
                              type="button"
                              className="btn cstm_btn mt-2 w-100"
                              onClick={() => handleDelivery(item.id)}
                            >
                              {t("address.deliver_here")}
                            </button>
                          )}
                          <span
                            onClick={onAddressShow}
                            className="add_new_address"
                          >
                            {" "}
                            {t("address.create_new")}
                          </span>
                          <div className="action_button">
                            <div className="border-outer">
                              <img
                                width=""
                                height=""
                                src={editImg}
                                alt="timeline"
                                className="action-icon"
                                onClick={() => handleEdit(item.id)}
                              />
                            </div>
                            <div className="border-outer ml-4">
                              <img
                                width=""
                                height=""
                                src={deleteImg}
                                alt="timeline"
                                className="action-icon"
                                onClick={() => handleDelete(item.id)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card dash_edit_card">
                  <h3>{t("address.billingAddress")}</h3>
                  <form id="form" noValidate>
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="form-group focused">
                          <CLabel
                            className="form-control-label"
                            htmlFor="address"
                          >
                            {t("address.addressForm.address")}
                          </CLabel>
                          <CInput
                            onChange={handleChange}
                            id="address"
                            className="form-control form-control-alternative"
                            placeholder="Home Address"
                            name="address"
                            type="text"
                          />
                          {formErrors.address && (
                            <span className="error">{formErrors.address}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group focused">
                          <CLabel
                            className="form-control-label"
                            htmlFor="address"
                          >
                            {t("address.addressForm.city")}
                          </CLabel>
                          <CInput
                            id="city"
                            className="form-control form-control-alternative"
                            placeholder={t("address.addressForm.city")}
                            name="city"
                            type="text"
                            onChange={handleChange}
                          />
                          {formErrors.city && (
                            <span className="error">{formErrors.city}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group focused">
                          <CLabel
                            className="form-control-label"
                            htmlFor="address"
                          >
                            {t("address.addressForm.state")}
                          </CLabel>
                          <CInput
                            onChange={handleChange}
                            id="state"
                            className="form-control form-control-alternative"
                            placeholder={t("address.addressForm.state")}
                            name="state"
                            type="text"
                          />
                          {formErrors.state && (
                            <span className="error">{formErrors.state}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group focused">
                          <CLabel
                            className="form-control-label"
                            htmlFor="address"
                          >
                            {t("address.addressForm.country")}
                          </CLabel>
                          <CInput
                            onChange={handleChange}
                            id="country"
                            className="form-control form-control-alternative"
                            placeholder={t("address.addressForm.country")}
                            name="country"
                            type="text"
                          />
                          {formErrors.country && (
                            <span className="error">{formErrors.country}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group focused">
                          <CLabel
                            className="form-control-label"
                            htmlFor="postal_code"
                          >
                            {t("address.addressForm.postal_code")}
                          </CLabel>
                          <CInput
                            onChange={handleChange}
                            id="postal_code"
                            className="form-control form-control-alternative"
                            placeholder={t("address.addressForm.postal_code")}
                            name="postal_code"
                            type="text"
                          />
                          {formErrors.postal_code && (
                            <span className="error">
                              {formErrors.postal_code}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="mt-3 mb-4 checkbox_label">
                        <Checkbox
                          checked={checked === false ? true : false}
                          color="primary"
                          onChange={() => isChecked()}
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        />

                        {t("address.sameasbilling")}
                      </label>
                    </div>
                    <div className="row float-right mt-4">
                      <div className="">
                        <button
                          type="submit"
                          className="btn cstm_btn ml-3"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          {t("address.addressForm.save_continue")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}

            <Modal
              displayModal={new_address}
              closeModal={(e) => onCancel(e)}
              modalBody={
                <>
                  <div className="">
                    <div className="modalcontent">
                      <h4 className="modal-title">
                        {" "}
                        {t("address.addressForm.title")}
                      </h4>
                      <form className="dash_edit_card" id="form" noValidate>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.address")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="address"
                                className="form-control form-control-alternative"
                                placeholder={t("address.addressForm.address")}
                                name="address"
                                type="text"
                              />
                              {formErrors.address && (
                                <span className="error">
                                  {formErrors.address}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.city")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder={t("address.addressForm.city")}
                                name="city"
                                type="text"
                              />
                              {formErrors.city && (
                                <span className="error">{formErrors.city}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.state")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="state"
                                className="form-control form-control-alternative"
                                placeholder={t("address.addressForm.state")}
                                name="state"
                                type="text"
                              />
                              {formErrors.state && (
                                <span className="error">
                                  {formErrors.state}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.country")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="country"
                                className="form-control form-control-alternative"
                                placeholder={t("address.addressForm.country")}
                                name="country"
                                type="text"
                              />
                              {formErrors.country && (
                                <span className="error">
                                  {formErrors.country}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="postal_code"
                              >
                                {t("address.addressForm.postal_code")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="postal_code"
                                className="form-control form-control-alternative"
                                placeholder={t(
                                  "address.addressForm.postal_code"
                                )}
                                name="postal_code"
                                type="text"
                              />
                              {formErrors.postal_code && (
                                <span className="error">
                                  {formErrors.postal_code}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row float-right mt-2">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={(e) => onCancel(e)}
                            >
                              {t("address.addressForm.cancel")}
                            </button>
                            <button
                              type="submit"
                              className="btn cstm_btn ml-3"
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              {t("address.addressForm.submit")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              }
            />

            <Modal
              displayModal={show}
              closeModal={() => onCancel()}
              modalBody={
                <>
                  <div className="modal-body">
                    <div className="modalcontent">
                      <div>
                        <img
                          width=""
                          height=""
                          src={deleteImg}
                          alt=""
                          className="icon_wrap"
                        />
                      </div>
                      <h2 className="text-center"> {t("deletemodal.title")}</h2>
                      <div className="modal-button">
                        <button
                          type="submit"
                          className="btn btn-light"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          {t("deletemodal.cancel")}
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={() => handleDeleted()}
                        >
                          {t("deletemodal.delete")}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <Modal
              displayModal={edit}
              closeModal={() => {
                onCancel();
              }}
              modalBody={
                <>
                  <div>
                    <div className="">
                      <h4 className="modal-title">{t("address.edittitle")}</h4>
                      <form
                        id="editForm"
                        className="dash_edit_card"
                        onSubmit={handleEdited}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.address")}
                              </CLabel>
                              <CInput
                                id="address"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="address"
                                type="text"
                                defaultValue={data.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.city")}
                              </CLabel>
                              <CInput
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="city"
                                type="text"
                                defaultValue={data.city}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.state")}
                              </CLabel>
                              <CInput
                                id="state"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="state"
                                type="text"
                                defaultValue={data.state}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.country")}
                              </CLabel>
                              <CInput
                                id="country"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="country"
                                type="text"
                                defaultValue={data.country}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="postal_code"
                              >
                                {t("address.addressForm.postal_code")}
                              </CLabel>
                              <CInput
                                id="postal_code"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="postal_code"
                                type="text"
                                defaultValue={data.postal_code}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row float-right mt-2">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => onCancel()}
                            >
                              {t("address.addressForm.cancel")}
                            </button>
                            <button
                              className="btn cstm_btn ml-3"
                              onClick={handleEdited}
                            >
                              {t("address.addressForm.submit")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              }
            />
            {checked === false && shippingAddress === true ? (
              <ShippingAddress />
            ) : null}
          </>
        )}
        <ToastContainer />
      </>
    );
};

export default AddressDetail;
