import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Auth from "./Auth";
import Verify from "./views/pages/login/verify";
import Header from "./views/Components/header";
import Signup from "./views/pages/login/signup";
import Login from "./views/pages/login/login";
import Dashboard from "./views/pages/dashboard/dashbaord";
// import Payment from "./views/pages/dashboard/payments/payment";
import Page404 from "./views/pages/page404/Page404";
import Page500 from "./views/pages/page500/Page500";
import Index from "./views/pages/dashboard";
import Footer from "./views/pages/dashboard/footer";
import Products from "./views/pages/dashboard/produts";
import Privacy from "./views/pages/dashboard/privacy";
import Aboutus from "./views/pages/dashboard/aboutus";
import Contactus from "./views/pages/dashboard/contactus";
import Works from "./views/pages/dashboard/how_it_works";
import Buyer from "./views/pages/dashboard/buyer";
import Seller from "./views/pages/dashboard/seller";
import ServiceProvider from "./views/pages/dashboard/serviceProvider";
import Forgot from "./views/pages/login/forgot-password";
import AccountDetail from "./views/pages/dashboard/payments/accountDetail";
import OfferList from "./views/pages/dashboard/myOffers/offerList";
import OfferView from "./views/pages/dashboard/myOffers/offerView";
import MyEarnings from "./views/pages/dashboard/myEarnings/earnings";
import Contracts from "./views/pages/dashboard/myOffers/contracts/contracts";
import ContractsView from "./views/pages/dashboard/myOffers/contracts/contractsView";
import OrderHistory from "./views/pages/dashboard/myOffers/orders/order-history";
import OrderHistoryView from "./views/pages/dashboard/myOffers/orders/orderHistoryView";
import MyProductView from "./views/pages/dashboard/MyProducts/myproductView";
import MyProduct from "./views/pages/dashboard/MyProducts/myproducts";
import AddProduct from "./views/pages/dashboard/MyProducts/addproduct";
import Profile from "./views/pages/dashboard/profile/profile";
import EditProduct from "./views/pages/dashboard/MyProducts/editProduct";
import AddressDetail from "./views/pages/dashboard/payments/billingaddressDetail";
import FinalView from "./views/pages/dashboard/payments/FinalViewOrder";
import ServiceCategory from "./views/pages/dashboard/service-provider/ServiceCategory/serviceCategory";
import Checkout from "./views/pages/dashboard/payments/Checkout";
import SearchData from "./views/pages/dashboard/searchData";
import ServiceCategoryUserList from "./views/pages/dashboard/service-provider/ServiceCategory/serviceCategoryUserList";
import BankDetail from "./views/pages/dashboard/profile/bankDetail";
import Thankyou from "./views/pages/dashboard/profile/thankyou";
import HireHistroy from "./views/pages/dashboard/myOffers/hireHistory/hireHistory";
import HireHistoryView from "./views/pages/dashboard/myOffers/hireHistory/hireHistroyView";
// import HireApproval from "./views/pages/dashboard/myOffers/hireHistory/serviceHire";
import Page401 from "./views/pages/page401/page401";
import DashboardChat from "./views/pages/dashboard/chat/chatDashboard";
import Terms from "./views/pages/dashboard/terms_condition";
import UpdatePassword from "./views/pages/login/updatePassword";
import ChangePhoneNumber from "./views/pages/login/change-phoneNumber";
import Sidebar from "./views/Components/Sidebar/NavSidebar";
import TheHeader from "./views/Components/Header/Header";
import PaymentError from "./views/pages/dashboard/payments/error";
import OutsideClick from "./outSideClick";
import PayPalThankyou from "./views/pages/dashboard/profile/paypalThankyou";
// import PaypalCredit from "./views/pages/dashboard/payments/payapalCreditCard";
import CreateService from "./views/pages/dashboard/service-provider/myservice/createService";
import MyService from "./views/pages/dashboard/service-provider/myservice/myService";
import EditService from "./views/pages/dashboard/service-provider/myservice/editService";
import MyServiceView from "./views/pages/dashboard/service-provider/myservice/myServiceView";
import ServiceProviderCategoryList from "./views/pages/dashboard/service-provider/ServiceCategory/serviceProviderCategoryList";
import ProductDetail from "./views/pages/dashboard/Product-detail/ProductDetail";

const Router = () => {
  return (
    <Switch>
      <PublicRoute exact={true} path="/login" component={Login} />
      <PublicRoute exact={true} path="/verify" component={Verify} />
      <PublicRoute
        exact={true}
        path="/signup"
        name="Signup Page"
        component={Signup}
      />
      <Routes
        exact={true}
        path="/404"
        name="Page 404"
        component={Page404}
        default
      />
      <Routes exact={true} path="/500" name="Page 500" component={Page500} />
      <Routes exact={true} path="/" component={Index} />
      <Routes exact={true} path="/401" name="Page 401" component={Page401} />
      <Routes exact={true} path="/index" name="Index" component={Index} />
      <Routes exact={true} path="/privacy" name="Privacy" component={Privacy} />
      <Routes exact={true} path="/terms" name="Terms" component={Terms} />
      <Routes exact={true} path="/aboutus" name="Aboutus" component={Aboutus} />

      <Routes
        exact={true}
        path="/userList/:id"
        name="Service Provider User List"
        component={ServiceCategoryUserList}
      />
      <Routes
        exact={true}
        path="/servicecategory/serviceList/:id"
        name="Service Provider User List"
        component={ServiceProviderCategoryList}
      />
      <Routes
        exact={true}
        path="/servicecategory/:username/:id"
        name="ServiceCategory"
        component={ServiceCategory}
      />
      <Routes
        exact={true}
        path="/contactus"
        name="Contactus"
        component={Contactus}
      />
      <Routes exact={true} path="/works" component={Works} />
      <Routes exact={true} path="/buyer" component={Buyer} />
      <Routes exact={true} path="/seller" component={Seller} />
      <Routes
        exact={true}
        path="/serviceprovider"
        component={ServiceProvider}
      />
      <Routes exact={true} path="/product-view" component={ProductDetail} />
      <Routes exact={true} path="/products/:id" component={ProductDetail} />
      <PublicRoute path="/forgot" name="Forgot" component={Forgot} />
      <Route exact={true} path="/">
        <Redirect to="/index" />
      </Route>
      <div className="">
        {/* <div> */}
        <Routes exact={true} path="/products" component={Products} />
        {/* </div> */}
        <Routes
          exact={true}
          path="/s"
          name="SearchData"
          component={SearchData}
        />
        <Routes
          exact={true}
          path="/s:k"
          name="SearchData"
          component={SearchData}
        />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        {/* <PrivateRoute path="/payment" component={Payment} /> */}
        <PrivateRoute
          exact={true}
          path="/offer"
          name="My Offer"
          component={OfferList}
        />
        <PrivateRoute exact={true} path="/offer-b/:id" component={OfferView} />
        <PrivateRoute exact={true} path="/offer-s/:id" component={OfferView} />
        <PrivateRoute
          exact={true}
          path="/payment-detail"
          name="Payment"
          component={AccountDetail}
        />
        <PrivateRoute
          exact={true}
          path="/product"
          name="Products"
          component={Products}
        />
        <PrivateRoute
          exact={true}
          path="/myearning"
          name="My Earnings"
          component={MyEarnings}
        />
        <PrivateRoute
          exact={true}
          path="/contracts"
          name="My Contracts"
          component={Contracts}
        />
        <PrivateRoute
          exact={true}
          path="/contracts-b/:id"
          name="My Contracts"
          component={ContractsView}
        />
        <PrivateRoute
          exact={true}
          path="/contracts-s/:id"
          name="My Contracts"
          component={ContractsView}
        />
        <PrivateRoute
          exact={true}
          path="/order"
          name="My Order"
          component={OrderHistory}
        />
        <PrivateRoute
          exact={true}
          path="/order-b/:id"
          name="My Order View"
          component={OrderHistoryView}
        />
        <PrivateRoute
          exact={true}
          path="/order-s/:id"
          name="My Order View"
          component={OrderHistoryView}
        />
        <PrivateRoute
          exact={true}
          path="/myproducts"
          name="Payment"
          component={MyProduct}
        />
        <PrivateRoute
          exact={true}
          path="/myproduct/:id"
          name="My View Product"
          component={MyProductView}
        />
        <PrivateRoute
          exact={true}
          path="/addproducts"
          name="add Product"
          component={AddProduct}
        />
        <PrivateRoute
          exact={true}
          path="/editproduct/:id"
          name="Edit product"
          component={EditProduct}
        />
        <PrivateRoute
          exact={true}
          path="/profile"
          name="Profile"
          component={Profile}
        />
        <PrivateRoute
          exact={true}
          path="/address-detail"
          name="Address"
          component={AddressDetail}
        />

        {/* <Routes exact={true} path="/paypal-checkout" component={PaypalCredit} /> */}
        <Routes
          exact={true}
          path="/final-checkout"
          name="Address"
          component={FinalView}
        />

        <PrivateRoute
          exact={true}
          path="/createservice"
          name="Address"
          component={CreateService}
        />
        <PrivateRoute
          exact={true}
          path="/editservice/:id"
          name="Address"
          component={EditService}
        />
        <PrivateRoute
          exact={true}
          path="/myservice"
          name="Address"
          component={MyService}
        />
        <PrivateRoute
          exact={true}
          path="/bankDetail"
          name="Bank Detail"
          component={BankDetail}
        />
        <Routes
          exact={true}
          path="/Checkout"
          name="Address"
          component={Checkout}
        />
        <PrivateRoute
          exact={true}
          path="/myservice/:id"
          name="MyServiceView"
          component={MyServiceView}
        />
        <Routes
          exact={true}
          path="/thankyou"
          name="Address"
          component={Thankyou}
        />
        <Routes exact={true} path="/error" component={PaymentError} />
        <PrivateRoute
          exact={true}
          path="/hireHistory"
          name="My Hire History"
          component={HireHistroy}
        />
        <PrivateRoute
          exact={true}
          path="/hireHistory-b/:id"
          name="My Order View"
          component={HireHistoryView}
        />
        <PrivateRoute
          exact={true}
          path="/hireHistory-s/:id"
          name="My Order View"
          component={HireHistoryView}
        />
        {/* <PrivateRoute
          exact={true}
          path="/hireApproval"
          name="HireApproval"
          component={HireApproval}
        /> */}
        <PrivateRoute
          exact={true}
          path="/chat"
          name="chat"
          component={DashboardChat}
        />
        <PrivateRoute
          exact={true}
          path="/changePassword"
          name="Change Password"
          component={UpdatePassword}
        />
        <PrivateRoute
          exact={true}
          path="/changePhoneNumber"
          name="change phone Number"
          component={ChangePhoneNumber}
        />
        <PrivateRoute
          exact={true}
          path="/thankyou-paypal"
          name="PayPal Thankyou"
          component={PayPalThankyou}
        />
      </div>
      <PublicRoute component={Page404} />
    </Switch>
  );
};
//  private Route : check token frist

const PrivateRoute = ({ component: Component, ...rest }) => {
  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);
  const [sidebar, setSidebarShow] = useState(
    window.matchMedia("(min-width: 991px)").matches ? true : false
  );

  useEffect(() => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      if (boxOutsideClick) {
        setSidebarShow(true);
      } else {
        setSidebarShow(false);
      }
    } else {
      setSidebarShow(true);
    }
  }, [boxOutsideClick]);
  const toggleSidebar = () => {
    setSidebarShow(!sidebar);
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        Auth.checkAuth() ? (
          <>
            <div className="dashboard_outer">
              <div className="d-flex">
                {sidebar ? <Sidebar toggleSidebar={toggleSidebar} /> : null}
                <div className="header_position">
                  <TheHeader
                    style={
                      sidebar
                        ? {
                            width: "calc(100% - 300px)",
                            marginLeft: "300px    ",
                          }
                        : { width: "100%" }
                    }
                    toggleSidebar={toggleSidebar}
                  />
                </div>
                <div
                  className={
                    sidebar
                      ? " c-wrapper dashboard-container"
                      : "w-100 dashboard-container"
                  }
                  ref={boxRef}
                >
                  <Component {...props} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};

//  Routes does not required token to access

const Routes = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        <Route
          path="/"
          render={(props) =>
            props.location.pathname !== "/login" &&
            props.location.pathname !== "/verify" &&
            props.location.pathname !== "/dashboard" &&
            props.location.pathname !== "/forgot" &&
            props.location.pathname !== "/dashboardview" &&
            props.location.pathname !== "/payment" &&
            props.location.pathname !== "/address-detail" && <Header />
          }
        ></Route>

        <Component {...props} />
        <Route
          path="/"
          render={(props) =>
            props.location.pathname !== "/login" &&
            props.location.pathname !== "/verify" &&
            props.location.pathname !== "/dashboard" &&
            props.location.pathname !== "/forgot" &&
            props.location.pathname !== "/dashboardview" &&
            props.location.pathname !== "/payment" &&
            props.location.pathname !== "/address-detail" && <Footer />
          }
        ></Route>
      </>
    )}
  />
);

// Public Route not required token to access.

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.checkAuth() === false ? (
        <>
          <Route
            path="/"
            render={(props) =>
              props.location.pathname !== "/dashboard" &&
              props.location.pathname !== "/dashboardview" &&
              props.location.pathname !== "/payment" && <Header />
            }
          ></Route>
          <Component {...props} />
          <Route
            path="/"
            render={(props) =>
              props.location.pathname !== "/dashboard" &&
              props.location.pathname !== "/dashboardview" &&
              props.location.pathname !== "/payment" && <Footer />
            }
          ></Route>
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/dashboard",
          }}
        />
      )
    }
  />
);

export default Router;
