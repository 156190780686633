import React, { useState, useEffect } from "react";
import axios from "axios";
import Search from "./searchbox";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Loader from "../../Components/loader/index";
import { useTranslation } from "react-i18next";
const Privacy = () => {
	window.scrollTo(0, 0)
	const { t } = useTranslation('common');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState();
	let getLang = localStorage.getItem("language");
	useEffect(() => {
		axios
		  .get(
			  `${process.env.REACT_APP_BASE_URL}website/page/?title=Privacy Policy&lang=${getLang}`
		  )
		  .then((res) => {
			  setData(res.data.data);
			  setLoading(false);
		  })
		  .catch((err) => {
			  if (err.response !== undefined) {
				  for (let i of err.response.data.message) {
					  toast.error(`${i.error}`);
				  }
			  }
		  });
  }, [loading, getLang]);

	return (
	  <div>
		  <div className="cstm_header privacy_header">
			  <Search />
		  </div>
		  {loading ? (
			  <Loader />
		  ) : (
			  <div className="other_sec">
				  <div className="container">
					  <div className="row">
						  <div className="col-lg-12 text-center" data-aos="fade-up">
								  <h2 className="heading">{t("staticPages.privacy_policy")}</h2>
							  </div>
							  <div className="col-lg-12" data-aos="fade-up">
								  {data.map((item) => {
									  return (
										  <>
						  {item.language.code === getLang
							  ? item.section.map((el, index) => {
								  return (
									  <div key={index}>
										  <h5>{el.title}</h5>
									<p
										dangerouslySetInnerHTML={{
											__html: `${el.description_html}`,
										}}
									></p>
								</div>
							);
						})
								: null}
						</>
					);
				})}
						  </div>
					  </div>
				  </div>
			  </div>
		  )}
	  </div>
	);
};

export default Privacy;
