import React, { useState, useEffect } from 'react';
import axios from "axios";
import ScrollAnimation from 'react-animate-on-scroll';
import Search from './searchbox';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { CLink } from "@coreui/react";
const Buyer = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const lang = localStorage.getItem("language");
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=Buyer&lang=${lang}`
      )
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  }, [loading, lang]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="cstm_header buyer_header">
        <Search />
      </div>
      <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
        <div className="buyer_seller_cont">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="heading" data-aos="fade-up">
                  {t("staticPages.buyer")}
                </h2>
              </div>
            </div>
            <div className="buyer_seller_cont_outer" data-aos="fade-up">
              {data.map((item) =>
                item.language.code === lang ? (
                  <>
                    <div className="left">
                      <img
                        width=""
                        height=""
                        src={`${item.section[0].image_upload}`}
                        alt="timeline"
                      />
                    </div>
                    <div className="right">
                      <h4>{item.section[0].subtitle}</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${item.section[0].description_html}`,
                        }}
                      ></p>
                      <CLink to="/signup">
                        <button className="web-btn mt-3">
                          {t("indexPage.section2.buyerText")}
                        </button>
                      </CLink>
                      {/* <p>{item.section[0].description}.</p> */}
                    </div>
                  </>
                ) : null
              )}
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default Buyer;