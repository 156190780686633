import React from "react";
import logo from "../../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import useForm from "./validation/useForm";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
const Footer = (props) => {
  const { t } = useTranslation("common");
  const initialState = {
    email: { value: "", error: "" },
  };
  let getLang = localStorage.getItem("language");
  const stateValidatorSchema = {
    email: {
      required: true,
      validator: {
        func: (value) =>
          //eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ),
        error: `${t("errorMessages.email_validation")}`,
      },
    },
  };
  const onSubmitForm = (state, event) => {
    let payload = {
      email: state.email,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}website/subscription/?lang=${getLang}`,
        payload
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(`${t("errorMessages.subscribe_success")}`);
          document.getElementById("form").reset();
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        } else {
          toast.error("an error occured");
        }
      });
  };
  const { handleChange, handleSubmit, disable } = useForm(
    initialState,
    stateValidatorSchema,
    onSubmitForm
  );
  return (
    <div>
      <footer>
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3">
              <img alt="" src={logo} />
              <p className="text mt-5">
                7300 Hooper <br /> LOS ANGELES CA 38938
              </p>
              <li className="mt-4">
                <FontAwesomeIcon icon={faPhone} /> +138 386 38938
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} /> cho.direct@gmail.com
              </li>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <h6 className="footer-title">Quick Links</h6>
              <li className="mt-4">
                <a href="/products"> {t("footer.usefullinks.sub_title1")}</a>
              </li>
              <li>
                <a href="/works"> {t("footer.usefullinks.sub_title2")}</a>
              </li>
              <li>
                <a href="/aboutus"> {t("footer.usefullinks.sub_title3")}</a>
              </li>
              <li>
                <a href="/contactus">{t("footer.usefullinks.sub_title4")}</a>
              </li>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2">
              <h6 className="footer-title">{t("footer.information.title")}</h6>
              <li className="mt-4">
                <a href="/privacy">{t("footer.information.sub_title1")}</a>
              </li>
              <li>
                <a href="/terms">{t("footer.information.sub_title2")}</a>
              </li>
              <li onClick={() => localStorage.setItem("faq", "faq")}>
                <a href="/works">{t("footer.information.sub_title3")}</a>
              </li>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
              <h6 className="footer-title">
                {t("indexPage.subscribe_section.title")}
              </h6>
              <form className="mt-4" id="form" onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter Your Email"
                  name="email"
                  id="email"
                  placeholder={t(
                    "indexPage.subscribe_section.email_placeholder"
                  )}
                  required
                  onChange={handleChange}
                />
                <button type="submit">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
};

export default Footer;
