import React, { useState, useEffect } from "react";
import { CLink, CSelect, CBadge } from "@coreui/react";
import user_icon from "../../assets/images/user.png";
import axios from "axios";
import "./header.css";
import { socket } from "../../socket";
import LoginModal from "../pages/login/loginModal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faPhone,
  faMessage,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo.png";
const Header = () => {
  //   const { t, i18n } = useTranslation('common');
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [image, setImage] = useState("");
  const [unread, setUnRead] = useState("");
  const [unreadChat, setUnReadChat] = useState("1");
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("accessToken");
  const [language, setLanguage] = useState([]);
  const userName = localStorage.getItem("username");
  const lang = localStorage.getItem("language");
  const unreadCount = {
    action: "unread_notification_count",
    request_id: userName,
    token: token,
    close: false,
  };
  useEffect(() => {
    const ws = new WebSocket("wss://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(unreadCount));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setUnRead(json.data);
            // setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
  }, [unread]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLanguage(res.data.data);
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, [loading, lang, token]);
  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}auth/profile/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setBody(res.data.data);
          setImage(res.data.data.profile_image);
          setLoading(false);
        })
        .catch((err) => console.log(err));
      //eslint-disable-next-line
    }
  }, [loading, token, image, lang]);

  const onHide = () => {
    setShow(false);
  };
  const LoginPop = () => {
    if (!token) {
      setShow(true);
    } else {
      history.push("../dashboard");
    }
  };
  const handleLanguage = (e) => {
    const value = JSON.parse(e.target.value);
    let code = value.code;
    localStorage.setItem("language", code);
    i18n.changeLanguage(code);

    const payload = {
      language: code,
    };

    let token = localStorage.getItem("accessToken");

    axios
      .put(`${process.env.REACT_APP_BASE_URL}auth/profile/`, payload, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {});
  };
  useEffect(() => {
    if (token) {
    socket.emit("get_unread_chat_count", {
      user: {
        user_id: body?.id.toString(),
        email: body?.username,
        name: body?.first_name,
        profile_image: body?.profile_image,
      },
    });
    }

    socket.on("set_unread_chat_count", (data) => {
      setUnReadChat(data.user_count);
    });
  }, [body]);

  return (
    <div>
      <div className="container-fluid px-5 py-2 tp-header">
        <div className="row">
          <div className="col-md-6 col-3 my-auto">
            <span>
              <FontAwesomeIcon icon={faPhone} /> 0123456789
            </span>
          </div>
          <div className="col-md-6 col-9 text-end">
            <span>
              <CSelect
                custom
                name="language"
                id="language"
                // className="form-control"
                onChange={handleLanguage}
                defaultValue={lang}
              >
                {language.map((option, index) => (
                  <option
                    value={JSON.stringify(option)}
                    key={index}
                    className="text-capitalize"
                    selected={option.code === lang}
                  >
                    {option.language}
                  </option>
                ))}
              </CSelect>
            </span>
            <span className="">
              {token && (
                <>
                  <CLink to="/chat">
                    <FontAwesomeIcon icon={faMessage} />
                  </CLink>
                  {unreadChat === 0 ? null : (
                    <CBadge
                      shape="pill"
                      className="badge badge-pill badge-primary pull-right notification-badge"
                    >
                      {unreadChat}
                    </CBadge>
                  )}
                </>
              )}
            </span>
            {token && (
              <span className="ms-4">
                <CLink to="/dashboard">
                  <FontAwesomeIcon icon={faBell} />
                </CLink>
                {unread === 0 ? null : (
                  <CBadge
                    shape="pill"
                    className="badge badge-pill badge-primary pull-right notification-badge"
                  >
                    {unread}
                  </CBadge>
                )}
              </span>
            )}
            <span className="ms-4">
              {/* <FontAwesomeIcon icon={faUser} /> My Account */}
              {token && body ? (
                <CLink to="/login">
                  <img
                    width="20"
                    height="20"
                    src={
                      image !== null && image !== undefined ? image : user_icon
                    }
                    className="user_image"
                    alt="Icon"
                  />{" "}
                  {
                    body?.first_name !== null &&
                      body?.first_name.length > 0 &&
                      body?.first_name !== undefined && (
                        <span className="user_name">{body?.first_name} </span>
                      )
                    // : (
                    //   <span className="my_test">
                    //     {" "}
                    //     `${t("header.toheader.mycontact")}`
                    //   </span>
                    // )
                  }
                </CLink>
              ) : (
                <CLink onClick={() => setShow(true)}>
                  <img width="20" height="20" src={user_icon} alt="Icon" />{" "}
                  &nbsp;
                  <span className="my_test">
                    {" "}
                    {t("header.toheader.mycontact")}
                  </span>
                </CLink>
              )}
            </span>
          </div>
        </div>
      </div>
      <header className="my-header">
        <nav className="navbar navbar-expand-lg navbar-light my-header py-4 px-5">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img alt="" src={logo} />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="navbar-nav ms-auto align-items-center">
                <a
                  className="nav-link  px-5"
                  aria-current="page"
                  href="/products"
                >
                  {t("header.title1")}
                </a>
                <a className="nav-link px-5" href="/works">
                  {t("header.title2")}
                </a>
                <a className="nav-link px-5" href="/aboutus">
                  {t("header.title3")}
                </a>
                <a className="nav-link px-5" href="/contactus">
                  {t("header.title4")}
                </a>
                {!token && (
                  <div className="nav-link px-5">
                    <FontAwesomeIcon icon={faRightToBracket} />
                    <CLink onClick={() => LoginPop()}>
                      {t("header.button.buttonLogin")}
                    </CLink>
                    <CLink to="/signup">/{t("header.button.buttonSign")}</CLink>
                  </div>
                )}
                {token && (
                  <div className="">
                    <CLink className="nav-link px-5" to="/dashboard">
                      {t("header.button.buttonDashboard")}
                    </CLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
      <LoginModal displayModal={show} closeModal={onHide} />
    </div>
  );
};

export default Header;
