import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    CButton,
    CCol,
    CInput,
    CLabel,
    CRow,
    CSelect,
    CTextarea,
} from "@coreui/react";
import Loader from "../../../Components/loader/index";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "src/views/Components/Custom-Datepicker/custom-datepicker";
import Moment from "moment";
import { Switch } from "antd";

const EditProduct = () => {
  const { t } = useTranslation("common");

  const quantityUnit = [
    {
      value: "KILOGRAMS",
      label: `${t("myproduct.ProductForm.quantityUnitData.kilograms")}`,
    },
    {
      value: "DEKAGRAMS",
      label: `${t("myproduct.ProductForm.quantityUnitData.dekagrams")}`,
    },
    {
      value: "HECTOGRAMS",
      label: `${t("myproduct.ProductForm.quantityUnitData.hectograms")}`,
    },
    {
      value: "GRAMS",
      label: `${t("myproduct.ProductForm.quantityUnitData.grams")}`,
    },
  ];
  const areaUnit = [
    { value: "YARD", label: `${t("myproduct.ProductForm.areaunitData.yard")}` },
    { value: "ACRE", label: `${t("myproduct.ProductForm.areaunitData.acre")}` },
    {
      value: "HECTARE",
      label: `${t("myproduct.ProductForm.areaunitData.hectare")}`,
    },
  ];
  const currencyData = [
    { value: "USD", label: `${t("myproduct.ProductForm.usd")} ` },
    { value: "DONG", label: `${t("myproduct.ProductForm.dong")} ` },
  ];
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const initialValue = {
    estimate_time: "",
    quantity: "",
    area: "",
    area_unit: "",
    quantity_unit: "",
    pricing_type: "",
    fixedprice: "",
    price_to: "",
    price_from: "",
    cateogories: "",
    subcateogories: "",
    description: "",
  };
  const history = useHistory();
  const [cateogory_value, setCateogary_Value] = useState([]);
  const [productData, setProductData] = useState("");
  const [inputList, setInputList] = useState([
    { name: "", description: "", language: Number() },
  ]);
  const [subcateogory_value, setSubCateogary_Value] = useState("");
  // const [pricetype, setPricetype] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialValue);
  const [earnestAllowed, setEarnestAllowed] = useState(false);
  const [priceFrom, setPriceFrom] = useState();
  //   const [editImage, setEditImage] = useState([]);
  const [fileList, setFileList] = useState([]);
  //   const [uploader, setuploader] = useState([]);
  const [languages, setLanguage] = useState([]);
  let k = window.location.href.split("/").length - 1;
  let id = window.location.href.split("/")[k];
  //   const [categoryValue, setCateogaryValue] = useState("");
  const lang = localStorage.getItem("language");
  const [datepick, setDatePick] = useState();
  const [shippment, setShippment] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(false);
  let bData = [];
  const onChange = (checked) => {
    console.log(checked, "checked");
    setShippment(checked);
  };
  const handleGet = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?id=${id}&lang=${lang}`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData(res.data.data);
        setDefaultChecked(res.data.data.shipping);
        res.data.data.product_name.forEach((e) => {
          let result = {};
          result = e;
          e.language = e.language.id;
          let data = { ...result };
          bData.push(data);
          return null;
        });
        setInputList(bData);
      })
      .catch((err) => console.log(err));
  };
  const onChangeImage = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onClick = (e) => {
    for (let i = 0; i < cateogory_value.length; i++) {
      if (cateogory_value[i].id == e.target.value) {
        setSubCateogary_Value(cateogory_value[i].subcategory);
        console.log(cateogory_value[i].subcategory, "ci");
      }
    }
  };

  const handleInputChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleRemoveClick = (x, index, id) => {
    const list = [...inputList];
    list.splice(index);
    setInputList(list);
    if (x !== null && x !== undefined) {
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}inventory/delete-product-name/?id=${x}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(`${t("errorMessages.success_deleted")}`);
          handleGet();
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
  };
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { name: "", description: "", language: Number() },
    ]);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  let token = localStorage.getItem("accessToken");
  const handleSubmit = async (e, value, id) => {
    e.preventDefault();
    let payload;
    let imagess = [];
    for (let i = 0; i < fileList.length; i++) {
      imagess.push(fileList[i].response.data[0]);
    }

    // setuploader(imagess);
    payload = {
      category: data.cateogories || productData.category.id,
      subcategory: data.subcateogories || productData.subcategory.id,
      estimate_time: datepick || productData.estimate_time,
      description: data.description || productData.description,
      total_quantity: data.quantity || productData.total_quantity,
      total_quantity_unit:
        data.quantity_unit || productData.total_quantity_unit,
      area: data.area || productData.area,
      area_unit: data.area_unit || productData.area_unit,
      product_name: inputList,
      earnest_allowed: earnestAllowed,
      price_from: priceFrom || data.price_from || productData.price_from,
      price_to: data.price_to || productData.price_to,
      image: imagess,
      status: value,
      is_deleted: "False",
      type: "PRODUCT",
      price_currency: data.product_currency || "USD",
      product_amount_unit:
        data.quantity_unit || productData.product_amount_unit,
      pricing_type: data.price_type || productData.pricing_type,
    };

    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?id=${id}&lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(`${t("errorMessages.success")}`);
        history.push(`/myproducts`);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };

  const handleSaveDraft = async (e, value, id) => {
    e.preventDefault();
    let imagess = [];

    for (let i = 0; i < fileList.length; i++) {
      imagess.push(fileList[i].response.data[0]);
    }
    // setuploader(imagess);

    const payload = {
      category: data.cateogories || productData.category.id,
      subcategory: data.subcateogories || productData.subcategory.id,
      estimate_time: datepick || productData.estimate_time,
      description: data.description || productData.description,
      total_quantity: data.quantity || productData.total_quantity,
      total_quantity_unit:
        data.quantity_unit || productData.total_quantity_unit,
      area: data.area || productData.area,
      area_unit: data.area_unit || productData.area_unit,
      product_name: inputList,
      earnest_allowed: earnestAllowed,
      price_from: data.price_from || priceFrom || productData.price_from,
      price_to: data.price_to || productData.price_to,
      image: imagess,
      status: value,
      is_deleted: "False",
      type: "PRODUCT",
      price_currency: data.product_currency || "USD",
      product_amount_unit:
        data.quantity_unit || productData.product_amount_unit,
      pricing_type: data.price_type || productData.pricing_type,
    };

    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?id=${id}&lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(`${t("errorMessages.success_added")}`);
        history.push(`/myproducts`);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });

    // }
  };

  const Price = (e) => {
    if (e.target.value === "FIXED") {
      // setPricetype("fixed");
      setEarnestAllowed(false);
      setPriceFrom("0");
    } else if (e.target.value === "RANGE") {
      // setPricetype("range");
      setEarnestAllowed(true);
    }
  };
  const [editCategory, setEditCategory] = useState("");
  //   const [editCategoryValue, setEditCategoryValue] = useState("");
  //   const [editSubCategory, setSubCategory] = useState("");
  //   const [editSubCategoryValue, setSubCategoryValue] = useState("");
  const [defaultVal, setDefaultValue] = useState([]);
  let LValue = [];
  useEffect(() => {
    if (editCategory !== -1) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/category-subcategory/?type=PRODUCT&lang=${lang}`
        )
        .then((res) => {
          setCateogary_Value(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    if (productData !== undefined) {
      for (let i of cateogory_value) {
        if (productData.category === i.id) {
          setEditCategory(i.title);
          //   setEditCategoryValue(i.id);
          setLoading(false);
          //   for (let j of i.subcategory) {
          //     if (productData.subcategory == j.id) {
          //       setSubCategory(j.title);
          //     //   setSubCategoryValue(j.id);
          //       setLoading(false);
          //     }
          //   }
        }
      }

      for (let i of inputList) {
        for (let j of languages) {
          if (j.id === i.language) {
            LValue.push({ code: j });
          }
        }
      }
      setDefaultValue(LValue);
    }
    if (productData !== undefined) {
      if (productData.image) {
        let images = [];
        for (let i = 0; i < productData.image.length; i++) {
          images.push({
            url: productData.image[i],
            response: { data: [productData.image[i]] },
          });
        }
        setFileList(images);
      }
      //   if (productData.category !== undefined) {
      //     setCateogaryValue(productData.category.title);
      //   }
    }
    //eslint-disable-next-line
  }, [loading, setCateogary_Value, productData, editCategory]);

  // useEffect(() => {
  //     for (let i = 0; i < cateogory_value.length; i++) {
  //         if (cateogory_value[i].id == productData.category.id) {
  //             setSubCateogary_Value(cateogory_value[i].subcategory);
  //         }
  //     }
  // }, [cateogory_value]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`)
      .then((res) => {
        setLanguage(res.data.data);
      })
      .catch((err) => console.log(err));
    handleGet();
    //eslint-disable-next-line
  }, [loading]);

  console.log(defaultChecked, "productData.shipping");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="scroll_div">
          <div className="headingWrap">
            {!productData ? (
              <h3 className="title"> {t("myproduct.ProductForm.edittitle")}</h3>
            ) : (
              <h3 className="title">{t("myproduct.ProductForm.edittitle")}</h3>
            )}
          </div>
          <form id="form" noValidate>
            <div className="card dash_edit_card">
              <>
                {inputList.map((x, i) => {
                  return (
                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.language")}*
                        </CLabel>
                        <select
                          onClick={(e) => handleInputChange(e, i)}
                          name="language"
                          id="language"
                          className="form-control"
                          defaultValue={defaultVal.map(
                            (item) => item.code.code
                          )}
                        >
                          <option disabled selected>
                            {t("myproduct.ProductForm.select")}{" "}
                          </option>
                          {languages.map((e) => (
                            <option
                              //   value={e.id}
                              disabled={e.id === x.language ? true : false}
                              className="text-capitalize"
                              selected={defaultVal.map(
                                (item) => item.code.id === e.id
                              )}
                            >
                              {e.language}({e.code})
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.name")}
                        </CLabel>
                        <CInput
                          value={x.name}
                          onChange={(e) => handleInputChange(e, i)}
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter your Product Name"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.description")}
                        </CLabel>
                        <CInput
                          value={x.description}
                          onChange={(e) => handleInputChange(e, i)}
                          name="description"
                          id="description"
                          className="form-control"
                          placeholder="Enter your Product description"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.differentLang")}
                        </CLabel>
                        <div className="btn-box m-auto">
                          {inputList.length !== 1 && (
                            <span
                              className="remove-btn"
                              onClick={() => handleRemoveClick(x.id, i)}
                            >
                              -
                            </span>
                          )}
                          {inputList.length - 1 === i && (
                            <span className="add-btn" onClick={handleAddClick}>
                              +
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            </div>
            <div>
              <div className="card dash_edit_card">
                <CRow>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.totalQuantity")}
                    </CLabel>
                    <CInput
                      onChange={handleChange}
                      name="quantity"
                      id="quantity"
                      className="form-control"
                      defaultValue={productData.total_quantity}
                    />
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.quantityUnit")}
                    </CLabel>
                    <CSelect
                      name="quantity_unit"
                      id="quantity_unit"
                      onChange={handleChange}
                      className="form-control"
                      defaultValue={productData.total_quantity_unit}
                      selected={productData.total_quantity_unit}
                    >
                      {quantityUnit.map((el, i) => (
                        <option
                          value={el.value}
                          selected={
                            el?.value === productData?.total_quantity_unit
                          }
                        >
                          {el.label}
                        </option>
                      ))}
                      {/* <option >
                                                  {" "}
                                                  {t("myproduct.ProductForm.quantityUnitData.kilograms")}
                                              </option>
                                              <option>
                                                  {" "}
                                                  {t("myproduct.ProductForm.quantityUnitData.dekagrams")}
                                              </option>
                                              <option>
                                                  {" "}
                                                  {t("myproduct.ProductForm.quantityUnitData.hectograms")}
                                              </option>
                                              <option>
                                                  {t("myproduct.ProductForm.quantityUnitData.grams")}
                                              </option> */}
                    </CSelect>
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="area">
                      {t("myproduct.ProductForm.area")}*
                    </CLabel>
                    <CInput
                      onChange={handleChange}
                      name="area"
                      id="area"
                      className="form-control"
                      defaultValue={productData.area}
                    />
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.areaunit")}*
                    </CLabel>
                    <CSelect
                      name="area_unit"
                      id="area_unit"
                      onChange={handleChange}
                      className="form-control"
                      defaultValue={productData.area_unit}
                      // selected={productData.area_unit}
                    >
                      {areaUnit.map((el, i) => (
                        <option
                          value={el.value}
                          selected={el?.value === productData?.area_unit}
                        >
                          {el.label}
                        </option>
                      ))}
                      {/* <option value="YARD">
                                                  {" "}
                                                  {t("myproduct.ProductForm.areaunitData.yard")}
                                              </option>
                                                <option value="ACRE">
                                                  {t("myproduct.ProductForm.areaunitData.acre")}
                                              </option>
                                                <option value="HECTARE">
                                                  {" "}
                                                  {t("myproduct.ProductForm.areaunitData.hectare")}
                                              </option> */}
                    </CSelect>
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="estimate_time">
                      {t("myproduct.ProductForm.estimate_date")}*
                    </CLabel>
                    <CustomDatePicker
                      wrapperClassName="datepicker"
                      className="form-control"
                      dateFormat="yy/mm/dd"
                      selected={Moment(
                        datepick || productData.estimate_time
                      ).toDate()}
                      name="estimate_time"
                      id="estimate_time"
                      onChange={(date) => setDatePick(formatDate(date))}
                      onClick={(date) => setDatePick(formatDate(date))}
                      defaultValue={productData.estimate_time}
                      minDate={new Date()}
                    />
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <div className="form-group">
                      <CLabel htmlFor="">
                        {t("myproduct.ProductForm.currency")}*
                      </CLabel>

                      <CSelect
                        custom
                        name="product_currency"
                        id="product_currency"
                        onChange={handleChange}
                        className="form-control"
                        defaultValue={productData.price_currency}
                      >
                        {currencyData.map((option) => (
                          <option
                            value={option.value}
                            selected={
                              option.value === productData.price_currency
                            }
                          >
                            {option.label}
                          </option>
                        ))}
                      </CSelect>
                    </div>
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {/* Price type */} {t("myproduct.ProductForm.price_type")}
                      *
                    </CLabel>
                    <select
                      //   onChange={handleChange}
                      name="price_type"
                      id="price_type"
                      className="form-control"
                      onChange={Price}
                      disabled={true}
                      value={productData.pricing_type}
                      selected={productData.pricing_type}
                    >
                      <option value="FIXED">
                        {t("myproduct.ProductForm.price_type_data.fixed")}
                      </option>
                      <option value="RANGE">
                        {t("myproduct.ProductForm.price_type_data.range")}
                      </option>
                    </select>
                  </CCol>
                  {productData.pricing_type === "FIXED" && (
                    <CCol className="form-group" md={12}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("myproduct.ProductForm.price")}{" "}
                        <span>
                          ({t("myproduct.ProductForm.perkg")}/
                          {t("myproduct.ProductForm.perunit")})*
                        </span>
                      </CLabel>
                      <CInput
                        onChange={handleChange}
                        name="price_to"
                        id="price_to"
                        disabled={true}
                        className="form-control"
                        defaultValue={productData.price_to}
                      />
                    </CCol>
                  )}
                  {productData.pricing_type === "RANGE" && (
                    <>
                      <CCol className="form-group" md={6}>
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.priceFrom")}
                          <span>
                            ( {t("myproduct.ProductForm.perkg")}/
                            {t("myproduct.ProductForm.perunit")})*
                          </span>
                        </CLabel>
                        <CInput
                          onChange={handleChange}
                          name="price_from"
                          id="price_from"
                          disabled={true}
                          className="form-control"
                          defaultValue={productData.price_from}
                        />
                      </CCol>

                      <CCol className="form-group" md={6}>
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.priceto")}{" "}
                          <span>
                            ( {t("myproduct.ProductForm.perkg")}/
                            {t("myproduct.ProductForm.perunit")})*
                          </span>
                        </CLabel>
                        <CInput
                          onChange={handleChange}
                          name="price_to"
                          id="price_to"
                          className="form-control"
                          defaultValue={productData.price_to}
                        />
                      </CCol>
                    </>
                  )}
                  <CCol className="form-group" md={6}>
                    <CLabel className="product-label">
                      {t("myproduct.ProductForm.categories")}*
                    </CLabel>

                    <CSelect
                      custom
                      name="cateogories"
                      onChange={handleChange}
                      onClick={onClick}
                      id="cateogories"
                      className="form-control"
                    >
                      <option selected disabled>
                        {t("myproduct.ProductForm.select")}
                      </option>
                      {cateogory_value.map((el, i) => (
                        <option
                          value={el.id}
                          selected={productData?.category?.id === el?.id}
                        >
                          {el.title[0].name}
                        </option>
                      ))}
                    </CSelect>
                  </CCol>
                  <CCol className="form-group" md={6}>
                    <CLabel className="product-label">
                      {t("myproduct.ProductForm.subcategories")}
                    </CLabel>
                    <CSelect
                      name="subcateogories"
                      id="subcateogories"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option selected disabled>
                        {t("myproduct.ProductForm.select")}
                      </option>
                      {subcateogory_value
                        ? subcateogory_value.map((el, index) => (
                            <option
                              value={el.id}
                              key={index}
                              selected={productData.subcategory.id === el.id}
                            >
                              {el.title.length > 0 ? el.title[0].name : null}
                            </option>
                          ))
                        : ""}
                    </CSelect>
                  </CCol>
                  <CCol className="my-2" md={12}>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      name="shippment"
                      id="shippment"
                      onChange={onChange}
                      defaultChecked={defaultChecked}
                      disabled
                    />
                    <CLabel className="product-label ml-3" htmlFor="phone">
                      I will bear the shippment
                    </CLabel>
                    {shippment && (
                      <CLabel className="product-message ml-3" htmlFor="phone">
                        Hope you will add your shipping cost in product price
                      </CLabel>
                    )}
                  </CCol>
                  <CCol className="form-group" md={12}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.description")}*
                    </CLabel>
                    <CTextarea
                      rows="6"
                      onChange={handleChange}
                      name="description"
                      id="description"
                      className="form-control"
                      defaultValue={productData.description}
                    ></CTextarea>
                  </CCol>
                </CRow>
              </div>
              <div className="card dash_edit_card">
                <CLabel className="product-label" htmlFor="">
                  {t("myproduct.ProductForm.uploadImage")}
                </CLabel>

                <Upload
                    action="https://cho.direct/api/v1/upload-multiple/"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChangeImage}
                  onPreview={onPreview}
                  multiple={true}
                  maxCount={10}
                  headers={{
                    Authorization: ` Bearer ${token}`,
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    {" "}
                    {t("myproduct.ProductForm.upload")}
                  </Button>
                </Upload>
              </div>
            </div>

            <div className="form-group mb-4 float-right">
              <CButton
                className="btn btn-light"
                onClick={() => history.push({ pathname: "/myproducts" })}
              >
                {t("myproduct.ProductForm.cancel")}
              </CButton>
              <CButton
                className="btn-secondary ml-4"
                type="Submit"
                onClick={(e) =>
                  handleSaveDraft(e, "UNPUBLISHED", productData.id)
                }
              >
                {t("myproduct.ProductForm.saveAsDraft")}
              </CButton>
              <CButton
                className="btn cstm_btn ml-4"
                type="Submit"
                onClick={(e) => handleSubmit(e, "PUBLISHED", productData.id)}
              >
                {t("myproduct.ProductForm.submit")}
              </CButton>
            </div>
          </form>
          <ToastContainer />
        </div>
      )}
    </>
  );
};
export default EditProduct;
