import React, { useState, useEffect } from "react";
import { CButton, CInput, CLabel, CLink, CSelect } from "@coreui/react";
import Logo from "../../../assets/images/newLogo.png";
import login from "../../../assets/images/login.png";
import { useHistory } from "react-router";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import contries from "./coutry.json";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const { t } = useTranslation('common');
  const lang = localStorage.getItem("language");
  const history = useHistory();
  const initalValue = {
    name: "",
    phone: "",
    country_code: "",
    password: "",
    role: "",
    otp: "",
    cpassword: "",
    email: "",
    lang: "",
  };
  const [languages, setLanguage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [User, setUser] = useState(initalValue);
  const [current_value, setCurrent_value] = useState("Phone");
  const [formErrors, setFormErrors] = useState({});
  const [token, setToken] = useState(null);
  const [fixed, setFixed] = useState(false);
  let permissions = sessionStorage.getItem("permissions");
  const prev = () => {
    setCurrent_value("Phone");
  };
  const [disabled, setdisabled] = useState(true);
  const valid = (user) => {
    let errors = {};
    if (!user.name) {
      errors.name = `${t("errorMessages.required_field")}`;
    }
    if (!user.role) {
      errors.role = `${t("errorMessages.required_field")}`;
    }
    if (/[^0-9a-zA-Z]/.test(user.name)) {
      errors.name = `${t("errorMessages.signup.username_error")}`;
    }
    if (/^[0-9]+$/.test(user.name)) {
      errors.name = `${t("errorMessages.number_not_allowed")}`;
    }

    return errors;
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`)
      .then((res) => {
        setLanguage(res.data.data);
      })
      .catch((err) => toast.error(err));
  }, [loading, lang]);
  const otp = async () => {
    setFormErrors(valid(User));

    let data;
    if (User.email) {
      data = {};
      data.username = User.name;
      data.email_or_phone = User.email;
      data.verification_type = "email";
      data.lang = User.lang;
    }

    if (User.phone) {
      data = {};
      data.username = User.name;
      data.email_or_phone = User.phone.replace(/\s/g, "");
      data.phone_code = User.country_code;
      data.verification_type = "sms";
      data.lang = "en";
    }
    if (Object.keys(valid(User)).length === 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}auth/get-otp/?lang=${lang}`,
          data
        )
        .then((res) => {
          if (res.status === 200) {
            setCurrent_value("Otp");
            toast.success(`${t("errorMessages.signup.verification_success")}`);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          } else {
            toast.error("an error occured");
          }
        });
    }
  };
  const verify = async () => {
    let data;
    if (User.email) {
      data = {};
      data.otp = User.otp;
      data.email_or_phone = User.email;
      data.page = "signup";
      data.verification_type = "email";
    }

    if (User.phone) {
      data = {};
      data.otp = User.otp.replace(/\s/g, "");
      data.page = "signup";
      data.email_or_phone = User.phone.replace(/\s/g, "");;;
      data.phone_code = User.country_code;
      data.verification_type = "sms";
    }

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}auth/verify-otp/?lang=${lang}`,
        data
      )
      .then((res) => {
        if (res.status === 200) {
          setCurrent_value("Pass");
          setdisabled(false);
          setToken(res.data.token);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        } else {
          toast.error("an error occured");
        }
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...User, [name]: value });
  };

  const validate = (values) => {
    let errors = {};

    // const regex =
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    if (!values.password) {
      errors.password = `${t("errorMessages.required_field")}`;
    } else if (!regex.test(values.password)) {
      errors.password = `${t("errorMessages.signup.password_validation")}`;
    }
    if (!values.cpassword) {
      errors.cpassword = `${t("errorMessages.required_field")}`;
    } else if (values.password !== values.cpassword) {
      errors.cpassword = `${t("errorMessages.signup.password_match")}`;
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validate(User));

    let data;
    if (User.email) {
      data = {};

      data.username = User.name;
      data.email_or_phone = User.email;
      data.password = User.password;
      data.role = User.role;
      data.token = token;
      data.lang = User.lang;
      if (User.role === "CUSTOMER") {
        data.permissions = "BUYER";
      } else {
        data.permissions = "SERVICE PROVIDER";
      }
    }

    if (User.phone) {
      data = {};
      data.lang = "en";
      data.email_or_phone = User.phone.replace(/\s/g, "");
      data.country_code = User.country_code;
      data.username = User.name;
      data.password = User.password;
      data.role = User.role;
      data.token = token;
      if (User.role === "CUSTOMER") {
        data.permissions = "BUYER";
      } else {
        data.permissions = "SERVICE PROVIDER";
      }
    }

    if (Object.keys(validate(User)).length === 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}auth/register/?lang=${lang}`,
          data
        )
        .then((res) => {
          if (res.status === 201) {
            toast.success(`${t("errorMessages.signup.success")}`);
            setLoading(false);
            setUser({
              name: "",
              password: "",
              cpassword: "",
            });
            localStorage.setItem("accessToken", res.data.data.token);
            localStorage.setItem("paypalAccount", res.data.data.have_paypal_account);
            localStorage.setItem("permissions", res.data.data.permissions[0]);
            let productType = localStorage.getItem(
              "productType",
              "productType"
            );
            if (productType === "RANGE") {
              history.push({ pathname: "/create-offer" });
            } else if (productType === "FIXED") {
              setFixed(true);
              localStorage.setItem("fixed", fixed);
              history.push({ pathname: "/Checkout" });
            } else {
              history.push({ pathname: "/profile" });
            }
          }
        })
        .catch((err) => toast.error(err));
    }
  };
  const [key, setKey] = useState("signup");
  const handleSelect = (key) => {
    setKey(key);
    if (key === 0) {
      setUser(initalValue);
    }
    if (key === 1) {
      setUser(initalValue);
    }
    if (current_value === "Otp" && key === 0) {
      setCurrent_value("Phone");
    } else if (current_value === "Otp" && key === 1) {
      setCurrent_value("Phone");
    }
    if (current_value === "Pass" && key === 0) {
      setCurrent_value("Phone");
    } else if (current_value === "Pass" && key === 1) {
      setCurrent_value("Phone");
    }
  };

  return (
    <div className="login_signup only_signup">
      <div className="left" data-aos="zoom-in">
        <img width="100" height="auto" src={login} alt="timeline" />
      </div>
      <div className="right" data-aos="fade-left">
        <CLink to="/">
          <img width="100" height="auto" src={Logo} alt="Logo" />
        </CLink>
        <h2 className="heading"> {t("SignupForm.buttonText")}</h2>
        <Tabs
          className="logintabs"
          activeKey={key}
          onSelect={(k) => handleSelect(k)}
        >
          <Tab label={t("SignupForm.label1")} eventKey="login">
            <form action="/action_page.php" id="form" onSubmit={handleSubmit}>
              {current_value === "Phone" && (
                <div>
                  <div className="form-group">
                    <CLabel htmlFor="name">{t("SignupForm.sub_title1")}</CLabel>
                    <CInput
                      name="name"
                      id="name"
                      value={User.name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="John Doe"
                    />
                    {formErrors.name && (
                      <span className="error">{formErrors.name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="phone">
                      {t("SignupForm.sub_title2")}
                    </CLabel>
                    <CSelect
                      className="form-select"
                      aria-label="Default select example"
                      name="role"
                      id="role"
                      onChange={handleChange}
                      vaue={User.role}
                    >
                      <option>{t("SignupForm.Role.choose")}</option>
                      <option value="CUSTOMER">
                        {t("SignupForm.Role.Buyer")}
                      </option>
                      <option value="CUSTOMER">
                        {t("SignupForm.Role.Farmer")}
                      </option>
                      {permissions === "FARMER" ? (
                        <option value="SERVICEPROVIDER" selected>
                          {t("SignupForm.Role.ServiceProvider")}
                        </option>
                      ) : (
                        <option value="SERVICEPROVIDER">
                          {t("SignupForm.Role.ServiceProvider")}
                        </option>
                      )}
                    </CSelect>
                    {formErrors.role && (
                      <span className="error">{formErrors.role}</span>
                    )}
                  </div>
                  <div className="form-group">
                    {
                      <div>
                        <div className="country_select">
                          <CSelect
                            className="select"
                            name="country_code"
                            id="country_code"
                            onChange={handleChange}
                          >
                            {" "}
                            {contries.map((e, index) => (
                              <option value={e.dial_code} key={index}>
                                {e.name}({e.dial_code})
                              </option>
                            ))}
                          </CSelect>
                          <CInput
                            name="phone"
                            id="phone"
                            value={User.phone}
                            onChange={handleChange}
                            className="input"
                            placeholder="999 888 7774"
                          />{" "}
                        </div>

                        <h6>
                          {t("SignupForm.footer.title1")}{" "}
                          <CLink to="/login" className="head">
                            {t("SignupForm.footer.signin")}
                          </CLink>
                        </h6>
                        <div>
                          <CButton onClick={otp} className="cstm_btn otp">
                            {t("SignupForm.next")}
                          </CButton>
                        </div>
                      </div>
                    }
                  </div>{" "}
                </div>
              )}
              {current_value === "Otp" && (
                <div className="form-group">
                  {" "}
                  <CLabel htmlFor="name">{t("SignupForm.otp.code")}</CLabel>
                  <CInput
                    name="otp"
                    id="otp"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="------"
                  />{" "}
                  <CButton className="next_btn" onClick={verify}>
                    {t("SignupForm.next")}{" "}
                  </CButton>
                  <CButton className="next_btn" onClick={prev}>
                    {t("SignupForm.prev")}{" "}
                  </CButton>
                  <span className="resend_btn" onClick={otp}>
                    {t("SignupForm.resend")}
                  </span>
                </div>
              )}
              {current_value === "Pass" && (
                <div>
                  <div className="form-group">
                    <CLabel htmlFor="password">
                      {" "}
                      {t("SignupForm.password")}
                    </CLabel>
                    <CInput
                      type="password"
                      name="password"
                      id="password"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Password"
                    />
                    {formErrors.password && (
                      <span className="error">{formErrors.password}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="password">
                      {" "}
                      {t("SignupForm.confirmPassword")}
                    </CLabel>
                    <CInput
                      type="password"
                      name="cpassword"
                      id="cpassword"
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Password"
                    />
                    {formErrors.cpassword && (
                      <span className="error">{formErrors.cpassword}</span>
                    )}
                  </div>
                  <label className="mt-3 mb-4 checkbox_label">
                    <input
                      type="checkbox"
                      className="mr-3"
                      onChange={handleChange}
                      name="checkbox"
                      required
                    />
                    {t("SignupForm.agree")}
                    <CLink to="/terms" className="terms_link">
                      &nbsp; {t("SignupForm.termsandcondition")}
                    </CLink>
                  </label>
                  <div className="form-group">
                    <CButton
                      type="submit"
                      id="sign_up"
                      disabled={disabled}
                      className="btn cstm_btn"
                    >
                      {t("SignupForm.buttonText")}
                    </CButton>
                  </div>

                  <h6>
                    {" "}
                    {t("SignupForm.footer.title1")}
                    <CLink to="/login">{t("SignupForm.footer.signin")}</CLink>
                  </h6>
                </div>
              )}
              <ToastContainer />
            </form>
          </Tab>
          <Tab label={t("SignupForm.label2")} eventKey="signup">
            <form
              action="/action_page.php"
              id="email_form"
              onSubmit={handleSubmit}
            >
              {current_value === "Phone" && (
                <div>
                  <div className="form-group">
                    <CLabel htmlFor="name">
                      {" "}
                      {t("SignupForm.sub_title1")}
                    </CLabel>
                    <CInput
                      name="name"
                      id="name"
                      value={User.name}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="John Doe"
                    />
                    {formErrors.name && (
                      <span className="error">{formErrors.name}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="phone">
                      {" "}
                      {t("SignupForm.sub_title2")}
                    </CLabel>
                    <CSelect
                      name="role"
                      id="role"
                      onChange={handleChange}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option> {t("SignupForm.Role.choose")}</option>
                      <option value="CUSTOMER">
                        {" "}
                        {t("SignupForm.Role.Buyer")}
                      </option>
                      <option value="CUSTOMER">
                        {" "}
                        {t("SignupForm.Role.Farmer")}
                      </option>
                      {permissions === "FARMER" ? (
                        <option value="SERVICEPROVIDER" selected>
                          {t("SignupForm.Role.ServiceProvider")}
                        </option>
                      ) : (
                        <option value="SERVICEPROVIDER">
                          {t("SignupForm.Role.ServiceProvider")}
                        </option>
                      )}
                    </CSelect>
                    {formErrors.role && (
                      <span className="error">{formErrors.role}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="name">{t("SignupForm.sub_title3")}</CLabel>
                    <CInput
                      name="email"
                      id="email"
                      onChange={handleChange}
                      className="form-control"
                      // placeholder="Nhập email của bạn"
                    />
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="name">
                      {t("SignupForm.prefferedLang")}
                    </CLabel>
                    <CSelect
                      onChange={handleChange}
                      name="lang"
                      id="lang"
                      className="form-control"
                    >
                      <option disabled selected value="en">
                        {t("SignupForm.select")}
                      </option>
                      {languages.map((e, index) => (
                        <option value={e.code} key={index}>
                          {e.language}({e.code})
                        </option>
                      ))}
                    </CSelect>
                    {formErrors.lang && (
                      <span className="error">{formErrors.lang}</span>
                    )}
                  </div>
                  <div>
                    {
                      <div>
                        <div className="country_select"></div>

                        <h6>
                          {t("SignupForm.footer.title1")}
                          <CLink to="/login" className="head">
                            {t("SignupForm.footer.signin")}
                          </CLink>
                        </h6>
                        <div>
                          <CButton className="cstm_btn opt" onClick={otp}>
                            {t("SignupForm.next")}{" "}
                          </CButton>
                        </div>
                      </div>
                    }
                  </div>{" "}
                </div>
              )}
              {current_value === "Otp" && (
                <div className="form-group">
                  {" "}
                  <CLabel htmlFor="name">{t("SignupForm.otp.code")}</CLabel>
                  <CInput
                    name="otp"
                    id="otp"
                    value={User.otp}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="------"
                  />{" "}
                  <div>
                    <CButton className="next_btn" onClick={verify}>
                      {t("SignupForm.next")}
                    </CButton>
                    <span className="resend_btn" onClick={otp}>
                      {" "}
                      {t("SignupForm.resend")}
                    </span>
                  </div>
                </div>
              )}
              {current_value === "Pass" && (
                <div>
                  <div className="form-group">
                    <CLabel htmlFor="password">
                      {t("SignupForm.password")}
                    </CLabel>
                    <CInput
                      type="password"
                      name="password"
                      id="password"
                      value={User.password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Password"
                    />
                    {formErrors.password && (
                      <span className="error">{formErrors.password}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="password">
                      {t("SignupForm.confirmPassword")}
                    </CLabel>
                    <CInput
                      type="password"
                      name="cpassword"
                      id="cpassword"
                      value={User.cpassword}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Password"
                    />
                    {formErrors.cpassword && (
                      <span className="error">{formErrors.cpassword}</span>
                    )}
                  </div>
                  <label className="mt-3 mb-4 checkbox_label">
                    <input
                      type="checkbox"
                      className="mr-3"
                      onChange={handleChange}
                      name="checkbox"
                      required
                    />
                    {t("SignupForm.agree")}
                    <CLink to="/terms" className="terms_link">
                      &nbsp; {t("SignupForm.termsandcondition")}
                    </CLink>
                  </label>
                  <div className="form-group">
                    <CButton
                      type="submit"
                      id="sign_up"
                      disabled={disabled}
                      className="btn cstm_btn"
                    >
                      {t("SignupForm.buttonText")}
                    </CButton>
                  </div>
                  <h6>
                    {t("SignupForm.footer.title1")}
                    <CLink to="/login" className="head">
                      {t("SignupForm.footer.signin")}
                    </CLink>
                  </h6>
                </div>
              )}
              <ToastContainer />
            </form>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Signup;
