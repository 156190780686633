import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import "./TheHeader.css";

const TheHeaderDropdownNotif = () => {
  let history = useHistory();
  let token = localStorage.getItem("accessToken");
  const [unread, setUnRead] = useState();
  const [notTitle, setNotTitle] = useState();
  // var conn = new WebSocket("ws://https://cho.direct/api/v1/notifications/");
  const lang = localStorage.getItem("language");
  const userName = localStorage.getItem("username");

  const [notificationData, setNotification] = useState([]);
  const apiCall = {
    action: "unread_notification",
    request_id: userName,
    token: token,
    close: false,
  };
  const unreadCount = {
    action: "unread_notification_count",
    request_id: userName,
    token: token,
    close: false,
  };
  useEffect(() => {
    const ws = new WebSocket("wss://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(apiCall));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setNotification(json.data);
            // setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
    //clean up function
    return () => ws.close();
  }, []);
  const updateNotification = () => {
    const notificationUpdate = {
      action: "mark_as_read_notification",
      request_id: userName,
      token: token,
      close: false,
    };
    const ws = new WebSocket("wss://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(notificationUpdate));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setUnRead(json.data);
            // setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
    // const payload = {};
    // axios
    //   .patch(
    //     `${process.env.REACT_APP_BASE_URL}event/notification/?lang=${lang}`,
    //     payload,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     setUnRead(0);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  useEffect(() => {
    const ws = new WebSocket("wss://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(unreadCount));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setUnRead(json.data);
            // setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
  }, []);
  const handlePage = (value) => {
    if (value.type === "OFFER" && value.data.user_type === "FARMER") {
      history.push({ pathname: `/myoffers/${value.link}` });
    } else if (value.type === "ORDER" && value.data.user_type === "BUYER") {
      history.push({ pathname: `/order-b/${value.link}` });
    } else if (value.type === "ORDER" && value.data.user_type === "FARMER") {
      history.push({ pathname: `/order-s/${value.link}` });
    } else if (value.type === "BACKOUT" && value.data.user_type === "BUYER") {
      history.push({ pathname: `/order-b/${value.link}` });
    }
  };
  const seeAll = () => {
    history.push({ pathname: "/dashboard" });
  };

  return (
    <>
      <CDropdown inNav className="c-header-nav-item mx-4 notification">
        <CDropdownToggle
          className="c-header-nav-link"
          caret={false}
          onClick={updateNotification}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-bell"
          >
            <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
          </svg>
          {unread === 0 ? null : (
            <CBadge
              shape="pill"
              className="badge badge-pill badge-primary pull-right notification-badge"
            >
              {unread}
            </CBadge>
          )}
        </CDropdownToggle>
        <CDropdownMenu placement="bottom-end" className="p-0">
          <div className="mt-3 mb-3">
            <div className="noti-body d-flex justify-content-between">
              <h5 className="">Notification</h5>
              <span className="see-all" onClick={seeAll}>
                See All
              </span>
            </div>
            {Array.isArray(notificationData) === true ? (
              notificationData.length > 0 ? (
                notificationData
                  ?.filter((item, idx) => idx < 5)
                  .map((item) => (
                    <>
                      <CDropdownItem className="top-text-block">
                        <div
                          className="top-text-heading"
                          onClick={() => handlePage(item)}
                        >
                          {item.title}
                        </div>
                      </CDropdownItem>
                    </>
                  ))
              ) : (
                <p className="text-center m-2">No Notification</p>
              )
            ) : (
              <CDropdownItem className="top-text-block">
                <div className="top-text-heading">{notTitle?.title}</div>
              </CDropdownItem>
            )}
          </div>
        </CDropdownMenu>
      </CDropdown>
    </>
  );
};

export default TheHeaderDropdownNotif;
