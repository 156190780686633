import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
  CLink,
} from "@coreui/react";
import Auth from "../../../Auth";
import dummyProfile from "../../../assets/images/profile.jpeg";
import axios from "axios";
import { useTranslation } from "react-i18next";

const TheHeaderDropdown = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const lang = localStorage.getItem("language");
  const logout = () => {
    Auth.signout();
    localStorage.clear();
    localStorage.clear();
    window.location.href = "/index";
  };

  const handleMainPgae = () => {
    localStorage.removeItem("Order_id");
    localStorage.removeItem("Order_id");
    history.push({ pathname: "/index" });
  };
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setImage(res.data.data.profile_image);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, [loading]);

  return (
    <>
      {!loading && (
        <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <div className="c-avatar">
              <CImg
                src={image !== null && image.length > 0 ? image : dummyProfile}
                className="c-avatar-img"
                alt="#"
              />
            </div>
          </CDropdownToggle>
          <CDropdownMenu placement="bottom-end">
            <CDropdownItem>
              <CLink to="/profile">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user mr-2"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  {/* Edit Profile */}
                  {t("dashboardHeader.editProfile")}
                </div>
              </CLink>
            </CDropdownItem>
            <CDropdownItem onClick={handleMainPgae}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-user mr-2"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>

                {t("dashboardHeader.mainPage")}
              </div>
            </CDropdownItem>
            <CDropdownItem onClick={logout}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-log-out mr-2"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
              </svg>
              {t("dashboardHeader.Logout")}
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      )}
    </>
  );
};
export default TheHeaderDropdown;
