import React, { useState } from "react";
import { useHistory } from "react-router";
import { CButton, CInput, CLabel } from "@coreui/react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UpdatePassword = (value) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const [show, setShow] = useState(false)
    const [confirmShow, setConfirmShow] = useState(false)
    const state = {
        oldpassword: "",
        newPassword: "",
        cpassword: "",
    };
    const [initialvalue, setInitialValue] = useState(state);
    const [formErrors, setFormErrors] = useState({});
    const token = localStorage.getItem("accessToken");
    const lang = localStorage.getItem("language");
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInitialValue({ ...initialvalue, [name]: value });
    };
    const handleShow = () => {
        setShow(!show)
    }
    const handleConfirm = () => {
        setConfirmShow(!confirmShow)
    }

    const validate = (values) => {
        let errors = {};

        // const regex =
        //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (!values.oldpassword) {
            errors.oldpassword = `${t("errorMessages.required_field")}`;
        }
        if (!values.newPassword) {
            errors.newPassword = `${t("errorMessages.required_field")}`;
        } else if (!regex.test(values.newPassword)) {
            errors.newPassword = `${t("errorMessages.signup.password_validation")}`;
        }
        if (!values.cpassword) {
            errors.cpassword = `${t("errorMessages.required_field")}`;
        } else if (values.newPassword !== values.cpassword) {
            errors.cpassword = `${t("errorMessages.signup.password_match")}`;
        }
        return errors;
    };
    const handleSubmit = async (event) => {
        // const lang = localStorage.getItem("langauge");
        event.preventDefault();
        setFormErrors(validate(initialvalue));
        let data = {
            old_password: initialvalue.oldpassword,
            password: initialvalue.newPassword,
        };

        if (Object.keys(validate(initialvalue)).length === 0) {
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}auth/updatepassword/?lang=${lang}`, data, {
                    headers: {
                        Authorization: ` Bearer ${token}`,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(`${t("errorMessages.password.success")}`);
                        history.push({ pathname: "/" });
                        localStorage.clear();
                    }
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        for (let i of err.response.data.message) {
                            toast.error(`${i.error}`);
                        }
                    }
                });
        }
    };

    return (
        <div className="w-50">
            <div>
                <div className="headingWrap">
                    <h3 className="title">{t("Change_Password.title")}</h3>
                </div>

                <form
                    action=""
                    id="form"
                    className="dash_edit_card"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <div className="form-group focused">
                        <CLabel htmlFor="phone" className="form-control-label">
                            {t("Change_Password.label1")}
                        </CLabel>
                        <CInput
                            name="oldpassword"
                            type="password"
                            id="oldpassword"
                            value={initialvalue.oldpassword}
                            onChange={handleChange}
                            className="form-control form-control-alternative"
                            placeholder={t("Change_Password.placeholder1")}
                        />
                        {formErrors.oldpassword && (
                            <span className="error">{formErrors.oldpassword}</span>
                        )}
                    </div>
                    <div className="form-group focused">
                        <CLabel htmlFor="phone" className="form-control-label">
                            {t("Change_Password.label2")}
                        </CLabel>
                        <div className="d-flex">
                        <CInput
                                type={show ? "text" : "password"}
                            name="newPassword"
                            id="newPassword"
                            value={initialvalue.newPassword}
                            onChange={handleChange}
                            className="form-control form-control-alternative"
                            placeholder={t("Change_Password.placeholder2")}
                        />
                            {show ?
                                <i className="far fa-eye" onClick={handleShow}></i>
                                : <i className="fas fa-eye-slash" onClick={handleShow}></i>}
                        </div>
                        {formErrors.newPassword && (
                            <span className="error">{formErrors.newPassword}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <CLabel htmlFor="phone">Confirm password</CLabel>
                        <div className="d-flex">
                            <CInput
                                type={confirmShow ? "text" : "password"}
                                name="cpassword"
                                id="cpassword"
                                value={initialvalue.cpassword}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Confirm Password"
                            />
                            {confirmShow ?
                                <i className="far fa-eye" onClick={handleConfirm}></i>
                                : <i className="fas fa-eye-slash" onClick={handleConfirm}></i>}
                        </div>
                        {formErrors.cpassword && (
                            <span className="error">{formErrors.cpassword}</span>
                        )}
                    </div>

                    <div className="form-group float-right">
                        <CButton type="submit" className="btn cstm_btn">
                            {t("Change_Password.saveButton")}
                        </CButton>
                    </div>
                    <ToastContainer />
                </form>
            </div>
        </div>
    );
};

export default UpdatePassword;
