import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";

import Loader from "../../../../Components/loader/index";
import { toast, ToastContainer } from "react-toastify";
// import Moment from 'moment'
import Backout from "../../Backout/backout";
import { useTranslation } from "react-i18next";

const ContractsView = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const paramId = props.match.params.id;
  const url = props.match.url;
  const currentValue = url.split("/");
  const current_value = currentValue[currentValue.length - 2];
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [backout, setBackout] = useState(false);
  const [backout_modal, setBackout_modal] = useState(false);
  //   const [data, setData] = useState({ backout_reason: "" });
  const [payment, setPayment] = useState(false);
  let token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  // const handleChange = (e) => {
  //     const name = e.target.name;
  //     const value = e.target.value;

  //     setData({ ...data, [name]: value });
  // };

  // const handleSubmit = async (event) => {

  //     event.preventDefault();
  //     const formatDate =
  //         `${Date().toLocaleString()} `;
  //     let date = Moment(formatDate).format("YYYY-MM-DD H:mm:ss")
  //     let payload = {
  //         id: paramId,
  //         backout: true,
  //         backout_reason: data.backout_reason,
  //         backout_date: date
  //     }
  //     axios
  //         .post(
  //             `${process.env.REACT_APP_BASE_URL}payment/backout/`, payload,
  //             {
  //                 headers: {
  //                     Authorization: `Bearer ${token}`,
  //                 },
  //             }
  //         ).then((res) => {
  //             if (res.status == 200) {
  //                 toast.success("you have successfully backout this product")
  //                 setBackout_modal(false);
  //                 window.reload();
  //             }
  //         }).catch((err) => {
  //             if (err.response !== undefined) {
  //                 for (let i of err.response.data.message) {
  //                     toast.error(`${i.error}`);
  //                 }
  //             } else {
  //                 toast.error("an error occured");
  //             }
  //         })

  // }
  const getData = () => {
    if (current_value === "contracts-b") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/contract-b/?id=${paramId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => toast.error(err.response));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/contract-s/?id=${paramId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => toast.error(err.response.detail));
    }
  };
  useEffect(() => {
    getData();
    let path = window.location.href.split("/");
    let buyer_path = path[path.length - 2];
    if (buyer_path === "contracts-b") {
      setPayment(true);
    } else {
      setPayment(false);
    }
    let seller_path = path[path.length - 2];
    if (seller_path === "contracts-s") {
      setBackout(true);
    } else {
      setBackout(false);
    }
    // eslint-disable-next-line
  }, []);
  const onHide = () => {
    setShow(false);
    setBackout_modal(false);
  };
  const onBackout = () => {
    setShow(false);
    setBackout_modal(true);
  };
  const handlePayment = (value) => {
    localStorage.setItem("Order_id", value.id);
    localStorage.setItem("productId", value.product.id);
    history.push({ pathname: "/checkout" });
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row contracts">
            <div className="headingWrap">
              <h3 className="title">{t("contracts.contractsView.title")}</h3>

              {backout && !body.backout && body.is_final_paid === false && (
                <div className="ml-auto">
                  <button
                    className="btn cstm_btn"
                    onClick={() => setShow(true)}
                  >
                    {t("contracts.contractsView.backout")}
                  </button>
                </div>
              )}

              {payment && !body.is_final_paid && !body.backout && (
                <div className="ml-auto">
                  <button
                    className="btn cstm_btn"
                    onClick={() => handlePayment(body)}
                  >
                    {t("contracts.contractsView.payment")}
                  </button>
                </div>
              )}
            </div>
            <Backout
              show={show}
              onHide={onHide}
              backout_modal={backout_modal}
              setBackout_modal={setBackout_modal}
              onBackout={onBackout}
              id={paramId}
            />
            <div className="contract-grid">
              {body.is_earnest_paid === true && (
                <p>
                  {t("contracts.contractsView.earnest_amount")}:
                  <span>{body.earnest_amount}</span>
                </p>
              )}
              {body.is_final_paid === true && (
                <p>
                  {t("contracts.contractsView.final_amount")}:
                  <span>{body.final_amount}</span>
                </p>
              )}
              <p>
                {t("contracts.contractsView.total_paid")}:
                <span>{body.total_paid}</span>
              </p>

              {body.is_refund_paid === true && (
                <p>
                  {t("contracts.contractsView.refund_amount")}:
                  <span>{body.refund_amount}</span>
                </p>
              )}
              <p>
                {t("contracts.contractsView.status")}
                <span>{body.status}</span>
              </p>
              <p>
                {t("contracts.contractsView.cutofPayment")}:
                <span>{body.cut_off_payment}</span>
              </p>

              <p>
                {t("contracts.contractsView.currency")}:
                <span>{body.currency}</span>
              </p>
              <p>
                {t("contracts.contractsView.unit")}:<span>{body.unit}</span>
              </p>
              <p>
                {t("contracts.contractsView.quantity")}:
                <span>{body.quantity}</span>
              </p>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <h4>{t("contracts.contractsView.billingAddress")}</h4>
                <p>
                  {t("contracts.contractsView.address")}:
                  <span>{body.billing.address}</span>
                </p>
                <p>
                  {t("contracts.contractsView.city")}:
                  <span>{body.billing.city}</span>
                </p>
                <p>
                  {t("contracts.contractsView.state")}:
                  <span>{body.billing.state}</span>
                </p>
                <p>
                  {t("contracts.contractsView.postal_code")}:
                  <span>{body.billing.postal_code}</span>
                </p>
                <p>
                  {t("contracts.contractsView.country")}:
                  <span>{body.billing.country}</span>
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <h4>{t("contracts.contractsView.shippingAddress")}</h4>
                <p>
                  {t("contracts.contractsView.address")}:
                  <span>{body.shipping.address}</span>
                </p>
                <p>
                  {t("contracts.contractsView.city")}:
                  <span>{body.shipping.city}</span>
                </p>
                <p>
                  {t("contracts.contractsView.state")}:
                  <span>{body.shipping.state}</span>
                </p>
                <p>
                  {t("contracts.contractsView.postal_code")}:
                  <span>{body.shipping.postal_code}</span>
                </p>
                <p>
                  {t("contracts.contractsView.country")}:
                  <span>{body.shipping.country}</span>
                </p>
              </div>
              {body.backout && (
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <h4>{t("contracts.contractsView.backout")}</h4>
                  <p>
                    {t("contracts.contractsView.backoutDate")}:
                    <span>{body.backout_date}</span>
                  </p>
                  <p>
                    {t("contracts.contractsView.backoutReason")}:
                    <span>{body.backout_reason}</span>
                  </p>
                </div>
              )}

              <div
                className={
                  body.backout === false
                    ? "col-lg-4 col-md-4 col-sm-6"
                    : "col-12"
                }
              >
                <h4>Product {t("contracts.contractsView.product")}</h4>
                <div className={body.backout === false ? "d-block" : "d-flex"}>
                  <p>
                    {t("contracts.contractsView.productName")}:
                    {body.product.product_name.map((item) =>
                      item.language.code === lang ? (
                        <span> {item.name}</span>
                      ) : null
                    )}
                  </p>
                  <p>
                    {t("contracts.contractsView.productCode")}:
                    <span>{body.product.product_code}</span>
                  </p>
                  <p>
                    {t("contracts.contractsView.createdby")}:
                    <span>
                      {body.product.user.username.charAt(0).toUpperCase() +
                        body.product.user.username.slice(1)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </>
      )}
    </>
  );
};
export default ContractsView;
