import React, { useState } from "react";
import { useHistory } from "react-router";
import { CButton, CInput, CLabel, CLink } from "@coreui/react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Auth from "../../../Auth";
import Logo from "../../../assets/images/newLogo.png";
import login from "../../../assets/images/login.png";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [fixed, setFixed] = useState(false);
  const lang = localStorage.getItem("language");
  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = `${t("errorMessages.required_field")}`;
    }

    if (!values.password) {
      errors.password = `${t("errorMessages.required_field")}`;
    }

    return errors;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };
  const handleSubmit = async (event) => {
    setFormErrors(validate(data));

    event.preventDefault();

    document.getElementById("form").reset();
    const payload = {
      username: data.name.replace(/\s/g, ""),
      password: data.password,
    };

    if (Object.keys(validate(data)).length === 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}auth/login/?lang=${lang}`,
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success(`${t("errorMessages.login.success")}`);
            localStorage.setItem("accessToken", res.data.data.token);
            Auth.authenticate();
            localStorage.setItem("paypalAccount", res.data.data.have_paypal_account);
            localStorage.setItem("accessToken", res.data.data.token);
            localStorage.setItem("permissions", res.data.data.permissions[0]);
            localStorage.setItem("language", res.data.data.language.code);
            localStorage.setItem("username", res.data.data.username);
            history.push({ pathname: "/dashboard" });
            setData({
              name: "",
              password: "",
            });
            let productType = localStorage.getItem(
              "productType",
              "productType"
            );
            if (productType === "RANGE") {
              history.push({ pathname: "/create-offer" });
            } else if (productType === "FIXED") {
              setFixed(true);
              localStorage.setItem("fixed", fixed);
              history.push({ pathname: "/Checkout" });
            } else  {
              history.push({ pathname: "/dashboard" });
            }
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
  };

  return (
    <div className="login_signup" style={{ backgroundColor: "white" }}>
      <div className="left" data-aos="zoom-in">
        <img width="100" height="auto" src={login} alt="timeline" />
      </div>
      <div className="right" data-aos="fade-left">
        <CLink to="/">
          <img width="100" height="auto" src={Logo} alt="Logo" />
        </CLink>
        <h2 className="heading"> {t("LoginForm.buttonText")}</h2>
        <form action="" id="form" onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <CLabel htmlFor="phone" className="text-left">
              {" "}
              {t("LoginForm.label1")}
            </CLabel>

            <CInput
              name="name"
              id="name"
              value={data.name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter Your Username or Mobile no. or Email id"
            />
            {formErrors.name && (
              <span className="error">{formErrors.name}</span>
            )}
          </div>
          <div className="form-group">
            <CLabel htmlFor="phone"> {t("LoginForm.label2")}</CLabel>

            <CInput
              type="password"
              name="password"
              id="password"
              value={data.password}
              onChange={handleChange}
              className="form-control"
              placeholder="Password"
            />
            {formErrors.password && (
              <span className="error">{formErrors.password}</span>
            )}
          </div>
          <div className="form-group">
            <CButton type="submit" className="btn cstm_btn login_btn">
              {t("LoginForm.buttonText")}
            </CButton>
          </div>
          <ToastContainer />
        </form>
        <h6>
          {t("LoginForm.footer.title1")}
          <CLink
            className="head"
            onClick={() => (window.location.href = "/signup")}
          >
            {t("LoginForm.footer.signup")}
          </CLink>
        </h6>
        <h6>
          <CLink to="/forgot" className="head">
            {" "}
            {t("LoginForm.footer.forgot_password")}{" "}
          </CLink>
        </h6>
      </div>
    </div>
  );
};

export default Login;
