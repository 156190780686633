import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    CCol,
    CContainer,
    CRow
} from '@coreui/react';
import { useHistory } from "react-router";
import thankImg from "../../../../assets/images/Successful purchase-cuate.png"

const PayPalThankyou = (props) => {
  const url = window.location.href;
  console.log(url, "ull");

  const history = useHistory();
  let paypalAccount = localStorage.getItem("paypalAccount");
  let token = localStorage.getItem("accessToken");
  const [code, setCode] = useState("");
  useEffect(async () => {
    if (url.includes("code")) {
      setCode(url.split("code")[1].split("&")[0].split("=")[1]);
    }
    if (paypalAccount === "false" && code.length > 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}auth/paypal-account/`,
          {
            code: code,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          localStorage.setItem("paypalAccount", true);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [code]);
  return (
    <div className="c-app bg-white c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center text-center">
          <CCol md="12">
            <div className="clearfix">
              <img
                width=""
                height=""
                src={thankImg}
                alt="thank you"
                className="thankyou_img"
              />
            </div>
            <div>
              <h2 className="pt-3 display-3">Account Added Successfully</h2>
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={() => history.push({ pathname: "/order" })}
              >
                Return Home
              </button>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
export default PayPalThankyou;