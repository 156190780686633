import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import TestTable from "../../../../Components/table/testTable";
import Loader from "../../../../Components/loader/index";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, Tab } from "react-bootstrap-tabs";
import "../contracts/contracts.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import reset from "../../../../../assets/images/reload.svg";
import "./order-history.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import vi from "date-fns/locale/vi";
import { useTranslation } from "react-i18next";
import Modal from "../../confirmModal/ConfirmModal";
import Rating from "../../Rating/Rating";

const OrderHistory = (props) => {
  const { t } = useTranslation("common");
  const [buyerData, setBuyerData] = useState();
  const [sellerData, setSellerData] = useState();
  const [serviceData, setServiceData] = useState();
  const [loading, setLoading] = useState(true);
  const { getContracts } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [ratingPop, setRatingPop] = useState(false);
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState("");
  const [show, setShow] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [from, setFrom] = useState("");
  const [end, setEnd] = useState("");
  const [dateModal, setDateModal] = useState(false);
  const lang = localStorage.getItem("language");
  useEffect(() => {
    BuyerGetData();
    SellerGetData();
    ServiceGetData();
    // eslint-disable-next-line
  }, []);
  let permissions = localStorage.getItem("permissions");
  const [tabValue, SetTabvalue] = useState(false);
  const BuyerGetData = () => {
    SetTabvalue(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/order-b/?lang=${lang}&type=SERVICE`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBuyerData(res.data.data);
        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };

  const SellerGetData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/order-s/?lang=${lang}&type=SERVICE`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSellerData(res.data.data);
        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };
  const ServiceGetData = () => {
    SetTabvalue(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/hire-b/?lang=${lang}&type=SERVICE`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data, "ressssssssssssssssssssssssssssss");
        setServiceData(res.data.data);

        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };
  const handleClick = (e, id, username) => {
    setUserId(id);
    setUserName(username);
    setShow(true);
  };
  const handleComplete = (e, value) => {
    const payload = {
      id: userId,
      service_status: value,
    };
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}payment/hire-b/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.message);
        // history.push({ pathname: "/dashboard" });
        setRatingPop(true);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };
  const onHide = () => {
    setShow(false);
  };
  const onCancel = () => {
    setRatingPop(false);
  };
  const formatDate = (date) =>
    `${new Date(date).toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })} `;
  const headingData = [
    {
      id: "1",
      Header: `${t("order.orderList.headingData.created")}`,
      accessor: (props) => formatDate(props.created),
    },
    {
      id: "2",
      Header: `${t("order.orderList.headingData.type")}`,
      accessor: "type",
    },
    {
      id: "3",
      Header: `${t("order.orderList.headingData.productName")}`,
      accessor: (props) =>
        props.product.product_name.map((item) =>
          item.language.code === lang ? (
            <span>
              {" "}
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ),
    },
    {
      id: "4",
      Header: `${t("order.orderList.headingData.currency")}`,
      accessor: "currency",
    },
    {
      id: "5",
      Header: `${t("order.orderList.headingData.quantity")}`,
      accessor: (props) => {
        return (
          <>
            {props.quantity !== 0 ? (
              <span>
                {props.quantity}
                &nbsp; {props.unit}
              </span>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
    },
    {
      id: "6",
      Header: `${t("order.orderList.headingData.earnest_amount")}`,
      accessor: (d) => {
        if (d.is_earnest_paid === true) {
          return <span>{d.earnest_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "7",
      Header: `${t("order.orderList.headingData.commission_paid")}`,
      accessor: "cut_off_payment",
    },
    {
      id: "8",
      Header: `${t("order.orderList.headingData.final_amount")}`,
      accessor: (d) => {
        if (d.is_final_paid === true) {
          return <span>{d.final_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "9",
      Header: `${t("order.orderList.headingData.amount")}`,
      accessor: "total_paid",
    },
    {
      id: "11",
      Header: `${t("order.orderList.headingData.pay_stat")}`,
      accessor: "pay_stat",
    },
    {
      id: "10",
      Header: `${t("order.orderList.headingData.status")}`,
      accessor: (d) => {
        if (
          d.service_status === "PENDING" ||
          d.service_status === "COMPLETED_FROM_SERVICE_PROVIDER"
        ) {
          return (
            <>
              {d.type === "SERVICE" ? (
                <button
                  className="btn  button_complete"
                  onClick={(e) => handleClick(e, d.id, d.product.user.username)}
                >
                  Complete
                </button>
              ) : (
                <span> -</span>
              )}
            </>
          );
        } else if (d.service_status === "COMPLETED_FROM_BUYER") {
          return <span>Completed</span>;
        }
      },
    },
  ];

  // const serviceHeading = [
  //   {
  //     id: "1",
  //     Header: `${t("order.orderList.headingData.created")}`,
  //     accessor: (props) => formatDate(props.created),
  //   },
  //   {
  //     id: "2",
  //     Header: `${t("order.orderList.headingData.productName")}`,
  //     accessor: (props) =>
  //       props.product.product_name.map((item) =>
  //         item.language.code === lang ? (
  //           <span>
  //             {" "}
  //             {item.name.length > 10
  //               ? item.name.substring(0, 10) + "..."
  //               : item.name}
  //           </span>
  //         ) : null
  //       ),
  //   },
  //   {
  //     id: "3",
  //     Header: `${t("order.orderList.headingData.currency")}`,
  //     accessor: "currency",
  //   },
  //   // {
  //   //   id: "5",
  //   //   Header: `${t("order.orderList.headingData.earnest_amount")}`,
  //   //   accessor: (d) => {
  //   //     if (d.is_earnest_paid === true) {
  //   //       return <span>{d.earnest_amount}</span>;
  //   //     } else {
  //   //       return <span>-</span>;
  //   //     }
  //   //   },
  //   // },
  //   {
  //     id: "6",
  //     Header: `${t("order.orderList.headingData.commission_paid")}`,
  //     accessor: "cut_off_payment",
  //   },
  //   {
  //     id: "7",
  //     Header: `${t("order.orderList.headingData.final_amount")}`,
  //     accessor: (d) => {
  //       if (d.is_final_paid === true) {
  //         return <span>{d.final_amount}</span>;
  //       } else {
  //         return <span>-</span>;
  //       }
  //     },
  //   },
  //   {
  //     id: "8",
  //     Header: `${t("order.orderList.headingData.amount")}`,
  //     accessor: "total_paid",
  //   },
  //   {
  //     id: "9",
  //     Header: `${t("order.orderList.headingData.pay_stat")}`,
  //     accessor: "pay_stat",
  //   },
  //   {
  //     id: "10",
  //     Header: `${t("order.orderList.headingData.final_amount")}`,
  //     accessor: (d) => {
  //       if (
  //         d.service_status === "PENDING" ||
  //         d.service_status === "COMPLETED_FROM_SERVICE_PROVIDER"
  //       ) {
  //         return (
  //           <button
  //             className="btn  button_complete"
  //             onClick={(e) =>
  //               handleComplete(
  //                 e,
  //                 "COMPLETED_FROM_BUYER",
  //                 d.id,
  //                 d.product.user.username
  //               )
  //             }
  //           >
  //             Complete
  //           </button>
  //         );
  //       } else if (d.service_status === "COMPLETED_FROM_BUYER") {
  //         return <span>Completed</span>;
  //       }
  //     },
  //   },
  // ];

  const handleId = (id) => {
    history.push(`/order-b/${id}`);
  };
  const sellerId = (id) => {
    history.push(`/order-s/${id}`);
  };
  const timeFrameModal = () => {
    setDateModal(!dateModal);
  };

  const handleSelect = (value) => {
    const start = selectionRange[0].startDate;
    const last = selectionRange[0].endDate;

    const startNow =
      start.getFullYear() +
      "-" +
      (start.getMonth() + 1) +
      "-" +
      start.getDate();
    const endNow =
      last.getFullYear() + "-" + (last.getMonth() + 1) + "-" + last.getDate();
    //   setFrom(startNow);
    //   setEnd(endNow);
    setDateModal(false);

    if (value === "buyer") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-b/?date_from=${startNow}&&date_to=${endNow}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(`${t("errorMessages.filter_success")}`);
          setBuyerData(res.data.data);
          setLoading(false);
          dispatch(getContracts(res.data));
        })
        .catch((err) => toast.error(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-s/?date_from=${startNow}&&date_to=${endNow}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("Filter successfully");
          setSellerData(res.data.data);
          setLoading(false);
          dispatch(getContracts(res.data));
        })
        .catch((err) => toast.error(err));
    }
  };
  function formateDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const resetFilter = (value) => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substr(0, 10);
    let from = "1980-05-11";
    const tomorrow = new Date();
    let to = formateDate(tomorrow);
    if (value === "seller") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-s/?date_from=${from}&&date_to=${to}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(`${t("errorMessages.reset_filter")}`);
          setSellerData(res.data.data);
          setLoading(false);
          dispatch(getContracts(res.data));
        })
        .catch((err) => toast.error(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-b/?date_from=${from}&&date_to=${to}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBuyerData(res.data.data);
          toast.success(`${t("errorMessages.reset_filter")}`);
          setLoading(false);
          dispatch(getContracts(res.data));
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
    setFrom(date);
    setEnd();
  };

  console.log(userName, "username");
  return (
    <div className="dashboardtabs">
      <>
        {permissions === "BUYER" || permissions === "FARMER" ? (
          <Tabs className="tabs">
            <Tab label={`${t("order.orderList.tab1")}`} disabled={tabValue}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="headingWrap">
                    <h3 className="title">{t("order.orderList.buyerorder")}</h3>
                    <div className="ml-auto">
                      <button onClick={timeFrameModal} className="btn cstm_btn">
                        <span>{t("order.orderList.sortbyDate")}</span>
                      </button>

                      {dateModal ? (
                        <div className="doneIt">
                          <div className="flex">
                            <DateRangePicker
                              locale={vi}
                              editableDateInputs={true}
                              rangeColors="#32297c"
                              color="#32297c"
                              moveRangeOnFirstSelection={false}
                              ranges={selectionRange}
                              onChange={(item) =>
                                setSelectionRange([item.selection])
                              }
                            />
                          </div>
                          <button
                            onClick={() => handleSelect("buyer")}
                            className="rangebtn"
                          >
                            {t("order.orderList.apply")}
                          </button>
                        </div>
                      ) : null}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {t("order.orderList.reset")}{" "}
                          </Tooltip>
                        }
                      >
                        <img
                          width=""
                          height=""
                          src={reset}
                          alt=""
                          className="reset-icon "
                          onClick={resetFilter}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  {buyerData && buyerData.length > 0 ? (
                    <div className="cursor_table">
                      <TestTable
                        headData={headingData}
                        bodyData={buyerData}
                        rowPerPage="5"
                        rowId={handleId}
                        paginationStatus={true}
                      />
                    </div>
                  ) : (
                    <div className="not-found">
                      <h2>{t("order.orderList.no_order")} </h2>
                    </div>
                  )}
                </>
              )}
            </Tab>

            <Tab label={`${t("order.orderList.tab2")}`} disabled={tabValue}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="headingWrap">
                    <h3 className="title">
                      {t("order.orderList.sellerorder")}
                    </h3>
                    <div className="ml-auto">
                      <button onClick={timeFrameModal} className="btn cstm_btn">
                        <span>{t("order.orderList.sortbyDate")}</span>
                        &nbsp;
                      </button>

                      {dateModal ? (
                        <div className="doneIt">
                          <div className="flex">
                            <DateRangePicker
                              locale={vi}
                              editableDateInputs={true}
                              rangeColors="#32297c"
                              color="#32297c"
                              moveRangeOnFirstSelection={false}
                              ranges={selectionRange}
                              onChange={(item) =>
                                setSelectionRange([item.selection])
                              }
                            />
                          </div>
                          <p className="rangeButton">
                            <button
                              onClick={() => handleSelect("seller")}
                              className="rangebtn"
                            >
                              {t("order.orderList.apply")}
                            </button>
                          </p>
                        </div>
                      ) : null}
                      <img
                        width=""
                        height=""
                        src={reset}
                        alt=""
                        className="reset-icon"
                        onClick={() => resetFilter("seller")}
                      />
                    </div>
                  </div>
                  {sellerData && sellerData.length > 0 ? (
                    <div className="cursor_table">
                      <TestTable
                        headData={headingData}
                        bodyData={sellerData}
                        rowPerPage="5"
                        rowId={sellerId}
                        paginationStatus={true}
                      />
                    </div>
                  ) : (
                    <div className="not-found">
                      <h2>{t("order.orderList.no_order")}</h2>
                    </div>
                  )}
                </>
              )}
            </Tab>

            {/* <Tab label={`${t("order.orderList.tab3")}`} disabled={tabValue}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="headingWrap">
                    <h3 className="title">
                      {t("order.orderList.serviceproviderorder")}
                    </h3>
                    <div className="ml-auto">
                      <button onClick={timeFrameModal} className="btn cstm_btn">
                        <span>{t("order.orderList.sortbyDate")}</span>
                      </button>

                      {dateModal ? (
                        <div className="doneIt">
                          <div className="flex">
                            <DateRangePicker
                              locale={vi}
                              editableDateInputs={true}
                              rangeColors="#32297c"
                              color="#32297c"
                              moveRangeOnFirstSelection={false}
                              ranges={selectionRange}
                              onChange={(item) =>
                                setSelectionRange([item.selection])
                              }
                            />
                          </div>
                          <button
                            onClick={() => handleSelect("buyer")}
                            className="rangebtn"
                          >
                            {t("order.orderList.apply")}
                          </button>
                        </div>
                      ) : null}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {t("order.orderList.reset")}{" "}
                          </Tooltip>
                        }
                      >
                        <img width="100" height="auto"
                          src={reset}
                          alt=""
                          className="reset-icon "
                          onClick={resetFilter}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  {serviceData && serviceData.length > 0 ? (
                    <div className="cursor_table">
                      <TestTable
                        headData={serviceHeading}
                        bodyData={serviceData}
                        rowPerPage="5"
                        rowId={handleId}
                        paginationStatus={true}
                      />
                    </div>
                  ) : (
                    <div className="not-found">
                      <h2>{t("order.orderList.no_order")} </h2>
                    </div>
                  )}
                </>
              )}
            </Tab> */}
          </Tabs>
        ) : (
          <Tabs className="tabs">
            <Tab label={`${t("order.orderList.tab1")}`} disabled={tabValue}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className="headingWrap">
                    <h3 className="title">{t("order.orderList.buyerorder")}</h3>
                    <div className="ml-auto">
                      <button onClick={timeFrameModal} className="btn cstm_btn">
                        <span>{t("order.orderList.sortbyDate")}</span>
                      </button>

                      {dateModal ? (
                        <div className="doneIt">
                          <div className="flex">
                            <DateRangePicker
                              locale={vi}
                              editableDateInputs={true}
                              rangeColors="#32297c"
                              color="#32297c"
                              moveRangeOnFirstSelection={false}
                              ranges={selectionRange}
                              onChange={(item) =>
                                setSelectionRange([item.selection])
                              }
                            />
                          </div>
                          <button
                            onClick={() => handleSelect("buyer")}
                            className="rangebtn"
                          >
                            {t("order.orderList.apply")}
                          </button>
                        </div>
                      ) : null}
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {t("order.orderList.reset")}{" "}
                          </Tooltip>
                        }
                      >
                        <img
                          width=""
                          height=""
                          src={reset}
                          alt=""
                          className="reset-icon "
                          onClick={resetFilter}
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                  {buyerData && buyerData.length > 0 ? (
                    <div className="cursor_table">
                      <TestTable
                        headData={headingData}
                        bodyData={buyerData}
                        rowPerPage="5"
                        rowId={handleId}
                        paginationStatus={true}
                      />
                    </div>
                  ) : (
                    <div className="not-found">
                      <h2>{t("order.orderList.no_order")} </h2>
                    </div>
                  )}
                </>
              )}
            </Tab>
          </Tabs>
        )}
      </>
      <ToastContainer />

      <Modal
        displayModal={show}
        closeModal={onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent mt-4">
                <h2 className="text-center">
                  I agree the service is completed upto my satisfication.
                </h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={onHide}
                  >
                    {t("deletemodal.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => handleComplete(e, "COMPLETED_FROM_BUYER")}
                  >
                    {/* {t("deletemodal.delete")} */}
                    complete
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />

      <Modal
        displayModal={ratingPop}
        closeModal={() => onCancel()}
        modalBody={
          <>
            <div className="m-auto p-4 service_modal">
              <h4 className="mt-3 mb-3">Rate your experience</h4>
              <h4>to</h4>

              <h4 className="user_name">{userName}</h4>
              <div className="react-star">
                <Rating username={userName} closeModal={() => onCancel()} />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};;;

export default OrderHistory;
