import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Components/loader/index";
import { toast, ToastContainer } from "react-toastify";

const HireHistoryView = (props) => {
  const paramId = props.match.params.id;
  //   const url = props.match.url;
  //   const currentValue = url.split("/");
  // const current_value = currentValue[currentValue.length - 2];
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");
  let token = localStorage.getItem("accessToken");

  const getData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/hire-s/?id=${paramId}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBody(res.data.data);
        setLoading(false);
      })
      .catch((err) => toast.error(err.response.data.detail));
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row contracts">
            <div className="col-6">
              <h3 className="title">Hire History Detail</h3>
            </div>

            <div className="contract-grid">
              <p>
                Total Paid:
                <span>{body.total_paid}</span>
              </p>
              <p>
                Earnest Amount:
                <span>{body.earnest_amount}</span>
              </p>

              <p>
                Final Amount:
                <span>{body.final_amount}</span>
              </p>
              <p>
                Refund Amount:
                <span>{body.refund_amount}</span>
              </p>
              {/* </div>
              <div className="col-4"> */}
              <p>
                Earnest Payment Paid:
                <span>{body.is_earnest_paid.toString()}</span>
              </p>

              <p>
                Final Payment Paid:
                <span>{body.is_final_paid.toString()}</span>
              </p>
              <p>
                Refund Payment Paid:
                <span>{body.is_refund_paid.toString()}</span>
              </p>
              <p>
                Status :<span>{body.status}</span>
              </p>

              <p>
                Cut Off Payment:
                <span>{body.cut_off_payment}</span>
              </p>

              <p>
                Currency :<span>{body.currency}</span>
              </p>
              <p>
                Unit :<span>{body.unit}</span>
              </p>
              <p>
                Quantity :<span>{body.quantity}</span>
              </p>
            </div>
            <div className="row mt-4">
              <div className="col-4">
                <h4>Billing Address</h4>
                <p>
                  Address:
                  <span>{body.billing.address}</span>
                </p>
                <p>
                  City:
                  <span>{body.billing.city}</span>
                </p>
                <p>
                  State:
                  <span>{body.billing.state}</span>
                </p>
                <p>
                  Postal Code:
                  <span>{body.billing.postal_code}</span>
                </p>
                <p>
                  Country:
                  <span>{body.billing.country}</span>
                </p>
              </div>
              <div className="col-4">
                <h4>Shipping Address</h4>
                <p>
                  Address:
                  <span>{body.shipping.address}</span>
                </p>
                <p>
                  City:
                  <span>{body.shipping.city}</span>
                </p>
                <p>
                  State:
                  <span>{body.shipping.state}</span>
                </p>
                <p>
                  Postal Code:
                  <span>{body.shipping.postal_code}</span>
                </p>
                <p>
                  Country:
                  <span>{body.shipping.country}</span>
                </p>
              </div>
              {body.backout && (
                <div className="col-4">
                  <h4>Backout</h4>
                  <p>
                    BackOut Date:
                    <span>{body.backout_date}</span>
                  </p>
                  <p>
                    BackOut Reason:
                    <span>{body.backout_reason}</span>
                  </p>
                </div>
              )}

              <div className={body.backout === false ? "col-4" : "col-12"}>
                <h4>Product</h4>
                <div className={body.backout === false ? "d-block" : "d-flex"}>
                  <p>
                    Product Name:
                    <span>{body.product.product_name[0].name}</span>
                  </p>

                  <p>
                    Created by User :
                    <span>
                      {body.product.user.username.charAt(0).toUpperCase() +
                        body.product.user.username.slice(1)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </>
      )}
    </>
  );
};
export default HireHistoryView;
