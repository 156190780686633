import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./chatDesign.css";
import { socket } from "../../../../socket";
import Modal from "../confirmModal/ConfirmModal";
const DashboardChat = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [chatLists, setChatLists] = useState([]);
  // const [chatDesignList, setChatDesignList] = useState(false);
  const [message, setMessage] = useState();
  const [roomID, setRoomId] = useState("");
  let token = localStorage.getItem("accessToken");
  // const [typing, setTyping] = useState(false);
  // const [messageDiv, setMessageDiv] = useState(false);
  // const [unreadMessage, setUnreadMessage] = useState([]);
  const [chatImage, setChatImage] = useState("");
  const [chatUserName, setChatUserName] = useState("");
  const [modalImage, setModalImage] = useState(false);
  const [chatDesignShow, setChatDesignShow] = useState(false);
  // let screenSize = window.matchMedia("(max-width: 756px)").matches;
  const [msgInputValue, setMsgInputValue] = useState("");
  // const [messages, setMessages] = useState([]);
  // const [typingUserName, setTypingUserName] = useState({});
  const [userOnline, setUserOnline] = useState([]);
  // const [gets, setGets] = useState(false);
  const [imageFile, setImageFile] = useState({});
  const [imageUpload, setImageUpload] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setMsgInputValue({ ...message, [name]: value });
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    // window.scrollTo(0, 0)
    scrollToBottom();
  }, [chatHistory]);

  const handleGetChats = () => {
    socket.emit("get_chats", {
      user: {
        user_id: profileData.id.toString(),
        email: profileData.username,
        name: profileData.first_name,
        profile_image: profileData.profile_image,
      },
    });

    socket.on("set_chats", (data) => {
      console.log(data.chats, "datchatttt");
      setChatList(data.chats);
    });
  };
  useEffect(() => {
    // window.scrollTo(0, 0)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}auth/profile/`, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {
        setProfileData(res.data.data);
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      Object.keys(profileData).length > 0 &&
      profileData !== undefined &&
      profileData !== null
    ) {
      handleGetChats();
    }
    //eslint-disable-next-line
  }, [profileData]);

  const handleClick = (value, user_id) => {
    setRoomId(value);
    let get_messages = {
      room: value,
      user_id: user_id,
    };
    socket.emit("get_messages", get_messages);
    socket.off("set_messages").on("set_messages", (resp) => {
      // setMessageDiv(true);
      setChatHistory(resp.messages.data.messages);
      // if (screenSize === true) {
      //   setChatDesignList(true);
      // }
      setChatDesignShow(true);
      scrollToBottom();
      if (resp.messages.data.room.participants) {
        for (let i = 0; i < resp.messages.data.room.participants.length; i++) {
          if (
            resp.messages.data.room.participants[i].email !==
            profileData.username
          ) {
            setChatUserName(resp.messages.data.room.participants[i].email);
            setChatImage(resp.messages.data.room.participants[i].profile_image);
          }
        }
      }
    });

    let connectdata = {
      user: {
        user_id: profileData.id.toString(),
        email: profileData.username,
        name: profileData.first_name,
        profile_image: profileData.profile_image,
      },
    };
    socket.emit("connected", connectdata);

    let messageRead = {
      room: value,
      user: {
        user_id: profileData.id.toString(),
        email: profileData.username,
        name: profileData.first_name,
        profile_image: profileData.profile_image,
      },
    };
    socket.emit("read_status", messageRead);
    socket.on("read_status_show", (resp) => {
      // setUnreadMessage(resp.data);
    });
    socket.emit("get_chats", {
      user: {
        user_id: profileData.id.toString(),
        email: profileData.username,
        name: profileData.first_name,
        profile_image: profileData.profile_image,
      },
    });

    socket.on("set_chats", (data) => {
      setChatList(data.chats);
    });
  };

  const handleSend = (e, message) => {
    e.preventDefault();
    // setMessages([
    //   ...chatHistory,
    //   {
    //     message,
    //     direction: "outgoing",
    //   },
    // ]);
    setMsgInputValue("");
    // inputRef.current.focus();
    if (msgInputValue.messageData.length > 0) {
      let new_message = {
        room: roomID,
        message_body: msgInputValue.messageData,
        type: "USER_TEXT_MESSAGE",
        user: {
          user_id: profileData.id.toString(),
          email: profileData.username,
          name: profileData.first_name,
          profile_image: profileData.profile_image,
        },
      };
      socket.emit("new_message", new_message);
      document.getElementById("form").reset();
      setMsgInputValue({ messageData: "" });

      socket.off("message_broadcast").on("message_broadcast", (resp) => {
        // setLatestMessage(resp.message_body);
        setChatHistory((chatHistory) => [...chatHistory, resp.message_body]);
        // setLoading(false);
        handleGetChats();
        if (resp.client_id !== profileData.id.toString()) {
          let messageRead = {
            room: roomID,
            user: {
              user_id: profileData.id.toString(),
              email: profileData.username,
              name: profileData.first_name,
            },
          };

          socket.emit("read_status", messageRead);
          // socket.on("read_status_show", (resp) => {
          //   // setUnreadMessage(resp.data);
          //   setGets(true);
          // });
        }
      });
    }
  };

  const onFocus = () => {
    let start_typing = {
      room: roomID,
      user: {
        user_id: profileData.id,
        email: profileData.username,
        name: profileData.first_name,
        profile_image: profileData.profile_image,
      },
    };

    socket.emit("typing_start", start_typing);
    socket.on("show_start_typing", (resp) => {
      // setTyping(true);
      // setTypingUserName(resp.user);
    });
  };
  const onBlur = () => {
    let start_typing = {
      room: roomID,
      user: {
        user_id: profileData.id,
        email: profileData.username,
        name: profileData.first_name,
        profile_image: profileData.profile_image,
      },
    };

    socket.emit("typing_end", start_typing);
    socket.on("show_end_typing", (resp) => {
      // setTyping(false);
      // setTypingUserName(resp.user);
    });
  };

  useEffect(() => {
    socket.off("message_broadcast").on("message_broadcast", (resp) => {
      // setLatestMessage(resp.message_body);
      setChatHistory((chatHistory) => [...chatHistory, resp.message_body]);
      // setLoading(false);

      if (resp.client_id !== profileData.id.toString() && roomID) {
        let messageRead = {
          room: roomID,
          user: {
            user_id: profileData.id.toString(),
            email: profileData.username,
            name: profileData.first_name,
          },
        };
        socket.emit("read_status", messageRead);
        // socket.on("read_status_show", (resp) => {
        //   // setUnreadMessage(resp.data);
        //   setGets(true);
        // });
      }
      socket.emit("get_chats", {
        user: {
          user_id: profileData.id.toString(),
          email: profileData.username,
          name: profileData.first_name,
          profile_image: profileData.profile_image,
        },
      });

      socket.on("set_chats", async (data) => {
        await setChatList(data.chats);
      });
    });
    let bData = [];
    if (chatList !== undefined && chatList.length > 0 && chatList !== -1) {
      chatList.forEach((e) => {
        let result = {};
        result = e;
        e.status = "unavailable";
        // status = e.status;

        let data = { ...result };
        bData.push(data);
        return null;
      });
      setChatLists(bData);
    }
    //eslint-disable-next-line
  }, [chatList]);

  useEffect(() => {
    if (
      Object.keys(profileData).length > 0 &&
      profileData !== undefined &&
      profileData !== null
    ) {
      socket.emit("online_status", {
        user_id: profileData.id.toString(),
      });
      socket.on("online_status_show", (data) => {
        setUserOnline(data);
      });
    }
  }, [profileData]);
  useEffect(() => {
    const dataoo = chatLists.map((el) => {
      userOnline.map((ell) => {
        if (ell.email === el.name) {
          el.status = "available";
          return el;
        }
      });
      return el;
    });
    setChatLists(dataoo);
    //eslint-disable-next-line
  }, [userOnline]);
  const onChangeImage = (e) => {
    setModalImage(true);
    // if (e.target.files[0]) {

    const reader = new FileReader();
    let k = e.target.files[0];
    var formdata = new FormData();
    formdata.append("file", k, k.name);
    setImageUpload(formdata);
    reader.addEventListener("load", () => {
      setImageFile(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
    // }
  };

  const handleSubmit = (e) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}upload-multiple/`, imageUpload, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {
        setModalImage(false);
        let new_message = {
          room: roomID,
          message_body: res.data.data[0],
          type: "USER_IMAGE_MESSAGE",
          user: {
            user_id: profileData.id.toString(),
            email: profileData.username,
            name: profileData.first_name,
            profile_image: profileData.profile_image,
          },
        };
        socket.emit("new_message", new_message);

        setMessage({ messageData: "" });

        socket.off("message_broadcast").on("message_broadcast", (resp) => {
          // setLatestMessage(resp.message_body);
          setChatHistory((chatHistory) => [...chatHistory, resp.message_body]);
          handleGetChats();
          if (resp.client_id !== profileData.id.toString()) {
            let messageRead = {
              room: roomID,
              user: {
                user_id: profileData.id.toString(),
                email: profileData.username,
                name: profileData.first_name,
              },
            };

            socket.emit("read_status", messageRead);
            // socket.on("read_status_show", (resp) => {
            //   // setUnreadMessage(resp.data);
            //   setGets(true);
            // });
          }
        });
      })
      .catch((err) => console.log(err.response, "i am errr"));
  };
  const OnSubmit = () => {};
  const onHide = () => {
    setModalImage(false);
  };
  const listView = () => {
    setChatDesignShow(false);
  };

  return (
    <>
      <div>
        <div className="main-veiw">
          <div className="row">
            {chatLists.length > 0 ? (
              <>
                <div
                  className={`${
                    chatDesignShow === true
                      ? "message-list col-lg-4 col-md-4 col-sm-12 d-block d-none d-lg-block d-md-block d-sm-none"
                      : "col-lg-4 col-md-4 col-sm-12 message-list d-block  d-lg-block d-md-block d-sm-block"
                  }`}
                >
                  <div className="list">
                    {chatLists.map((el) => (
                      <>
                        <div
                          className="contact "
                          onClick={() => handleClick(el.id, profileData.id)}
                        >
                          <div className="icon">
                            <img
                              width=""
                              height=""
                              src={el.image}
                              alt="kitten"
                            />
                          </div>
                          <div className="blurb">
                            <h2 className="name">{el.name}</h2>
                          </div>
                          {el.unread_messages_count !== 0 ? (
                            <span className="unread_count">
                              {el.unread_messages_count}
                            </span>
                          ) : null}
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                <div
                  className={`${
                    chatDesignShow === true
                      ? "col-lg-8 col-md-8 col-sm-12  chat_design"
                      : "chat_content"
                  }`}
                >
                  <div className="top">
                    <div className="open-message" onClick={listView}>
                      <i className="fa fa-chevron-left"></i>
                    </div>
                    <div className="name" id="open">
                      <img width="100" height="auto" src={chatImage} alt="" />
                      <p> {chatUserName}</p>
                    </div>
                  </div>
                  <div className="message-container">
                    {chatHistory.map((item) => {
                      return (
                        <>
                          {item.type === "USER_TEXT_MESSAGE" && (
                            <>
                              {item.sender.user_id != profileData.id ? (
                                <div
                                  className="comment other"
                                  ref={messagesEndRef}
                                >
                                  <img
                                    width=""
                                    height=""
                                    src={item.sender.profile_image}
                                    alt=""
                                  />
                                  <div className="bubble">
                                    {item.message_body}
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}

                          {item.type === "USER_TEXT_MESSAGE" && (
                            <>
                              {item.sender.user_id == profileData.id ? (
                                <>
                                  <div
                                    className="comment me"
                                    ref={messagesEndRef}
                                  >
                                    <img
                                      width=""
                                      height=""
                                      src={item.sender.profile_image}
                                      alt=""
                                    />
                                    <div className="bubble">
                                      {item.message_body}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}

                          {item.type === "USER_IMAGE_MESSAGE" && (
                            <>
                              {item.sender.user_id != profileData.id ? (
                                <div
                                  className="comment other"
                                  ref={messagesEndRef}
                                >
                                  <img
                                    width=""
                                    height=""
                                    src={item.sender.profile_image}
                                    alt=""
                                  />
                                  <div className="bubble">
                                    <img
                                      width=""
                                      height=""
                                      src={item.message_body}
                                      alt="message_icon"
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}

                          {item.type === "USER_IMAGE_MESSAGE" && (
                            <>
                              {item.sender.user_id == profileData.id ? (
                                <>
                                  <div
                                    className="comment me"
                                    ref={messagesEndRef}
                                  >
                                    <img
                                      width=""
                                      height=""
                                      src={item.sender.profile_image}
                                      alt="message_icon"
                                    />
                                    <div className="bubble">
                                      <img
                                        width=""
                                        height=""
                                        src={item.message_body}
                                        alt="message_icon"
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      );
                    })}
                    {/* <div ref={messagesEndRef} /> */}
                  </div>
                  <form
                    action="file.php"
                    id="form"
                    onSubmit={(e) => handleSend(e)}
                    autoComplete="off"
                  >
                    <div className="input-container">
                      <form className="chat-attachment" onSubmit={OnSubmit}>
                        <input
                          id="file"
                          type="file"
                          onChange={onChangeImage}
                          multiple
                          accept="image/*"
                          className="d-none"
                        />
                        <label htmlFor="file">
                          <span
                            tabIndex="0"
                            role="button"
                            aria-controls="filename"
                          >
                            <i className="fa fa-paperclip ml-3"></i>
                          </span>
                        </label>

                        <br />
                      </form>

                      {/* <button className="attach" onClick={onChangeImage}>
                                          <i className="fa fa-paperclip"></i>
                                      </button> */}
                      <div className="textarea-container">
                        {/* <textarea type="text" placeholder="message" id="messageData"
                                          name="messageData" onSend={() => handleSend()}
                                          onChange={handleChange}></textarea> */}
                        <input
                          className="form-control message_input"
                          type="text"
                          placeholder="Type a message..."
                          id="messageData"
                          name="messageData"
                          onChange={handleChange}
                          onFocus={onFocus}
                          onBlur={onBlur}
                        />
                      </div>
                      <input
                        className="send_btn ml-3"
                        type="submit"
                        value="Send"
                        placeholder="Type a message..."
                        onKeyDown={(e) => handleSend(e)}
                      />
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <div className="not-found ">
                <h2>No Chat Yet</h2>
              </div>
            )}
          </div>
        </div>
        <Modal
          displayModal={modalImage}
          closeModal={onHide}
          modalBody={
            <>
              <div className="modal-body">
                <div className="modalcontent">
                  <div>
                    {imageFile !== null &&
                      imageFile.length !== 0 &&
                      imageFile.length > 0}
                    {
                      <img
                        width=""
                        height=""
                        id="blah"
                        src={imageFile}
                        alt="your icon"
                        className="w-100"
                      />
                    }
                  </div>
                  <div className="modal-button">
                    <button
                      type="submit"
                      className="btn btn-light"
                      onClick={onHide}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};
export default DashboardChat;
