import { PaymentActionTypes } from '../constants/action-types';

export const setPayment = (payments) => {

    return {
        type: PaymentActionTypes.SET_PAYMENT,
        payload: payments
    }
}
export const getpayments = (payment) => {

    return {
        type: PaymentActionTypes.GET_PAYMENTS,
        payload: payment
    }
}
