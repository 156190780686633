import React, { useEffect, useState } from "react";
import Search from "./searchbox";
import { CCol, CRow } from "@coreui/react";
import Body from "../dashboard/products/Body";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Products = () => {
	const { t } = useTranslation('common');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState();
	const getLang = localStorage.getItem("language");
	useEffect(() => {
		window.scrollTo(0, 0)
		axios
			.get(
				`${process.env.REACT_APP_BASE_URL}adminpanel/get-advertisement/?title=Product&language=${getLang}&position=Right`
			)
			.then((res) => {
				setData(res.data.data);
		  setLoading(false);
	  })
		.catch((err) => {
			if (err.response !== undefined) {
				for (let i of err.response.data.message) {
					toast.error(`${i.error}`);
				}
			}
		});
	}, [getLang, loading]);
	return (
    <div>
		  <div className="cstm_header product_details_header">
			  <Search />
		  </div>
		  <div className="main-product collection-otr">
			  <CRow>
				  <CCol className="text-center" lg={12}>
					  <h2 className="heading" data-aos="fade-up">
						  {" "}
						  {t("myproduct.product.title")}
					  </h2>
				  </CCol>
			  </CRow>

			  <div className="container position-relative">
				  <Body />
				  <div className="side_add_cont">
						{data.map((item, index) => {
						  return (
                <img
                  width=""
                  height=""
                  src={item.image}
                  alt="card img"
                  key={index}
                />
              );
					  })}
				  </div>
			  </div>
		  </div>
	  </div>
  );
};

export default Products;
