import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getpayments } from "src/redux/actions/Paymentactions";
import deleteImg from "../../../../assets/images/delete.svg";
import ChipImage from "../../../../assets/images/card_icon.png";

import Modal from "../confirmModal/ConfirmModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Payment from "./payment";
import Loader from "../../../Components/loader/index";
import { useTranslation } from "react-i18next";

const AccountDetail = (props) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("accessToken");
    let Order_id = localStorage.getItem("Order_id");
    let OrderId = localStorage.getItem("OrderId");
    const lang = localStorage.getItem("language");
    useEffect(() => {
      handleGetData();
      // eslint-disable-next-line
    }, [loading]);
    const handleGetData = () => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}payment/card/?lang=${lang}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(res.data.data);
          dispatch(getpayments(res.data.data));
          setLoading(false);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    };

    const onHide = () => {
      setShow(false);
    };
    const removeAccountHandler = (id) => {
      setDeleteId(id);
      setShow(true);
    };

    const handleDelete = () => {
      const payload = { id: deleteId };
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}payment/card/?id=${deleteId}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success("successfully delete");
            handleGetData();
            setShow(false);
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
            setShow(false);
          }
        });
    };
    const editAccountHandler = (editId) => {
      const payload = { id: editId };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}payment/card/?lang=${lang}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message.success);
            handleGetData();
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            console.log(err.response, "response");
          }
        });
    };
    // const handleAdd = () => {
    //     history.push({ pathname: "/payment" });
    // };
    const handlePayment = (value) => {
      localStorage.setItem("cardId", value);
      history.push({ pathname: "./final-checkout" });
    };
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="scroll_div" style={{ backgroundColor: "white" }}>
            <div data-aos="fade-left">
              {data.length > 0 ? (
                <>
                  {/* <div className="d-flex border-bottom">
                                     
                                   
                                  </div> */}
                  <div className="d-lg-flex d-md-block  justify-content-around">
                    <div className="w-100">
                      <h5>{t("payments.title")}</h5>
                      <div className="steps_card_info w-100">
                        {data.map((value, index) => {
                          return (
                            <>
                              <div className="" key={index}>
                                <div className="card_box mt-4">
                                  <img
                                    width=""
                                    height=""
                                    src={ChipImage}
                                    alt="chip"
                                  />
                                  <div className="card_type">
                                    {value.card_type}
                                  </div>
                                  <div className="card_number">
                                    ●●●●&nbsp;●●●●&nbsp;●●●●&nbsp;
                                    {value.last_card}
                                  </div>
                                  {OrderId || Order_id ? (
                                    <>
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn cstm_btn w-100"
                                        onClick={() => handlePayment(value.id)}
                                      >
                                        {t("payments.payment")}
                                      </button>
                                      <div className="mt-2" variant="danger">
                                        {" "}
                                        {t("payments.payment_message")}
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                                <div className="row mt-4">
                                  {value.is_primary === false ? (
                                    <>
                                      <div className="col-6">
                                        <button
                                          type="button"
                                          className="btn btn-outline-info w-100"
                                          onClick={() =>
                                            editAccountHandler(value.id)
                                          }
                                          disabled={
                                            value.is_primary === true
                                              ? true
                                              : null
                                          }
                                        >
                                          Set as primary
                                        </button>
                                      </div>
                                      <div className="col-6">
                                        <button
                                          type="button"
                                          className="btn btn-outline-danger w-100"
                                          onClick={() =>
                                            removeAccountHandler(value.id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="w-100">
                      <h5 className="w-lg-100">
                        {t("payments.CardDetailForm.title")}
                      </h5>
                      <Payment />
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <h5 className="w-100 border-bottom">
                    {t("payments.CardDetailForm.title")}
                  </h5>
                  <Payment />
                </div>
              )}
            </div>
          </div>
        )}
        <Modal
          displayModal={show}
          closeModal={onHide}
          modalBody={
            <>
              <div className="modal-body">
                <div className="modalcontent">
                  <div>
                    <img
                      width=""
                      height=""
                      src={deleteImg}
                      alt=""
                      className="icon_wrap"
                    />
                  </div>
                  <h2>Are you sure you want to delete</h2>
                  <div className="modal-button">
                    <button
                      type="submit"
                      className="btn btn-light"
                      onClick={onHide}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleDelete}
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
        />

        <ToastContainer />
      </>
    );
};
export default AccountDetail;
