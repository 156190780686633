import {ActionTypes} from '../constants/action-types';

export const setProduct=(products)=>{
    return {
        type:ActionTypes.SET_PRODUCTS,
        payload: products
    }
}
export const selectedProduct=(product)=>{
    return {
        type:ActionTypes.SELECTED_PRODUCTS,
        payload:product
    }

}
export const subcatId=(id)=>{

    return {
        type:ActionTypes.SUBCAT_ID,
        payload:id
    }

}

export const product_id=(id)=>{

    return {
        type:ActionTypes.PRODUCT_ID,
        payload:id
    }

}
export const own_product = (value) => {

    return {
        type: ActionTypes.OWN_PRODUCT,
        payload: value
    }

}

export const checkbox_id=(id)=>{

  return {
      type:ActionTypes.CHECKBOX_ID,
      payload:id
  }

}
export const search_state = (value) => {
    return {
        type: ActionTypes.SEARCH_STATE,
        payload: value
    }
}
export const offer_state = (value) => {
    return {
        type: ActionTypes.OFFER_STATE,
        payload: value
    }
}
