import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const MyServiceView = (props) => {
  const [body, setBody] = useState([]);
  const paramId = props.match.params.id;
  let token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?type=PRODUCT&id=${paramId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBody(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response);
      });
  }, [paramId, token]);

  return (
    <>
      <div class="service_provider_details_cont scroll_div">
        <div class="container position-relative">
          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-12">
                  <div class="info_outer" data-aos="fade-up">
                    <div class="info">
                      <img
                        width=""
                        height=""
                        src="https://cho.direct:8000/api/v1/media/edef76c5a5a74586a79eb2853fc809f2577632-35a7974404bf44318e571e1a3d98f2cb577756.jpg"
                        alt="profile pic"
                      />
                      <div>
                        <div class="name">
                          {" "}
                          {body.product_name.map((item) =>
                            item.language.code === lang ? (
                              <h4>{item.name}</h4>
                            ) : null
                          )}
                        </div>
                        <div class="rating">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <span>(25 Reviews)</span>
                        </div>
                      </div>
                    </div>
                    <div class="dets">
                      <div class="price">
                        $50.00<sup>1ha</sup>
                      </div>
                      {/* <button class="btn chat_btn">
                        <i class="fas fa-comment-alt"></i>Chat
                      </button> */}
                    </div>
                  </div>
                </div>
                <div class="col-md-12" data-aos="fade-up">
                  <h3>
                    <span>Work Information</span>
                  </h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmd tempor incididunt ut labore et dolore magna
                    aliqua. Lorem ipsum dlor sit amet, consectetur adipiscing
                    magna aliqua. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmd tempor incididunt ut labore
                    et dolore magna aliqua. Lorem ipsum dlor sit amet,
                    consectetur adipiscing magna aliqua.
                  </p>
                  <ul>
                    <li>Consectetur adipiscing elit, sed do eiusmd tempor.</li>
                    <li>Incididunt ut labore et dolore magna aliqua.</li>
                    <li>Lorem ipsum dlor sit amet, consectetur adipiscing.</li>
                    <li>Magna aliqua. Lorem ipsum dolor sit amet.</li>
                    <li>
                      Sed do eiusmd tempor incididunt ut labore et dolore magna
                      aliqua.
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmd tempor incididunt ut labore et dolore magna
                    aliqua. Lorem ipsum dlor sit amet, consectetur adipiscing
                    magna aliqua. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmd tempor incididunt ut labore
                    et dolore magna aliqua. Lorem ipsum dlor sit amet,
                    consectetur adipiscing magna aliqua.
                  </p>
                  <h3>
                    <span>Equipments</span>
                  </h3>
                  <ol>
                    <li>Lorem ipsum dolor</li>
                    <li>Adipiscing elit sed do eiusmd</li>
                    <li>tempor incididunt ut labore</li>
                    <li>Magna aliqua. Lorem ipsum dolor sit amet.</li>
                  </ol>
                  {/* <a href="" class="btn cstm_btn">
                    Hire Now
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyServiceView;
