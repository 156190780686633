import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CButton, CInput, CLabel, CLink } from "@coreui/react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Auth from "../../../Auth";
import { offer_state } from "src/redux/actions/Productactions";
import { Modal } from "react-bootstrap";
import { user_name } from "src/redux/actions/Useraction";
import { useTranslation } from "react-i18next";

const LoginModal = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    password: "",
  });
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [fixed, setFixed] = useState(false);
  let productId = localStorage.getItem("productId");
  let permissions = localStorage.getItem("permissions");
  const lang = localStorage.getItem("language");
  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = `${t("errorMessages.required_field")}`;
    }

    if (!values.password) {
      errors.password = `${t("errorMessages.required_field")}`;
    }
    return errors;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (event) => {
    setFormErrors(validate(data));
    event.preventDefault();
    document.getElementById("form").reset();
    const payload = {
      username: data.name.replace(/\s/g, ""),
      password: data.password,
    };

    if (Object.keys(validate(data)).length === 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}auth/login/?lang=${lang}`,
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            Auth.authenticate();
            localStorage.setItem("accessToken", res.data.data.token);
            localStorage.setItem(
              "paypalAccount",
              res.data.data.have_paypal_account
            );
            toast.success(`${t("errorMessages.login.success")}`);
            localStorage.setItem("permissions", res.data.data.permissions[0]);
            dispatch(user_name(res.data.data.username));
            let productType = localStorage.getItem("productType");
            localStorage.setItem("username", res.data.data.username);

            setData({
              name: "",
              password: "",
            });
            if (productId !== undefined) {
              dispatch(offer_state(true));
            }
            if (productType === "FIXED" && permissions === "FARMER") {
              setFixed(true);
              localStorage.setItem("fixed", fixed);
              history.push({ pathname: "/Checkout" });
            }
            props.closeModal();
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
  };
  function closeModal(e) {
    document.getElementById("form").reset();
    setFormErrors({});
    props.closeModal();
  }
  return (
    <Modal show={props.displayModal} onHide={closeModal}>
      <Modal.Header>
        <span className="close pull-right" onClick={closeModal}>
          &times;
        </span>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        <div>
          <div className="pt-4">
            <h3>{t("LoginForm.headerTitle")}</h3>
            <div className="mt-4 dash_edit_card">
              <form
                action=""
                id="form"
                className="text-left"
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="form-group">
                  <CLabel htmlFor="phone" className="text-left">
                    {" "}
                    {t("LoginForm.label1")}
                  </CLabel>
                  <CInput
                    name="name"
                    id="name"
                    value={data.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder={t("LoginForm.email_placeholder")}
                    autoComplete="off"
                  />
                  {formErrors.name && (
                    <span className="error">{formErrors.name}</span>
                  )}
                </div>
                <div className="form-group">
                  <CLabel htmlFor="phone"> {t("LoginForm.label2")}</CLabel>
                  <CInput
                    type="password"
                    name="password"
                    id="password"
                    value={data.password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder={t("LoginForm.password_placeholder")}
                    autoComplete="off"
                  />
                  {formErrors.password && (
                    <span className="error">{formErrors.password}</span>
                  )}
                </div>
                <div className="form-group text-center">
                  <CButton
                    type="submit"
                    className="btn cstm_btn login_btn login_test"
                  >
                    {t("LoginForm.buttonText")}
                  </CButton>
                </div>
                <ToastContainer />
              </form>
              <div className="d-flex justify-content-between">
                <h6>
                  {t("LoginForm.footer.title1")}
                  <CLink
                    className="head"
                    onClick={() => (window.location.href = "/signup")}
                  >
                    {t("LoginForm.footer.signup")}
                  </CLink>
                </h6>
                <h6>
                  <CLink
                    onClick={() => (window.location.href = "/forgot")}
                    className="head"
                  >
                    {" "}
                    {t("LoginForm.footer.forgot_password")}{" "}
                  </CLink>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
