import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Loader from "../../../Components/loader/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CInput, CLabel } from "@coreui/react";
import Modal from "../confirmModal/ConfirmModal";
import "antd/dist/antd.css";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
const FinalView = (props) => {
  const [clientAccessToken, setClientAccessToken] = useState("");
  const { t } = useTranslation("common");
  const history = useHistory();
  const [productname, setProductname] = useState("");
  const [productdata, setProductdata] = useState("");
  const [billing, setBilling] = useState("");
  // const [earnestPaid, setEarnestPaid] = useState(false);
  // const [finalPaid, setFinalPaid] = useState(false);
  let token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  let billingId = localStorage.getItem("billingAddress");
  // let cardId = localStorage.getItem("cardId");
  let orderId = localStorage.getItem("OrderId");
  let Order_id = localStorage.getItem("Order_id");
  const [checked, setChecked] = useState(true);
  const [symbol, setSymbol] = useState("$");
  const [productdes, setProductdes] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [shippingId, setShippingId] = useState({});
  // const [ratingPop, setRatingPop] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [details, setDetails] = useState({
    address: "",
    country: "",
    city: "",
    state: "",
    postal_code: "",
  });
  // const [userName, setUserName] = useState();
  const lang = localStorage.getItem("language");
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}payment/payment-access-token/?lang=${lang}`,
        {},
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setClientAccessToken(res.data.data.access_token);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (Order_id) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-b/?id=${Order_id}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setProductdata(res.data.data);
          setProductname(res.data.data.product.product_name[0].name);
          setProductdes(res.data.data.product.product_name[0].description);
          // setUserName(res.data.data.product.user.username);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-b/?id=${orderId}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setProductdata(res.data.data);
            setProductname(res.data.data.product.product_name[0].name);
            setProductdes(res.data.data.product.product_name[0].description);
            // setUserName(res.data.data.product.user.username);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
    if (productdata) {
      if (
        productdata.product.image.length === 0 ||
        productdata.product.image === null
      ) {
        //   setProductimg(
        //       "https://developersushant.files.wordpress.com/2015/02/no-image-available.png"
        //   );
        setLoading(false);
      } else {
        //   setProductimg(productdata.product.image[0]);
        setLoading(false);
      }
      if (productdata.price_currency === "DONG") {
        setSymbol("₫");
      }
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/billing/?id=${billingId}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBilling(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // if (productdata !== undefined) {
    //   if (
    //     productdata.status === "UNPAID" &&
    //     productdata.earnest_allowed === true
    //   ) {
    //     setEarnestPaid(true);
    //     setFinalPaid(false);
    //   } else if (productdata.status === "EARNEST-PAID") {
    //     setEarnestPaid(true);
    //     setFinalPaid(true);
    //   } else if (productdata.status === "TOTAL-PAID") {
    //     setEarnestPaid(true);
    //     setFinalPaid(true);
    //   }
    // }
    // eslint-disable-next-line
  }, [loading]);
  useEffect(() => {
    if (productdata.progress === "COMPLETED") {
      history.push("/order");
    }
    // eslint-disable-next-line
  }, [token]);
  const isChecked = () => {
    setChecked(!checked);
    setShippingAddress(!shippingAddress);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setDetails({ ...details, [name]: value });
  };
  const handleShipping = (event, id) => {
    event.preventDefault();
    setFormErrors(details);
    const payload = {
      address: details.address,
      country: details.country,
      city: details.city,
      state: details.state,
      postal_code: details.postal_code,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}payment/shipping/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          document.getElementById("form").reset();
          toast.success("Succesfully added");
          setShippingId(res.data.data.id);
          setDetails({
            address: "",
            country: "",
            city: "",
            state: "",
            postal_code: "",
          });
          setChecked(false);
          setShippingAddress(false);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };

  const onCancel = () => {
    // setRatingPop(false);
    setPayModal(false);
  };

  const onCreate = (data, actions) => {
    let payload;
    if (Order_id) {
      payload = {
        order: Number(Order_id),
        is_earnest_paid: true,
        is_final_paid: false,
        billingAddress: Number(billingId),
      };
      if (
        productdata.earnest_allowed == true &&
        productdata.is_earnest_paid == false
      ) {
        payload.is_final_paid = true;
        return actions.order.create({
          purchase_units: [
            {
              custom_id: productdata.user.username,
              description: "earnest_paid",
              soft_descriptor: "earnest_paid",
              reference_id: productdata.id,
              amount: {
                currency_code: "USD",
                value: productdata.earnest_amount,
              },
            },
          ],
          application_context: {
            brand_name: "Chodirect", //# PROJECT NAME
            user_action: "PAY_NOW", //# CONTINUE/PAY_NOW
            return_url: "https://cho.direct/api/v1/payment/payment-success/",
            cancel_url: "https://cho.direct/api/v1/payment/payment-failed/",
          },
        });
      } else {
        payload.is_final_paid = false;
        return actions.order.create({
          purchase_units: [
            {
              custom_id: productdata.user.username, // user id,
              soft_descriptor: "final_paid",
              description: "final_paid",
              reference_id: productdata.id,
              amount: {
                currency_code: "USD",
                value: productdata.final_amount,
              },
            },
          ],
          application_context: {
            brand_name: "Chodirect", //# PROJECT NAME
            user_action: "PAY_NOW", //# CONTINUE/PAY_NOW
            return_url: "https://cho.direct/api/v1/payment/payment-success/",
            cancel_url: "https://cho.direct/api/v1/payment/payment-failed/",
          },
        });
      }
    }

    return actions.order.create({
      purchase_units: [
        {
          soft_descriptor: "final_paid",
          custom_id: productdata.user.username,
          description: "final_paid",
          reference_id: productdata.id,
          amount: {
            currency_code: "USD",
            value: productdata.final_amount,
          },
        },
      ],
      application_context: {
        brand_name: "Chodirect", //# PROJECT NAME
        user_action: "PAY_NOW", //# CONTINUE/PAY_NOW
        return_url: "https://cho.direct/api/v1/payment/payment-success/",
        cancel_url: "https://cho.direct/api/v1/payment/payment-failed/",
      },
    });
  };
  const onApprove = async (data, actions) => {
    let payload;

    await actions.order.capture().then(function (details) {
      // Show a success message to your buyer

      payload = {
        paypal_order_id: details.id,
        billingAddress: Number(billingId),
        same_billing_shipping_address: checked,
      };
      if (payload.same_billing_shipping_address === false) {
        payload.shippingAddress = Number(shippingId);
      }
      // return fetch(
      //     `${process.env.REACT_APP_BASE_URL}payment/order-b/?lang=${lang}`,
      //     {
      //         method: "post",
      //         headers: {
      //             Authorization: `Bearer ${token}`,
      //             Accept: "application/json, text/plain, */*",
      //             "Content-Type": "application/json",
      //         },
      //         body: JSON.stringify(payload),
      //     }
      // )

      //     .then((res, result) => {
      //         console.log(res, result, "resultttttttt")
      //         if (result.status === 200 || result.status === 201 || result.status === true) {
      //             window.location.href = "https://cho.direct/thankyou";
      //         }
      //     })
      //     .catch((error) => {
      //         return fetch(
      //             `${process.env.REACT_APP_BASE_URL}payment/payment-failed/?token=${details.id}`,
      //             {
      //                 method: "get",
      //                 headers: {
      //                     Authorization: `Bearer ${token}`,
      //                 },
      //             }
      //         )
      //             .then((response) => response.text())
      //             .then((result) => (window.location.href = result))
      //             .catch((error) => console.log("error", error));
      //     });

      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}payment/order-b/?lang=${lang}`,
          payload,
          {
            headers: {
              Authorization: ` Bearer ${token}`,
            },
          }
        )

        .then((res, result) => {
          if (res.status === 200 || res.status === 201) {
            window.location.href = "https://cho.direct/thankyou-paypal";
          }
        })
        .catch((error) => {
          return fetch(
            `${process.env.REACT_APP_BASE_URL}payment/payment-failed/?token=${details.id}`,
            {
              method: "get",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((result) => (window.location.href = result))
            .catch((error) => console.log("error", error));
        });
    });
  };

  console.log(shippingAddress, "shippingAddress");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="order-detail-grid">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="address_detail">
                    <h3>{t("address.billingAddress")}</h3>
                    <h5>
                      <strong> {t("address.addressForm.address")}</strong>{" "}
                      <span className="ml-4"> {billing.address}</span>
                    </h5>
                    <h5>
                      <strong> {t("address.addressForm.city")}: </strong>{" "}
                      <span className="ml-4"> {billing.city}</span>
                    </h5>
                    <h5>
                      <strong> {t("address.addressForm.state")}:</strong>{" "}
                      <span className="ml-4">{billing.state}</span>
                    </h5>
                    <h5>
                      <strong> {t("address.addressForm.country")}:</strong>{" "}
                      <span className="ml-4"> {billing.country}</span>
                    </h5>
                    <h5>
                      <strong> {t("address.addressForm.postal_code")}: </strong>
                      <span className="ml-4"> {billing.postal_code}</span>
                    </h5>
                  </div>
                  <form className="dash_edit_card" id="form">
                    <label className="mt-3 mb-4 checkbox_label">
                      <Checkbox
                        checked={checked === false ? true : false}
                        color="primary"
                        onChange={() => isChecked()}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                      />

                      {t("address.sameasbilling")}
                    </label>
                    {checked === false && shippingAddress === true ? (
                      <>
                        <h3> {t("address.shippingAddress")}</h3>

                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.address")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="address"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="address"
                                type="text"
                              />
                              {formErrors.address && (
                                <span className="error">
                                  {formErrors.address}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.city")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder="Enter your city"
                                name="city"
                                type="text"
                              />
                              {formErrors.city && (
                                <span className="error">{formErrors.city}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.state")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="state"
                                className="form-control form-control-alternative"
                                placeholder="Enter your State"
                                name="state"
                                type="text"
                              />
                              {formErrors.state && (
                                <span className="error">
                                  {formErrors.state}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("address.addressForm.country")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="country"
                                className="form-control form-control-alternative"
                                placeholder="Enter your Country"
                                name="country"
                                type="text"
                              />
                              {formErrors.country && (
                                <span className="error">
                                  {formErrors.country}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="postal_code"
                              >
                                {t("address.addressForm.postal_code")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="postal_code"
                                className="form-control form-control-alternative"
                                placeholder="Enter your postal code"
                                name="postal_code"
                                type="text"
                              />
                              {formErrors.postal_code && (
                                <span className="error">
                                  {formErrors.postal_code}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row float-right mt-4">
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn cstm_btn ml-3"
                              onClick={(e) => {
                                handleShipping(e);
                              }}
                            >
                              {t("address.addressForm.save_continue")}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </form>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="order_summary_sidebar">
                    <h3>
                      {" "}
                      {t(
                        "checkout_Screen.orderDetail.order_Summary.order_summary"
                      )}
                    </h3>
                    <h4>
                      {" "}
                      {t(
                        "checkout_Screen.orderDetail.order_Summary.order_id"
                      )}: <strong>{productdata.id}</strong>
                    </h4>

                    <p>
                      {" "}
                      {t(
                        "checkout_Screen.orderDetail.order_Summary.productName"
                      )}
                      : <span>{productname}</span>
                    </p>
                    <p>
                      {" "}
                      {t(
                        "checkout_Screen.orderDetail.order_Summary.product_description"
                      )}
                      : <span>{productdes}</span>
                    </p>
                    {productdata.quantity !== 0 ? (
                      <p>
                        {" "}
                        {t(
                          "checkout_Screen.orderDetail.order_Summary.quantity"
                        )}
                        <span>
                          {productdata.quantity}
                          {productdata.unit}{" "}
                        </span>
                      </p>
                    ) : null}

                    <h5>
                      {t(
                        "checkout_Screen.orderDetail.order_Summary.total_amount"
                      )}
                      :{" "}
                      {productdata.is_earnest_paid === false ? (
                        <span className="ml-4">
                          {symbol} {productdata.earnest_amount}
                        </span>
                      ) : (
                        <span className="ml-4">
                          {symbol} {productdata.final_amount}
                        </span>
                      )}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 float-right text-right">
                  <button
                    type="submit"
                    className="btn cstm_btn ml-3"
                    onClick={(e) => {
                      //   handleSubmit(e);
                      setPayModal(true);
                      //   history.push("./paypal-checkout")
                    }}
                    disabled={shippingAddress ? true : false}
                  >
                    {t("checkout_Screen.orderDetail.order_Summary.final_pay")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
          {/* <PaypalCredit /> */}
          {payModal ? (
            <div>
              <Modal
                className="paypal_Credit"
                displayModal={payModal}
                closeModal={() => onCancel()}
                modalBody={
                  <>
                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "AZy7e6CHgK_godUR90VlxZbtdlZ3NbFt1TcYdAgnhosdtU1aKy1LmfI7de7V_i1B2MOKN3TG8b0yeG7m",
                        currency: "USD",

                        intent: "capture",
                      }}
                    >
                      <PayPalButtons
                        createOrder={onCreate}
                        onApprove={onApprove}
                      />
                    </PayPalScriptProvider>
                  </>
                }
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
export default FinalView;
