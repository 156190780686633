import React from 'react'
import {
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import { useHistory } from "react-router";
const Page401 = () => {
    const history = useHistory();
    return (
        <div className="c-app bg-white flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center text-center">
                    <CCol md="6">
                        <div className="clearfix">
                            <h1 className="float-left display-2 mr-4 w-100">401</h1>
                        </div>
                        <div className="error_page">
                            <h2 className="pt-3 display-3">NO AUTHORIZATION FOUND</h2>
                            <p className="text-muted">This page is not publically available.</p>
                            <p className="text-muted">To access it please login first.</p>
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={() => history.push({ pathname: "/index" })}>Return Home</button>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

export default Page401
