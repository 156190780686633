import React, { useEffect, useState } from "react";
import axios from "axios";
import PayPalThankyou from "./paypalThankyou";
export const ReactPayPal = () => {
    let token = localStorage.getItem("accessToken");
    let paypalAccount = localStorage.getItem("paypalAccount")
    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(false)
    const [data, setData] = useState("")
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}auth/paypal-account/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data.status === false) {
              setData({ data: err.response.data.data });
              setLoading(false);
            }
          }
        });
      if (paypalAccount === "false") {
        setShowButton(true);
      }
    }, [loading, token, paypalAccount]);
    // To show PayPal buttons once the component loads
    React.useEffect(() => {
      window.paypal.use(["login"], function (login) {
        login.render({
          appid:
            "AfrkePAEsvnN7gGsNzJZkleZWcztcyNPRIO_mYA28IrlHpHclRf_0eOdkVdHh7RGWrbGISw6Xqvn_SN4",
          authend: "sandbox",
          scopes: "email",
          containerid: "lippButton",
          responseType: "code",
          locale: "en-us",
          buttonType: "CWP",
          buttonShape: "pill",
          buttonSize: "lg",
          fullPage: "true",
          // returnurl: "http://localhost:3000/thankyou-paypal",
          returnurl: "https://cho.direct/thankyou-paypal",
        });
      });
    }, []);
    return (
      <div className="m-auto">
        {showButton ? (
          <>
            <span id="lippButton"></span>
            <h4 className="mt-4">Click on button to add account</h4>
          </>
        ) : (
          <PayPalThankyou data={data} />
        )}
      </div>
    );
}
export default ReactPayPal;