import React, { useEffect } from "react";
import { Steps } from "antd";
import AddressDetail from "./billingaddressDetail";
import AccountDetail from "./accountDetail";
import CheckoutLogin from "../../login/checkoutLogin";
import { useTranslation } from "react-i18next";

const Checkout = () => {
    const { t } = useTranslation('common');
    const { Step } = Steps;
    let token = localStorage.getItem("accessToken");
    const [current, setCurrent] = React.useState(0);
    useEffect(() => {
        if (!token) {
        setCurrent(0);
    } else {
        setCurrent(1);
    }
  }, [token]);
    const next = () => {
        setCurrent(current + 1);
    };

    // const prev = () => {
    //     setCurrent(current - 1);
    // };

    const steps = [
        {
            title: `${t("checkout_Screen.steps.step1")}`,
            content: <CheckoutLogin onClick={() => next()} />,
      },
      {
          title: `${t("checkout_Screen.steps.step2")}`,
          content: <AddressDetail />,
      },
        {
            title: `${t("checkout_Screen.steps.step3")}`,
            content: <AccountDetail />,
        },
  ];

    return (
        <>
            <div className="container">
                <div className="row step-process">
                    <div className="col-lg-10 col-md-12 col-sm-12">
                      <Steps
                          current={current}
                          className="site-navigation-steps"
                          type="navigation"
                          size="small"
                      >
                          {steps.map((item) => (
                              <Step key={item.title} title={item.title} />
                          ))}
                      </Steps>

                      <div className="steps-content">{steps[current].content}</div>
                  </div>
              </div>
          </div>
      </>
  );
};

export default Checkout;
