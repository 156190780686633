import React, { useState, useEffect } from "react";
import "./myOffer.css";
import axios from "axios";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CButton } from "@coreui/react";
import Loader from "../../../Components/loader/index";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import LineWeightIcon from "@material-ui/icons/LineWeight";
import AcUnitIcon from "@material-ui/icons/AcUnit";

const OfferView = (props) => {
    const history = useHistory();
    const paramId = props.match.params.id;
    const [body, setBody] = useState();
    const [productName, setProductName] = useState();
    const [loading, setLoading] = useState(true);
    let token = localStorage.getItem("accessToken");
    const lang = localStorage.getItem("language");

    const getData = () => {
        axios
        .get(
            `${process.env.REACT_APP_BASE_URL}payment/offer/?id=${paramId}&lang=${lang}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
          }
      )
        .then((res) => {
            setBody(res.data.data);
            setProductName(res.data.data.product.product_name[0].name);
            setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.detail);
      });
    };
    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, []);
    const acceptOffer = (value) => {
        const payload = {
            id: paramId,
            status: value,
        };
        axios
        .post(
            `${process.env.REACT_APP_BASE_URL}payment/offer/?lang=${lang}`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
          }
      )
        .then((res) => {
            toast.success("Offer is Accepted");
          history.push({ pathname: "/dashboard" });
      })
          .catch((err) => {
              if (err.response !== undefined) {
                  for (let i of err.response.data.message) {
                      toast.error(`${i.error}`);
                  }
              }
          });
  };

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="container ">
            <div className="oferView">
              <img
                width=""
                height=""
                src={body.product.image[0]}
                alt="oferView"
              />
              <div className="offerDetail">
                <h1 className="title">{productName}</h1>
                <div className="offer-item">
                  <MoneyOffIcon className="desc__fav" />
                  <p>
                    <strong className="mr-3">Earnest Amount :</strong>
                    <span>{body.earnest_amount}</span>
                  </p>
                </div>
                <div className="offer-item">
                  <MonetizationOnIcon className="desc__fav" />
                  <p>
                    <strong className="mr-3">Final Amount:</strong>
                    <span>{body.final_amount}</span>
                  </p>
                </div>
                <div className="offer-item">
                  <AttachMoneyIcon className="desc__fav" />
                  <p>
                    <strong className="mr-3">Currency :</strong>
                    <span>{body.currency}</span>
                  </p>
                </div>
                <div className="offer-item">
                  <AcUnitIcon className="desc__fav" />
                  <p>
                    <strong className="mr-3">Unit :</strong>
                    <span>{body.unit}</span>
                  </p>
                </div>
                <div className="offer-item">
                  <LineWeightIcon className="desc__fav" />
                  <p>
                    <strong className="mr-3">Quantity :</strong>
                    <span>{body.quantity}</span>
                  </p>
                </div>
                <div className="offer-item">
                  <LineWeightIcon className="desc__fav" />
                  <p>
                    <strong className="mr-3">Status :</strong>
                    <span>{body.status}</span>
                  </p>
                </div>
                {body.status === "ACCEPT" || body.status === "REJECT" ? null : (
                  <>
                    <CButton
                      className="btn accept_now"
                      onClick={() => acceptOffer("ACCEPT")}
                    >
                      Accept
                    </CButton>
                    <CButton
                      className="btn make_button"
                      onClick={() => acceptOffer("REJECT")}
                    >
                      Decline
                    </CButton>
                  </>
                )}
              </div>
            </div>
            <div className="offerDescription">
              <h2 className="heading">Description</h2>
              <p>{body.product.description}</p>
            </div>
          </div>
        )}
        <ToastContainer />
      </>
    );
};
export default OfferView;
