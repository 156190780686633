import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Search from "./searchbox";
import Loader from "../../Components/loader/index";
import ReactStars from "react-rating-stars-component";
import dummyProfile from "../../../assets/images/profile.jpeg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const SearchData = (props) => {
    const { t } = useTranslation('common');
    const queryString = window.location.search;
    const MAX_LENGTH = 150;
    const urlParams = new URLSearchParams(queryString);
    const search_value = urlParams.get("k");
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [body, setBody] = useState([]);
    const [symbol, setSymbol] = useState("$");
    const [searchvalue, setSearchValue] = useState();
    const [type, setType] = useState("ALL");
    const lang = localStorage.getItem("language");

    useEffect(() => {
        setSearchValue(search_value);
        setSymbol("$");
        setType("ALL");
    }, [search_value]);
    useEffect(() => {
        axios
            .get(
          `${process.env.REACT_APP_BASE_URL}inventory/search/?type=ALL&k=${search_value}&lang=${lang}&product_limit=6`
      )
          .then((res) => {
              setBody(res.data.data);
              setLoading(false);
          })
          .catch((err) => toast.error(err));
    }, [loading, search_value, type, lang]);
    const relatedView = (e, id) => {
        e.preventDefault();
        history.push({ pathname: `/products/${id}` });
    };
    const serviceView = (value, id) => {
      history.push({ pathname: `/servicecategory/${value}/${id}` });
    };
    const pagination = (value) => {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }inventory/search/?type=ALL&k=${search_value}&lang=${lang}&product_page=${
            value + 1
          }&product_limit=6`,
          {}
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response);
        });
    };
    const paginationprevious = (value) => {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }inventory/search/?type=ALL&k=${search_value}&lang=${lang}&product_page=${
            value - 1
          }&product_limit=6`,
          {}
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response);
        });
    };

    const servicepreviouspagination = (value) => {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }inventory/search/?type=ALL&k=${search_value}&lang=${lang}&service_page=${
            value - 1
          }&service_limit=6`,
          {}
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    const servicenextpagination = (value) => {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_URL
          }inventory/search/?type=ALL&k=${search_value}&lang=${lang}&service_page=${
            value + 1
          }&service_limit=6`,
          {}
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    };

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="search-product service_provider_details_cont">
            <div className="cstm_header product_details_header">
              <Search value={searchvalue} />
            </div>
            {body ? (
              <div className="container">
                <div className="row ">
                  <div className="col-lg-12 text-center">
                    <h2 className="heading">{t("searchPage.search_result")}</h2>
                  </div>
                  {body.PRODUCT?.length > 0 ? (
                    <>
                      <div className="col-md-12 aos-init" data-aos="fade-up">
                        <h3>
                          <span>Product's</span>
                        </h3>
                      </div>
                      {body.PRODUCT?.map((item, index) => {
                        return (
                          <div
                            className="col-6 col-md-6 col-lg-3"
                            key={index}
                            onClick={(e) => relatedView(e, item.id)}
                          >
                            <div className="product-card">
                              <div className="img-outer">
                                <img
                                  width=""
                                  height=""
                                  src={
                                    item.image !== null && item.image.length > 0
                                      ? item.image[0]
                                      : "https://developersushant.files.wordpress.com/2015/02/no-image-available.png"
                                  }
                                  className="product_img"
                                  alt="profile"
                                />
                                <div className="time">
                                  {t("searchPage.product")}
                                </div>
                              </div>
                              <div className="name">
                                {item?.product_name.map((item) =>
                                  item.language.code === lang ? (
                                    <span> {item.name}</span>
                                  ) : null
                                )}
                              </div>

                              {item.pricing_type === "FIXED" ? (
                                <>
                                  <div className="price">
                                    {" "}
                                    {symbol} {item.price_to}
                                    <sup>{item.total_quantity_unit}</sup>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="price">
                                    {" "}
                                    {symbol} {item.price_from}
                                    <sup>{item.total_quantity_unit}</sup>-{" "}
                                    {symbol} {item.price_to}
                                    <sup>{item.total_quantity_unit}</sup>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="col-lg-12">
                        <div className="pagination">
                          {body.product_extra.page_number > 1 ? (
                            <button
                              className="paginataion_button"
                              onClick={() =>
                                paginationprevious(
                                  body.product_extra.page_number
                                )
                              }
                            >
                              &#x3c;
                            </button>
                          ) : null}
                          {body.product_extra.page_number <
                          body.product_extra.total_pages ? (
                            <button
                              className="paginataion_button"
                              onClick={() =>
                                pagination(body.product_extra.page_number)
                              }
                            >
                              &#x3e;
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                  {body.SERVICE?.length > 0 ? (
                    <>
                      <div className="col-md-12 aos-init" data-aos="fade-up">
                        <h3>
                          <span>{t("searchPage.service")}</span>
                        </h3>
                      </div>
                      <div className="service_detail">
                        <div className="row">
                          {body.SERVICE?.map((item, index) => {
                            return (
                              <div
                                className="col-md-6"
                                key={index}
                                onClick={() =>
                                  serviceView(item.user.username, item.id)
                                }
                              >
                                <div
                                  className="service_provider_cont_box aos-init"
                                  data-aos="fade-up"
                                >
                                  <div className="info_outer">
                                    <div className="info">
                                      <img
                                        width=""
                                        height=""
                                        src={
                                          item.user.profile_image !== null &&
                                          item.user.profile_image.length > 0
                                            ? item.user.profile_image
                                            : dummyProfile
                                        }
                                        alt="dummy Profile"
                                      />
                                      <div>
                                        <div className="name">
                                          {/* {item.user.first_name === null
                                                                                      ? item.user.username
                                                                                      : item.user.first_name}{" "}
                                                                                  {item.user.last_name} */}
                                          {item?.product_name.map((item) =>
                                            item.language.code === lang ? (
                                              <span> {item.name}</span>
                                            ) : null
                                          )}
                                        </div>

                                        <div className="rating d-flex">
                                          <ReactStars
                                            size={20}
                                            count={5}
                                            value={
                                              item.user.rating.length === 0
                                                ? 0
                                                : item.user.rating.rate__avg
                                            }
                                            edit={false}
                                            a11y={true}
                                            isHalf={true}
                                            emptyIcon={
                                              <i className="fas fa-star"></i>
                                            }
                                            halfIcon={
                                              <i className="fas fa-star-half-alt"></i>
                                            }
                                            fullIcon={
                                              <i className="fas fa-star"></i>
                                            }
                                            activeColor="#ffd700"
                                          />
                                          <span className="m-auto">
                                            ({item.user.rating.length} Reviews)
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="provider_details w-100">
                                    {item.user.profile_summary.length === 0 ? (
                                      <p>No Summary avaibale</p>
                                    ) : (
                                      <>
                                        {""}
                                        {item.user.profile_summary ? (
                                          <p>
                                            {`${item.user.profile_summary.substring(
                                              0,
                                              MAX_LENGTH
                                            )}...`}
                                          </p>
                                        ) : (
                                          <a
                                            href="service_provider_details.html"
                                            className=""
                                          >
                                            Read More{" "}
                                            <i className="fas fa-chevron-right"></i>
                                          </a>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="pagination">
                          {body.service_extra.page_number > 1 ? (
                            <button
                              className="paginataion_button"
                              onClick={() =>
                                servicepreviouspagination(
                                  body.product_extra.page_number
                                )
                              }
                            >
                              &#x3c;
                            </button>
                          ) : null}
                          {body.service_extra.page_number >
                          body.product_extra.total_pages ? (
                            <button
                              className="paginataion_button"
                              onClick={() =>
                                servicenextpagination(
                                  body.product_extra.page_number
                                )
                              }
                            >
                              &#x3e;
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : (
              <h3 className="text-center">{t("searchPage.no_result")}</h3>
            )}
          </div>
        )}
      </>
    );
};

export default SearchData;
