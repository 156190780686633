import React, { useEffect, useState } from "react";
import axios from "axios";
import contries from "../../login/coutry.json";
import Loader from "../../../Components/loader/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CButton, CInput, CLabel, CSelect } from "@coreui/react";
import dummyProfile from "../../../../assets/images/profile.jpeg";
import countries from "./country.json";
import "react-datetime/css/react-datetime.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import TheHeader from "src/views/Components/Header/Header";
import CustomDatePicker from "src/views/Components/Custom-Datepicker/custom-datepicker";
import Moment from "moment";
const Profile = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const gender = [
    { value: "MALE", label: `${t("profile.profileForm.male")}` },
    { value: "FEMALE", label: `${t("profile.profileForm.female")}` },
  ];
  const initialValue = {
    address: "",
    alt_phone: "",
    city: "",
    country: "",
    date_of_birth: "",
    district: "",
    first_name: "",
    gender: "",
    last_name: "",
    license: "",
    phone: "",
    phone_code: "",
    postal_code: "",
    profile_image: "",
    profile_summary: "",
    state: "",
    license_proof: "",
    language: "",
  };
  // const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState();
  const [data, setData] = useState(initialValue);
  const [image, setImage] = useState();
  const token = localStorage.getItem("accessToken");
  const [proofImage, setProofImage] = useState();
  const [language, setLanguage] = useState([]);
  const lang = localStorage.getItem("language");
  const [datepick, setDatePick] = useState();
  const permission = localStorage.getItem("permissions");
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const handleGetProfile = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBody(res.data.data);
        setImage(res.data.data.profile_image);
        setProofImage(res.data.data.license_proof);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };
  const validate = (values) => {
    let errors = {};
    return errors;
  };

  useEffect(() => {
    handleGetProfile();
    // eslint-disable-next-line
  }, []);

  const handleImage = (e) => {
    let k = e.target.files[0];
    var formdata = new FormData();
    formdata.append("file", k, k.name);
    console.log(formdata, "fromdata");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}upload-multiple/?lang=${lang}`,
        formdata,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const payloadImage = {
            profile_image: res.data.data[0],
          };
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`,
              payloadImage,
              {
                headers: {
                  Authorization: ` Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                toast.success("Profile Image Uploaded");

                handleGetProfile();
                setData({
                  profile_image: "",
                });
              }
            })
            .catch((err) => {
              if (err.response !== undefined) {
                for (let i of err.response.data.message) {
                  toast.error(`${i.error}`);
                }
              }
            });
        }
      })
      .catch((err) => toast.error(err.response));
  };
  const handleImageProof = (e) => {
    let k = e.target.files[0];
    var formdata = new FormData();
    formdata.append("file", k, k.name);
    console.log(formdata, "formData");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}upload-multiple/`, formdata, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {
        setProofImage(res.data.data[0]);
      })
      .catch((err) => toast.error(err.response));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      address: "" || data.address || body.address,
      alt_phone: "" || data.alt_phone || body.alt_phone,
      city: "" || data.city || body.city,
      country: "" || data.country || body.country,
      date_of_birth: null || datepick || body.date_of_birth,
      district: "" || data.district || body.district,
      first_name: "" || data.first_name || body.first_name,
      gender: data.gender || "MALE" || body.gender,
      last_name: "" || data.last_name || body.last_name,
      license: "" || data.license || body.license,
      phone: "" || data.phone || body.phone,
      phone_code: "" || data.phone_code || body.phone_code,
      postal_code: "" || data.postal_code || body.postal_code,
      profile_image: "" || image,
      profile_summary: "" || data.profile_summary || body.profile_summary,
      state: "" || data.state || body.state,
      license_proof: "" || proofImage,
      language: "1" || data.language,
    };

    let token = localStorage.getItem("accessToken");
    if (Object.keys(validate(data)).length === 0) {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`,
          payload,
          {
            headers: {
              Authorization: ` Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(`${t("errorMessages.success_added")}`);
            if (permission === "BUYER" || permission === "FARMER") {
              history.push("./myproducts");
            } else {
              history.push("./myservice");
            }
            handleGetProfile();
            setData({
              address: "",
              alt_phone: "",
              city: "",
              country: "",
              date_of_birth: "",
              district: "",
              first_name: "",
              gender: "",
              last_name: "",
              license: "",
              phone: "",
              phone_code: "",
              postal_code: "",
              profile_image: "",
              profile_summary: "",
              state: "",
            });
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLanguage(res.data.data);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [loading, token]);

  const changePhoneNumber = () => {
    history.push("/changePhoneNumber");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="profile-page scroll_div">
            <div className="row">
              <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                <div className="card card-profile shadow">
                  <div className="row justify-content-center">
                    <div className="card-profile-image">
                      <label
                        htmlFor="photo-upload"
                        className="custom-file-upload fas"
                      >
                        <div className="img-wrap img-upload">
                          <img
                            width=""
                            height=""
                            htmlFor="photo-upload"
                            src={
                              image !== null && image.length > 0
                                ? image
                                : dummyProfile
                            }
                            alt="profile "
                          />
                        </div>
                        <input
                          id="photo-upload"
                          type="file"
                          onChange={handleImage}
                        />
                      </label>
                    </div>
                  </div>

                  <div className="card-body   pt-md-4">
                    <div className="text-center card-profile">
                      {body.first_name !== null ? (
                        <h3>
                          {body.first_name} {body.last_name}
                        </h3>
                      ) : null}

                      {body.country !== null && body.country.length < 0 ? (
                        <div className="h5 font-weight-300">
                          <i className="ni location_pin mr-2"></i>
                          {body.country}
                        </div>
                      ) : null}
                      <hr className="my-4" />
                      <div className="row">
                        <div className="col-12">
                          {body.date_of_birth !== null ? (
                            <p>
                              {t("profile.profileForm.dob")}:
                              <span>{body.date_of_birth}</span>
                            </p>
                          ) : null}
                          {body.gender !== null ? (
                            <p>
                              {t("profile.profileForm.gender")}:
                              <span>{body.gender}</span>
                            </p>
                          ) : null}
                          {body.username !== null ? (
                            <p>
                              {t("profile.profileForm.username")}:
                              <span>{body.username}</span>
                            </p>
                          ) : null}
                          {body.address.length > 0 ? (
                            <p>
                              {t("profile.profileForm.address")}:
                              <span>{body.address}</span>
                            </p>
                          ) : null}
                          {body.city !== null && body.city.length < 0 ? (
                            <p>
                              {t("profile.profileForm.city")}:
                              <span>{body.city}</span>
                            </p>
                          ) : null}
                          {body.country !== null && body.country.length < 0 ? (
                            <p>
                              {t("profile.profileForm.country")}:
                              <span>{body.country}</span>
                            </p>
                          ) : null}
                          {body.postal_code !== null ? (
                            <p>
                              {t("profile.profileForm.postal_code")}:
                              <span>{body.postal_code}</span>
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {body.profile_summary !== null &&
                      body.profile_summary.length < 0 ? (
                        <>
                          <hr className="my-4" />
                          <p>
                            {t("profile.profileForm.description")}:
                            <br />
                            <span className="ml-0 mt-2">
                              {body.profile_summary}
                            </span>
                          </p>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 order-xl-1">
                <div className="">
                  <div className="headingWrap">
                    <div className="">
                      <div className="">
                        <h3 className="title">{t("profile.title")}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <form id="form" className="dash_edit_card">
                      <h6 className="heading-small">
                        {t("profile.profileForm.title")}:
                      </h6>
                      <div className="">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                {t("profile.profileForm.first_name")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                type="text"
                                id="first_name"
                                name="first_name"
                                className="form-control form-control-alternative"
                                placeholder="First name"
                                defaultValue={body.first_name}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="last-name"
                              >
                                {t("profile.profileForm.last_name")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                type="text"
                                id="last_name"
                                name="last_name"
                                className="form-control form-control-alternative"
                                placeholder="Last name"
                                defaultValue={body.last_name}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="gender"
                              >
                                {t("profile.profileForm.gender")}
                              </CLabel>
                              <CSelect
                                custom
                                name="gender"
                                id="gender"
                                onChange={handleChange}
                                className="form-control"
                                defaultValue={body.gender}
                              >
                                <option disabled selected>
                                  Select Gender
                                </option>
                                {gender.map((option) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </CSelect>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="last-name"
                              >
                                {t("profile.profileForm.dob")}
                              </CLabel>

                              {/* <CInput
                                                                  type="date"
                                                                  onChange={handleChange}
                                                                  name="dob"
                                                                  id="dob"
                                                                  className="form-control"
                                                                  max={disableFutureDt()}
                                                                  defaultValue={body.date_of_birth}
                                                              /> */}
                              <CustomDatePicker
                                wrapperClassName="datepicker"
                                className="form-control"
                                dateFormat="yy/mm/dd"
                                selected={Moment(
                                  body.date_of_birth || datepick
                                ).toDate()}
                                // selected={Date.parse(Moment(datepick || body.date_of_birth).toISOString())}
                                name="dob"
                                id="dob"
                                onChange={(date) =>
                                  setDatePick(formatDate(date))
                                }
                                maxDate={new Date()}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group focused ">
                              <CLabel
                                className="form-control-label"
                                htmlFor="phone_number"
                              >
                                {t("profile.profileForm.phone_number")}
                                <spam
                                  className="ml-4 change_phone_number"
                                  onClick={() => changePhoneNumber()}
                                >
                                  Change Phone Number
                                </spam>
                              </CLabel>
                              <div className="country_select">
                                <CSelect
                                  onChange={handleChange}
                                  className="select"
                                  name="country_code"
                                  id="country_code"
                                  defaultValue={body.phone_code}
                                  disabled={
                                    body.phone_code !== null &&
                                    body.phone_code.length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {" "}
                                  {contries.map((e, index) => (
                                    <option value={e.dial_code} key={index}>
                                      {e.dial_code}
                                    </option>
                                  ))}
                                </CSelect>
                                <CInput
                                  onChange={handleChange}
                                  name="phone"
                                  id="phone"
                                  className="input"
                                  placeholder="4124262626"
                                  defaultValue={body.phone}
                                  disabled={
                                    body.phone !== null && body.phone.length > 0
                                      ? true
                                      : false
                                  }
                                />{" "}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <CLabel
                                className="form-control-label"
                                htmlFor="alt_phone_number"
                              >
                                {t(
                                  "profile.profileForm.alternative_phone_number"
                                )}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                type="number"
                                name="alt_phone_name"
                                id="alt_phone_number"
                                className="form-control form-control-alternative"
                                placeholder="987654345"
                                defaultValue={body.alt_phone}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <CLabel
                                className="form-control-label"
                                htmlFor="license"
                              >
                                {t("profile.profileForm.government_id")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                type="text"
                                name="license"
                                id="license"
                                className="form-control form-control-alternative"
                                placeholder="Enter your ID Proof Number"
                                defaultValue={body.license}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <CLabel
                                className="form-control-label"
                                htmlFor="license"
                              >
                                {t("profile.profileForm.id_proof")}
                              </CLabel>
                              <input
                                id="photo-file-upload"
                                type="file"
                                className="form-control"
                                onChange={handleImageProof}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="gender"
                              >
                                {t("profile.profileForm.preferred_language")}
                              </CLabel>
                              <CSelect
                                custom
                                name="language"
                                id="language"
                                onChange={handleChange}
                                className="form-control"
                                defaultValue={body.gender}
                              >
                                <option disabled selected defaultValue="1">
                                  {t("profile.profileForm.select")}
                                </option>
                                {language.map((option) => (
                                  <option
                                    value={option.id}
                                    className="text-capitalize"
                                    //   selected={defaultVal.map(
                                    //     (item) => item.code.id === option.id
                                    //   )}
                                  >
                                    {option.language}
                                  </option>
                                ))}
                              </CSelect>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h6 className="heading-small">
                        {t("profile.profileForm.contact_information")}
                      </h6>
                      <div className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                {t("profile.profileForm.address")}
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="address"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="address"
                                type="text"
                                defaultValue={body.address}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group focused">
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                {t("profile.profileForm.city")}
                              </label>
                              <input
                                onChange={handleChange}
                                name="city"
                                type="text"
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder="City"
                                defaultValue={body.city}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group focused">
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                {t("profile.profileForm.state")}
                              </label>
                              <input
                                onChange={handleChange}
                                name="state"
                                type="text"
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder="State"
                                defaultValue={body.state}
                              />
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-group focused">
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                {t("profile.profileForm.country")}
                              </label>
                              <CSelect
                                className="select"
                                name="country"
                                id="country"
                                onChange={handleChange}
                                defaultValue={body.country}
                              >
                                {" "}
                                {countries.map((e) => (
                                  <option value={e.code}>{e.name}</option>
                                ))}
                              </CSelect>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                {t("profile.profileForm.postal_code")}
                              </label>
                              <input
                                onChange={handleChange}
                                name="postal_code"
                                type="number"
                                id="postal_code"
                                className="form-control form-control-alternative"
                                placeholder="Postal code"
                                minlength={6}
                                maxlength={6}
                                defaultValue={body.postal_code}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <h6 className="heading-small">
                        {t("profile.profileForm.about_me")}
                      </h6>
                      <div className="">
                        <div className="form-group focused">
                          <textarea
                            onChange={handleChange}
                            name="profile_summary"
                            id="profile_summary"
                            rows="4"
                            className="form-control form-control-alternative"
                            placeholder="A few words about you ..."
                            defaultValue={body.profile_summary}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <CButton
                            className="btn cstm_btn float-right"
                            onClick={handleSubmit}
                          >
                            {t("profile.profileForm.buttonText")}
                          </CButton>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none">
            <TheHeader setImage={image} />
          </div>
          <ToastContainer />
        </>
      )}{" "}
    </>
  );
};
export default Profile;
