import React, { useState, useEffect } from "react";
import { CButton, CSelect, CInput, CLink, CLabel } from "@coreui/react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Logo from "../../../assets/images/newLogo.png";
import login from "../../../assets/images/login.png";
import { Tab, Tabs } from "react-bootstrap-tabs";
import Verify from "./verify";
import { useTranslation } from "react-i18next";

const Forgot = () => {
  const { t } = useTranslation('common');
  const [data, setData] = useState({
    phone: null,
    email: null,
  });
  const [counter, setCounter] = useState(true);
  const [code, setCode] = useState(null);
  const [languages, setLanguage] = useState([]);
  const [loading, setLoading] = useState(false);
  const forgot = () => { };
  const lang = localStorage.getItem("language");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`)
      .then((res) => {
        setLanguage(res.data.data);
        setLoading(false);
      })
      .catch((err) => toast.error(err));
  }, [loading, lang]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload;
    if (data.email) {
      payload = {};
      payload.email_or_phone = data.email;
      payload.lang = data.lang;
      payload.verification_type = "email";
    }
    if (data.phone) {
      payload = {};
      payload.email_or_phone = data.phone;
      payload.lang = "en";
      payload.verification_type = "sms";
    }

    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}auth/forgetpassword/?lang=${lang}`,
        payload
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success("Verification Code Sent");
          setCounter(false);
          setCode(res.data.phone_code);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        } else {
          toast.error("an error occured");
        }
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  return (
    <div>
      {counter === true && (
        <div className="login_signup" style={{ backgroundColor: "white" }}>
          <div className="left" data-aos="zoom-in">
            <img width="100" height="auto" src={login} alt="timeline" />
          </div>
          <div className="right" data-aos="fade-left">
            <CLink to="/">
              <img width="100" height="auto" src={Logo} alt="Logo" />
            </CLink>
            <h2 className="heading">{t("Forgot_Password.title")}</h2>
            <Tabs className="logintabs">
              <Tab label={t("Forgot_Password.tab1")}>
                <form action="" onSubmit={handleSubmit} id="form">
                  <div className="form-group">
                    <CLabel htmlFor="phone">
                      {t("Forgot_Password.label1")}
                    </CLabel>
                    <CInput
                      name="phone"
                      id="phone"
                      value={data.phone}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter your phone no"
                    />
                  </div>

                  <div className="form-group">
                    <CButton
                      type="submit"
                      className="btn cstm_btn"
                      onClick={forgot}
                    >
                      {t("Forgot_Password.buttonText")}
                    </CButton>
                  </div>
                  <ToastContainer />
                </form>
              </Tab>
              <Tab label={t("Forgot_Password.tab2")}>
                <form action="" onSubmit={handleSubmit} id="form">
                  <div className="form-group">
                    <CLabel htmlFor="phone">
                      {t("Forgot_Password.label2")}
                    </CLabel>
                    <CInput
                      name="email"
                      id="email"
                      value={data.email}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter your Email"
                    />
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="name">
                      {t("Forgot_Password.prefferedLang")}
                    </CLabel>
                    <CSelect
                      onChange={handleChange}
                      name="lang"
                      id="lang"
                      className="form-control"
                    >
                      <option disabled selected value="en">
                        Select{" "}
                      </option>
                      {languages.map((e) => (
                        <option value={e.code}>
                          {e.language}({e.code})
                        </option>
                      ))}
                    </CSelect>
                  </div>

                  <div className="form-group">
                    <CButton
                      type="submit"
                      className="btn cstm_btn"
                      onClick={forgot}
                    >
                      {t("Forgot_Password.buttonText")}
                    </CButton>
                  </div>
                  <ToastContainer />
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
      {counter === false && <Verify value={data} code={code} />}
    </div>
  );
};

export default Forgot;
