import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CHO_text from "../../../assets/images/newLogo.png";
import "antd/dist/antd.css";
import "./Sidebar.css";
import { useTranslation } from "react-i18next";

function Sidebar(props) {
  const history = useHistory();
  const { t } = useTranslation("common");
  const [settingShow, setSettingShow] = useState(false);
  let permissions = localStorage.getItem("permissions");

  let url = window.location.pathname;
  const _nav = [
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title1")}`,
      to: "/dashboard",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title2")}`,
      to: "/myproducts",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-package"
          >
            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title3")}`,
      to: "/offer",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-tag"
          >
            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
            <line x1="7" y1="7" x2="7" y2="7"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title4")}`,
      to: "/order",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chrome"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="4"></circle>
            <line x1="21.17" y1="8" x2="12" y2="8"></line>
            <line x1="3.95" y1="6.06" x2="8.54" y2="14"></line>
            <line x1="10.88" y1="21.94" x2="15.46" y2="14"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title5")}`,
      to: "/contracts",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chrome"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="4"></circle>
            <line x1="21.17" y1="8" x2="12" y2="8"></line>
            <line x1="3.95" y1="6.06" x2="8.54" y2="14"></line>
            <line x1="10.88" y1="21.94" x2="15.46" y2="14"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title7")}`,
      to: "/myearning",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-dollar-sign"
          >
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title8")}`,
      to: "/chat",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-message-square"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </div>
      ),
    },
    {
      type: "BUYER",
      _tag: "CSidebarNavDropdown",
      name: `${t("sidebar.title9")}`,
      // to: "/#",
      show: true,
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </div>
      ),
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title1")}`,
          to: "/profile",
        },

        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title2")}`,
          to: "/bankDetail",
        },
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title3")}`,
          to: "/address-detail",
        },
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title4")}`,
          to: "/changePassword",
        },
      ],
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title1")}`,
      to: "/dashboard",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title2")}`,
      to: "/myproducts",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-package"
          >
            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title3")}`,
      to: "/offer",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-tag"
          >
            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
            <line x1="7" y1="7" x2="7" y2="7"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title4")}`,
      to: "/order",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chrome"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="4"></circle>
            <line x1="21.17" y1="8" x2="12" y2="8"></line>
            <line x1="3.95" y1="6.06" x2="8.54" y2="14"></line>
            <line x1="10.88" y1="21.94" x2="15.46" y2="14"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title5")}`,
      to: "/contracts",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chrome"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="4"></circle>
            <line x1="21.17" y1="8" x2="12" y2="8"></line>
            <line x1="3.95" y1="6.06" x2="8.54" y2="14"></line>
            <line x1="10.88" y1="21.94" x2="15.46" y2="14"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title7")}`,
      to: "/myearning",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-dollar-sign"
          >
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title8")}`,
      to: "/chat",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-message-square"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </div>
      ),
    },
    {
      type: "FARMER",
      _tag: "CSidebarNavDropdown",
      name: `${t("sidebar.title9")}`,
      // to: "/#",
      show: true,
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </div>
      ),
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title1")}`,
          to: "/profile",
        },

        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title3")}`,
          to: "/address-detail",
        },
        ,
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title4")}`,
          to: "/changePassword",
        },
      ],
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title1")}`,
      to: "/dashboard",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-home"
          >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title12")}`,
      to: "/myservice",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-package"
          >
            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
            <line x1="12" y1="22.08" x2="12" y2="12"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title3")}`,
      to: "/offer",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-tag"
          >
            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
            <line x1="7" y1="7" x2="7" y2="7"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title10")}`,
      to: "/hireHistory",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-tag"
          >
            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
            <line x1="7" y1="7" x2="7" y2="7"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title7")}`,
      to: "/myearning",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-dollar-sign"
          >
            <line x1="12" y1="1" x2="12" y2="23"></line>
            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title8")}`,
      to: "/chat",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-message-square"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title4")}`,
      to: "/order",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chrome"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="4"></circle>
            <line x1="21.17" y1="8" x2="12" y2="8"></line>
            <line x1="3.95" y1="6.06" x2="8.54" y2="14"></line>
            <line x1="10.88" y1="21.94" x2="15.46" y2="14"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavItem",
      name: `${t("sidebar.title5")}`,
      to: "/contracts",
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chrome"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle cx="12" cy="12" r="4"></circle>
            <line x1="21.17" y1="8" x2="12" y2="8"></line>
            <line x1="3.95" y1="6.06" x2="8.54" y2="14"></line>
            <line x1="10.88" y1="21.94" x2="15.46" y2="14"></line>
          </svg>
        </div>
      ),
    },
    {
      type: "SERVICE PROVIDER",
      _tag: "CSidebarNavDropdown",
      name: `${t("sidebar.title9")}`,
      // to: "/#",
      show: true,
      icon: (
        <div className="mr-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
        </div>
      ),
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title1")}`,
          to: "/profile",
        },
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title2")}`,
          to: "/bankDetail",
        },
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title3")}`,
          to: "/address-detail",
        },
        ,
        {
          _tag: "CSidebarNavItem",
          name: `${t("sidebar.Settings.sub_title4")}`,
          to: "/changePassword",
        },
      ],
    },
  ];
  const sidebarToggle = (url) => {
    history.push(`${url}`);
    if (window.matchMedia("(min-width: 991px)").matches) {
      props.toggleSidebar();
    }
  };
  return (
    <nav className="sidebar_naviagtion">
      <div className="text-center  sidebar_band">
        <Link to="/index">
          {" "}
          <img
            width=""
            height=""
            src={CHO_text}
            alt="logo"
            className="m-auto"
          />
        </Link>
      </div>
      <ul className="c-sidebar-nav h-100 ps">
        {_nav.map((item) => {
          return (
            <>
              {permissions === item.type ? (
                <>
                  {item.name !== "Settings" && item.name !== "Cài đặt" ? (
                    <div
                      onClick={() => sidebarToggle(item.to)}
                      className="c-sidebar-nav-item"
                    >
                      <li
                        className={
                          item.to === url
                            ? "c-sidebar-nav-link  c-active"
                            : "c-sidebar-nav-link"
                        }
                      >
                        {item.icon}
                        {item.name}
                      </li>
                    </div>
                  ) : null}

                  {item.name === "Settings" || item.name === "Cài đặt" ? (
                    <div
                      className="c-sidebar-nav-item d-block"
                      onClick={() => setSettingShow(!settingShow)}
                    >
                      <li
                        className={
                          item._children.to === url
                            ? "c-sidebar-nav-link  c-active"
                            : "c-sidebar-nav-link"
                        }
                      >
                        {item.icon}
                        {item.name}
                        <div className="ml-auto">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-corner-right-down"
                          >
                            <polyline points="10 15 15 20 20 15"></polyline>
                            <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                          </svg>{" "}
                        </div>{" "}
                      </li>

                      {settingShow ? (
                        <ol>
                          {item._children.map((item) => {
                            return (
                              <div
                                onClick={() => sidebarToggle(item.to)}
                                className="c-sidebar-nav-item d-block"
                              >
                                <li
                                  className={
                                    item.to === url
                                      ? "c-sidebar-nav-link  c-active"
                                      : "c-sidebar-nav-link"
                                  }
                                >
                                  {item.icon}
                                  {item.name}
                                </li>
                              </div>
                            );
                          })}
                        </ol>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          );
        })}
      </ul>
    </nav>
  );
}

export default Sidebar;
