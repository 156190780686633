import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CButton,
  CCol,
  CInput,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";
import { Switch } from "antd";
import Loader from "../../../Components/loader/index";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import "react-datetime/css/react-datetime.css";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "src/views/Components/Custom-Datepicker/custom-datepicker";
import Moment from "moment";
const AddProduct = () => {
  const { t } = useTranslation("common");
  const currencyData = [
    { value: "USD", label: `${t("myproduct.ProductForm.usd")} ` },
    { value: "DONG", label: `${t("myproduct.ProductForm.dong")} ` },
  ];
  const [fileList, setFileList] = useState([]);
  const [languages, setLanguage] = useState([]);
  const [disable, setdisabled] = useState(false);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("");
  const [datepick, setDatePick] = useState(new Date());
  const onChangeImage = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const history = useHistory();
  const [cateogory_value, setCateogary_Value] = useState("");
  const [inputList, setInputList] = useState([
    { name: "", description: "", language: "1" },
  ]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const initialValue = {
    estimate_time: "",
    quantity: "",
    area: "",
    area_unit: "",
    quantity_unit: "",
    pricing_type: "",
    price_to: "",
    price_from: "",
    cateogories: "",
    subcategories: "",
    description: "",
    shippment: false,
  };

  const [subcateogory_value, setSubCateogary_Value] = useState("");
  const [pricetype, setPricetype] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialValue);
  const [earnestAllowed, setEarnestAllowed] = useState(false);
  const [priceFrom, setPriceFrom] = useState();
  const lang = localStorage.getItem("language");
  const [shippment, setShippment] = useState(false);
  const onClick = (e) => {
    console.log(e.target.value, "target value");
    setSelectedCategoryValue(e.target.value);
    for (let i = 0; i < cateogory_value.length; i++) {
      if (cateogory_value[i].id == e.target.value) {
        setSubCateogary_Value(cateogory_value[i].subcategory);
      }
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { name: "", description: "", language: "" }]);
  };

  const [formErrors, setFormErrors] = useState({});
  const validate = (values) => {
    let errors = {};

    if (!values.description) {
      errors.description = `${t("errorMessages.required_field")}`;
    }
    if (!values.cateogories) {
      errors.cateogories = `${t("errorMessages.required_field")}`;
    }
    // if (!values.estimate_time) {
    //   errors.estimate_time = `${t("errorMessages.required_field")}`;
    // }

    if (!values.price_type) {
      errors.price_type = `${t("errorMessages.required_field")}`;
    }

    if (!values.quantity_unit) {
      errors.quantity_unit = `${t("errorMessages.required_field")}`;
    }
    if (!values.area_unit) {
      errors.area_unit = `${t("errorMessages.required_field")}`;
    }
    if (!values.area) {
      errors.area = `${t("errorMessages.required_field")}`;
    }
    if (!values.quantity) {
      errors.quantity = `${t("errorMessages.required_field")}`;
    }

    return errors;
  };
  const validated = (values) => {
    let errors = {};

    if (!values.cateogories) {
      errors.cateogories = `${t("errorMessages.required_field")}`;
    }

    if (!values.price_type) {
      errors.price_type = `${t("errorMessages.required_field")}`;
    }

    return errors;
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };
  let token = localStorage.getItem("accessToken");

  const onChange = (checked) => {
    setShippment(checked);
  };

  const handleSubmit = async (e, value) => {
    e.preventDefault();
    if (e.detail === 1) {
      setFormErrors(validate(data));

      let images = [];
      for (let i = 0; i < fileList.length; i++) {
        images.push(fileList[i].response.data[0]);
      }

      const payload = {
        category: data.cateogories,
        subcategory: data.subcateogories,
        estimate_time: datepick,
        description: data.description,
        total_quantity: data.quantity,
        total_quantity_unit: data.quantity_unit,
        area: data.area,
        area_unit: data.area_unit,
        product_name: inputList,
        earnest_allowed: earnestAllowed,
        price_from: priceFrom || data.price_from,
        price_to: data.price_to,
        image: images,
        status: value,
        is_deleted: "False",
        type: "PRODUCT",
        price_currency: data.product_currency || "USD",
        product_amount_unit: data.quantity_unit,
        shipping: shippment,
      };
      data.cateogories = selectedCategoryValue;
      payload.category = selectedCategoryValue;
      data.price_type = pricetype;
      payload.pricing_type = pricetype;
      console.log(payload, "payloadddddddddddddddddddddd");

      if (Object.keys(validate(data)).length === 0) {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?lang=${lang}`,
            payload,
            {
              headers: {
                Authorization: ` Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(`${t("errorMessages.success_added")}`);
            history.push(`/myproducts`);
            document.getElementById("form").reset();
            setdisabled(true);
          })
          .catch((err) => {
            if (err.response !== undefined) {
              for (let i of err.response.data.message) {
                toast.error(`${i.error}`);
              }
            }
          });
      }
    }
  };

  const handleSaveDraft = async (e, value, id) => {
    e.preventDefault();
    if (e.detail === 1) {
      setFormErrors(validated(data));
      let images = [];
      for (let i = 0; i < fileList.length; i++) {
        images.push(fileList[i].response.data[0]);
      }
      const payload = {
        category: data.cateogories,
        subcategory: data.subcateogories,
        estimate_time: datepick,
        description: data.description,
        total_quantity: data.quantity || 0,
        total_quantity_unit: data.quantity_unit,
        area: data.area || 0,
        area_unit: data.area_unit,
        product_name: inputList,
        earnest_allowed: earnestAllowed,
        price_from: priceFrom || data.price_from || 0,
        price_to: data.price_to || 0,
        image: images,
        status: value,
        is_deleted: "False",
        type: "PRODUCT",
        price_currency: data.product_currency,
        product_amount_unit: data.quantity_unit,
        pricing_type: data.price_type,
        shipping: shippment,
      };
      data.cateogories = selectedCategoryValue;
      payload.category = selectedCategoryValue;
      data.price_type = pricetype;
      payload.pricing_type = pricetype;

      if (Object.keys(validated(data)).length === 0) {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?lang=${lang}`,
            payload,
            {
              headers: {
                Authorization: ` Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setdisabled(true);
            toast.success(`${t("errorMessages.success_added")}`);
            history.push(`/myproducts`);
          })
          .catch((err) => {
            if (err.response !== undefined) {
              for (let i of err.response.data.message) {
                toast.error(`${i.error}`);
              }
            }
          });
      }
    }
  };

  const Price = (e) => {
    if (e.target.value === "FIXED") {
      setPricetype("FIXED");
      setEarnestAllowed(false);
      setPriceFrom("0");
    } else {
      setPricetype("RANGE");
      setPriceFrom("");
      setEarnestAllowed(true);
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/category-subcategory/?type=PRODUCT&lang=${lang}`
      )
      .then((res) => {
        setCateogary_Value(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, [loading]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`)
      .then((res) => {
        setLanguage(res.data.data);
      })
      .catch((err) => console.log(err, "bug"));
    //eslint-disable-next-line
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="scroll_div">
          <div className="headingWrap">
            <h3 className="title">{t("myproduct.ProductForm.title")}</h3>
          </div>
          <form id="form" noValidate>
            <div className="card dash_edit_card">
              <>
                {inputList.map((x, i) => {
                  return (
                    <div className="row">
                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.language")}*
                        </CLabel>
                        <CSelect
                          value={x.language}
                          onChange={(e) => handleInputChange(e, i)}
                          onClick={(e) => handleInputChange(e, i)}
                          name="language"
                          id="language"
                          className="form-control"
                        >
                          <option selected>
                            {t("myproduct.ProductForm.select")}{" "}
                          </option>
                          {languages.map((e) => (
                            <option value={e.id} className="text-capitalize">
                              {e.language}({e.code})
                            </option>
                          ))}
                        </CSelect>
                      </div>

                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.name")}*
                        </CLabel>
                        <CInput
                          value={x.name}
                          onChange={(e) => handleInputChange(e, i)}
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter your Product Name"
                        />
                      </div>

                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.description")}*
                        </CLabel>
                        <CInput
                          value={x.description}
                          onChange={(e) => handleInputChange(e, i)}
                          name="description"
                          id="description"
                          className="form-control"
                          placeholder="Enter your Product description"
                          maxLength="200"
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.ProductName.differentLang")}
                        </CLabel>
                        <div className="btn-box">
                          {inputList.length !== 1 && (
                            <span
                              className="remove-btn"
                              onClick={() => handleRemoveClick(i)}
                            >
                              -
                            </span>
                          )}
                          {inputList.length - 1 === i && inputList.length < 2 && (
                            <span className="add-btn" onClick={handleAddClick}>
                              +
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            </div>
            <div>
              <div className="card dash_edit_card">
                <CRow>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.totalQuantity")}
                    </CLabel>
                    <CInput
                      onChange={handleChange}
                      name="quantity"
                      id="quantity"
                      className="form-control"
                      placeholder="Enter quantity"
                    />
                    {formErrors.quantity && (
                      <span className="error">{formErrors.quantity}</span>
                    )}
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.quantityUnit")}*
                    </CLabel>
                    <CSelect
                      name="quantity_unit"
                      id="quantity_unit"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option disabled selected>
                        {t("myproduct.ProductForm.select")}{" "}
                      </option>
                      <option>
                        {t("myproduct.ProductForm.quantityUnitData.kilograms")}
                      </option>
                      <option>
                        {t("myproduct.ProductForm.quantityUnitData.dekagrams")}
                      </option>
                      <option>
                        {t("myproduct.ProductForm.quantityUnitData.hectograms")}
                      </option>
                      <option>
                        {t("myproduct.ProductForm.quantityUnitData.grams")}
                      </option>
                    </CSelect>
                    {formErrors.quantity_unit && (
                      <span className="error">{formErrors.quantity_unit}</span>
                    )}
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="area">
                      {t("myproduct.ProductForm.area")}*
                    </CLabel>
                    <CInput
                      onChange={handleChange}
                      name="area"
                      id="area"
                      className="form-control"
                      placeholder="Enter area"
                    />
                    {formErrors.area && (
                      <span className="error">{formErrors.area}</span>
                    )}
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.areaunit")}*
                    </CLabel>
                    <CSelect
                      name="area_unit"
                      id="area_unit"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option disabled selected>
                        {t("myproduct.ProductForm.select")}
                      </option>
                      <option value="YARD">
                        {t("myproduct.ProductForm.areaunitData.yard")}
                      </option>
                      <option value="ACRE">
                        {t("myproduct.ProductForm.areaunitData.acre")}
                      </option>
                      <option value="HECTARE">
                        {t("myproduct.ProductForm.areaunitData.hectare")}
                      </option>
                    </CSelect>
                    {formErrors.area_unit && (
                      <span className="error">{formErrors.area_unit}</span>
                    )}
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="estimate_time">
                      {t("myproduct.ProductForm.estimate_date")}*
                    </CLabel>

                    <CustomDatePicker
                      wrapperClassName="datepicker"
                      className="form-control"
                      dateFormat="yy/mm/dd"
                      selected={Moment(datepick).toDate()}
                      name="estimate_time"
                      id="estimate_time"
                      onChange={(date) => setDatePick(formatDate(date))}
                      onClick={(date) => setDatePick(formatDate(date))}
                      minDate={new Date()}
                    />
                    {formErrors.estimate_time && (
                      <span className="error">{formErrors.estimate_time}</span>
                    )}
                  </CCol>
                  <CCol md={3}>
                    <div className="form-group">
                      <CLabel htmlFor="" className="product-label">
                        {t("myproduct.ProductForm.currency")}*
                      </CLabel>

                      <CSelect
                        custom
                        name="product_currency"
                        id="product_currency"
                        onChange={handleChange}
                        className="form-control"
                      >
                        {currencyData.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </CSelect>
                    </div>
                  </CCol>
                  <CCol className="form-group" md={3}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.price_type")}*
                    </CLabel>
                    <CSelect
                      name="price_type"
                      id="price_type"
                      className="form-control"
                      onChange={Price}
                    >
                      <option disabled selected>
                        {t("myproduct.ProductForm.choose_price_type")}
                      </option>
                      <option value="FIXED">
                        {t("myproduct.ProductForm.price_type_data.fixed")}
                      </option>
                      <option value="RANGE">
                        {t("myproduct.ProductForm.price_type_data.range")}
                      </option>
                    </CSelect>
                    {formErrors.price_type && (
                      <span className="error">{formErrors.price_type}</span>
                    )}
                  </CCol>
                  {pricetype === "FIXED" && (
                    <CCol className="form-group" md={12}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("myproduct.ProductForm.price")}
                        <span>
                          &nbsp; ({t("myproduct.ProductForm.perkg")}/
                          {t("myproduct.ProductForm.perunit")})*
                        </span>
                      </CLabel>
                      <CInput
                        onChange={handleChange}
                        name="price_to"
                        id="price_to"
                        className="form-control"
                        placeholder="Enter your Price"
                      />
                      {formErrors.price_to && (
                        <span className="error">{formErrors.price_to}</span>
                      )}
                    </CCol>
                  )}
                  {pricetype === "RANGE" && (
                    <>
                      <CCol className="form-group" md={6}>
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.priceFrom")}
                          <span>
                            &nbsp; ({t("myproduct.ProductForm.perkg")}/
                            {t("myproduct.ProductForm.perunit")})*
                          </span>
                        </CLabel>
                        <CInput
                          onChange={handleChange}
                          name="price_from"
                          id="price_from"
                          className="form-control"
                          placeholder="Enter your Price from"
                        />
                        {formErrors.price_from && (
                          <span className="error">{formErrors.price_from}</span>
                        )}
                      </CCol>
                      <CCol className="form-group" md={6}>
                        <CLabel className="product-label" htmlFor="phone">
                          {t("myproduct.ProductForm.priceto")}
                          <span>
                            &nbsp; ({t("myproduct.ProductForm.perkg")}/
                            {t("myproduct.ProductForm.perunit")})*
                          </span>
                        </CLabel>
                        <CInput
                          onChange={handleChange}
                          name="price_to"
                          id="price_to"
                          className="form-control"
                          placeholder="Enter your Price to"
                        />
                        {formErrors.price_to && (
                          <span className="errodisabledr">
                            {formErrors.price_to}
                          </span>
                        )}
                      </CCol>
                    </>
                  )}
                  <CCol className="form-group" md={6}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.categories")}*
                    </CLabel>
                    <CSelect
                      name="cateogories"
                      id="cateogories"
                      onChange={onClick}
                    >
                      <option value="KILOGRAMS" disabled selected>
                        {t("myproduct.ProductForm.select")}
                      </option>
                      {cateogory_value.map((el) => {
                        return (
                          <option value={el.id}>{el.title[0].name}</option>
                        );
                      })}
                    </CSelect>
                    {formErrors.cateogories && (
                      <span className="error">{formErrors.cateogories}</span>
                    )}
                  </CCol>
                  <CCol className="form-group" md={6}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.subcategories")}
                    </CLabel>
                    <CSelect
                      name="subcateogories"
                      id="subcateogories"
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option selected>
                        {t("myproduct.ProductForm.select")}
                      </option>
                      {subcateogory_value
                        ? subcateogory_value.map((el) => {
                            return (
                              <option value={el.id}>
                                {el.title.length > 0 ? el.title[0].name : null}
                              </option>
                            );
                          })
                        : ""}
                    </CSelect>
                  </CCol>
                  <CCol className="my-2" md={12}>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      name="shippment"
                      id="shippment"
                      onChange={onChange}
                    />
                    <CLabel className="product-label ml-3" htmlFor="phone">
                      I will bear the shippment
                    </CLabel>
                    {shippment && (
                      <CLabel className="product-message ml-3" htmlFor="phone">
                        Hope you will add your shipping cost in product price
                      </CLabel>
                    )}
                  </CCol>
                  <CCol className="form-group" md={12}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("myproduct.ProductForm.description")}*
                    </CLabel>
                    <CTextarea
                      rows="6"
                      onChange={handleChange}
                      name="description"
                      id="description"
                      className="form-control"
                      placeholder="Enter Description...."
                    ></CTextarea>
                    {formErrors.description && (
                      <span className="error">{formErrors.description}</span>
                    )}
                  </CCol>
                </CRow>
              </div>
              <div className="card dash_edit_card">
                <CLabel className="product-label" htmlFor="">
                  {t("myproduct.ProductForm.uploadImage")}
                </CLabel>
                <Upload
                    action="https://cho.direct/api/v1/upload-multiple/"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChangeImage}
                  onPreview={onPreview}
                  multiple={true}
                  maxCount={10}
                  headers={{
                    Authorization: ` Bearer ${token}`,
                  }}
                  beforeUpload={(file) => {
                    const isJPG =
                      file.type === "image/jpeg" || file.type === "image/png";
                    if (!isJPG) {
                      toast.error("You can only upload JPG or PNG file!");
                      return false;
                    } else {
                      return true;
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    {t("myproduct.ProductForm.upload")}
                  </Button>
                </Upload>

                {formErrors.myfile && (
                  <span className="error">{formErrors.myfile}</span>
                )}
              </div>
            </div>

            <div className="form-group float-right mb-4">
              <CButton
                className="btn btn-light"
                onClick={() => history.push({ pathname: "/myproducts" })}
              >
                {t("myproduct.ProductForm.cancel")}
              </CButton>
              <CButton
                className="btn-secondary ml-4"
                type="Submit"
                onClick={(e) => handleSaveDraft(e, "UNPUBLISHED")}
                disabled={disable}
              >
                {t("myproduct.ProductForm.saveAsDraft")}
              </CButton>
              <CButton
                className="btn cstm_btn ml-4"
                type="Submit"
                onClick={(e) => handleSubmit(e, "PUBLISHED")}
                disabled={disable}
              >
                {t("myproduct.ProductForm.submit")}
              </CButton>
            </div>
          </form>
          <ToastContainer />
        </div>
      )}
    </>
  );
};
export default AddProduct;
