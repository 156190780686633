import React, { useEffect, useState } from "react";
import TestTable from "../../../Components/table/testTable";
import axios from "axios";
import Loader from "../../../Components/loader/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MyEarnings = () => {
    const { t } = useTranslation('common');
    const [body, setBody] = useState();
    const [earningByUser, setEarningByUser] = useState();
    const [totalEarning, setTotalEarning] = useState();
    const [loading, setLoading] = useState(true);
    const lang = localStorage.getItem("language")
    const headingData = [
        {
            id: "1",
            Header: `${t("earnings.headingData.buyerName")}`,
            accessor: d => d.user.username.charAt(0).toUpperCase() + d.user.username.slice(1)
        },
        {
            id: "2",
            Header: `${t("earnings.headingData.earnest_amount")}`,
            accessor: d => {
                if (d.is_earnest_paid === true) {
                    return (
                        <span>{d.earnest_amount}</span>
                    )
                } else {
                    return (
                        <span>-</span>
                    )
                }
            },
        },
        {
            id: "3",
            Header: `${t("earnings.headingData.final_amount")}`,
            accessor: d => {
                if (d.is_final_paid === true) {
                    return (
                        <span>{d.final_amount}</span>
                    )
                } else {
                    return (
                        <span>-</span>
                    )
                }
            },
        },
        {
            id: "5",
            Header: `${t("earnings.headingData.total_paid")}`,
            accessor: "total_paid",
        }
    ];
    const token = localStorage.getItem("accessToken");
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}payment/earning/?lang=${lang}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setBody(res.data.data);
                setLoading(false);
            })
            .catch((err) => toast.error(err.response.data.detail));
        if (body !== undefined) {
            setEarningByUser(body.earning_by_user);
            setTotalEarning(body.total_earning)
        }
        // eslint-disable-next-line
    }, [loading]);

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            {earningByUser && earningByUser.length > 0 ? (
              <>
                <div className="headingWrap m-0">
                  <h3 className="m-4 title">{t("earnings.title")}</h3>
                </div>
                <div className="cursor_table mt-4">
                  <TestTable
                    headData={headingData}
                    bodyData={earningByUser}
                    rowPerPage="5"
                    paginationStatus={true}
                  />
                  <p className="note-text">
                    <span>*Note: </span>
                    <span>
                      {" "}
                      Total paid is amount what the seller/service provider will
                      get after the deductions of commission.
                    </span>
                  </p>
                </div>
                <div className="row">
                  <div className="col-12 ml-auto p-4">
                    <h4 className="float-right">
                      {t("earnings.headingData.total_earning")}:
                      <span className="ml-1">
                        {totalEarning.total_paid__sum}
                      </span>
                    </h4>
                  </div>
                </div>
              </>
            ) : (
              <div className="not-found">
                <h2>{t("earnings.headingData.no_earning")}</h2>
              </div>
            )}
          </>
        )}
        <ToastContainer />
      </>
    );
};
export default MyEarnings;
