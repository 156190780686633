import React, { useState, useEffect } from "react";
import { CInput, CLabel } from "@coreui/react";
import Modal from "../confirmModal/ConfirmModal";
import axios from "axios";
import deleteImg from "../../../../assets/images/delete.svg";
import Loader from "../../../Components/loader/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ShippingAddress = () => {
    const { t } = useTranslation('common');
    const [new_address, setNew_Address] = useState(false);
    const [body, setBody] = useState();
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("accessToken");
    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [deletedId, setDeletedId] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const lang = localStorage.getItem("language");
    const [details, setDetails] = useState({
        address: "",
        country: "",
        city: "",
        state: "",
        postal_code: "",
    });
    useEffect(() => {
      handleGet();
      // eslint-disable-next-line
    }, []);

    const handleGet = () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/shipping/?lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data.data);

          setLoading(false);
        })
        .catch((err) => console.log(err));
    };
    const validate = (values) => {
      let errors = {};

      if (!values.address) {
        errors.address = `${t("errorMessages.required_field")}`;
      }
      if (!values.city) {
        errors.city = `${t("errorMessages.required_field")}`;
      }

      if (!values.country) {
        errors.country = `${t("errorMessages.required_field")}`;
      }
      if (!values.state) {
        errors.state = `${t("errorMessages.required_field")}`;
      }
      return errors;
    };
    const handleChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setDetails({ ...details, [name]: value });
    };
    const handleSubmit = async (event, id) => {
      setFormErrors(validate(details));
      event.preventDefault();

      const payload = {
        address: details.address,
        country: details.country,
        city: details.city,
        state: details.state,
        postal_code: details.postal_code,
      };

      if (Object.keys(validate(details)).length === 0) {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}payment/shipping/?lang=${lang}`,
            payload,
            {
              headers: {
                Authorization: ` Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Succesfully added");
              setNew_Address(false);
              handleGet();

              setDetails({
                address: "",
                country: "",
                city: "",
                state: "",
                postal_code: "",
              });
            }
          })
          .catch((err) => {
            if (err.response !== undefined) {
              for (let i of err.response.data.message) {
                toast.error(`${i.error}`);
              }
            }
          });
      }
    };

    const handleEdit = (id) => {
      setEdit(true);
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/shipping/?id=${id}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };
    const handleEditSubmit = async (event, id) => {
      event.preventDefault();
      document.getElementById("editForm").reset();
      const payload = {
        address: details.address || data.address,
        country: details.country || data.country,
        city: details.city || data.city,
        state: details.state || data.state,
        postal_code: data.postal_code || details.postal_code,
      };
      await axios
        .put(
          `${process.env.REACT_APP_BASE_URL}payment/shipping/?id=${id}&lang=${lang}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Succesfully added");
            setNew_Address(false);
            handleGet();

            setDetails({
              address: "",
              country: "",
              city: "",
              state: "",
              postal_code: "",
            });
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    };

    const onAddressShow = () => {
      setNew_Address(true);
    };
    const handleDelete = (id) => {
      setShow(true);
      setDeletedId(id);
    };
    const handleDeleted = () => {
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}payment/shipping/?id=${deletedId}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success("successfully deleted");
          setShow(false);
          handleGet();
        })
        .catch((err) => toast.error(err.message));
    };
    const onCancel = () => {
      setShow(false);
      setEdit(false);
      setNew_Address(false);
      setFormErrors({});
      document.getElementById("form").reset();
      document.getElementById("editForm").reset();
    };
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="address mt-4">
              <div className="d-flex">
                <h5>Select your Shipping Address from previous list</h5>
                <span onClick={onAddressShow} className="add_new">
                  <p>Add New Shipping address</p>
                </span>
              </div>
              <div className="d-grid">
                {body.map((item, index) => {
                  return (
                    <div className="grid-address" key={index}>
                      <p>{item.address}</p>
                      <div className="d-flex justify-content-between flex-wrap">
                        <p>{item.city}</p>
                        <p>{item.state}</p>
                        <p>{item.country}</p>
                        <p>{item.postal_code}</p>
                      </div>

                      <div className="row mt-4">
                        <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-outline-info w-100"
                            onClick={() => handleEdit(item.id)}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-outline-danger w-100"
                            onClick={() => handleDelete(item.id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <Modal
              displayModal={new_address}
              closeModal={() => onCancel()}
              modalBody={
                <>
                  <div className="mt-4 row pl-3">
                    <div className="col-12">
                      <h4>Add Your New Address Detail</h4>
                    </div>
                  </div>
                  <div className="modal-body d-block text-left">
                    <div className="modalcontent">
                      <form id="form">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                Address
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="address"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="address"
                                type="text"
                              />
                              {formErrors.address && (
                                <span className="error">
                                  {formErrors.address}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                City
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder="Enter your city"
                                name="city"
                                type="text"
                              />
                              {formErrors.city && (
                                <span className="error">{formErrors.city}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                State
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="state"
                                className="form-control form-control-alternative"
                                placeholder="Enter your State"
                                name="state"
                                type="text"
                              />
                              {formErrors.state && (
                                <span className="error">
                                  {formErrors.state}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                Country
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="country"
                                className="form-control form-control-alternative"
                                placeholder="Enter your Country"
                                name="country"
                                type="text"
                              />
                              {formErrors.country && (
                                <span className="error">
                                  {formErrors.country}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="postal_code"
                              >
                                Postal Code
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="postal_code"
                                className="form-control form-control-alternative"
                                placeholder="Enter your postal code"
                                name="postal_code"
                                type="text"
                              />
                              {formErrors.postal_code && (
                                <span className="error">
                                  {formErrors.postal_code}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row float-right mt-4">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => onCancel()}
                            >
                              CANCEL
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary ml-3"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              }
            />

            <Modal
              displayModal={show}
              closeModal={() => onCancel()}
              modalBody={
                <>
                  <div className="modal-body">
                    <div className="modalcontent">
                      <div>
                        <img
                          width=""
                          height=""
                          src={deleteImg}
                          alt="delete"
                          className="icon_wrap"
                        />
                      </div>
                      <h2>Are you sure you want to delete</h2>
                      <div className="modal-button">
                        <button
                          type="submit"
                          className="btn btn-light"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          CANCEL
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={() => handleDeleted()}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              }
            />

            <Modal
              displayModal={edit}
              closeModal={() => {
                onCancel();
              }}
              modalBody={
                <>
                  <div className="mt-4 row pl-3">
                    <div className="col-12">
                      <h4>Edit Your Address Detail</h4>
                    </div>
                  </div>
                  <div className="modal-body d-block text-left">
                    <div className="modalcontent">
                      <form id="editForm">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                Address
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="address"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="address"
                                type="text"
                                defaultValue={data.address}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                City
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="city"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="city"
                                type="text"
                                defaultValue={data.city}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                State
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="state"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="state"
                                type="text"
                                defaultValue={data.state}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="address"
                              >
                                Country
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="country"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="country"
                                type="text"
                                defaultValue={data.country}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group focused">
                              <CLabel
                                className="form-control-label"
                                htmlFor="postal_code"
                              >
                                Postal Code
                              </CLabel>
                              <CInput
                                onChange={handleChange}
                                id="postal_code"
                                className="form-control form-control-alternative"
                                placeholder="Home Address"
                                name="postal_code"
                                type="text"
                                defaultValue={data.postal_code}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row float-right mt-4">
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => onCancel()}
                            >
                              CANCEL
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary ml-3"
                              onClick={(e) => {
                                handleEditSubmit(e, data.id);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              }
            />
          </>
        )}
        <ToastContainer />
      </>
    );
};
export default ShippingAddress;
