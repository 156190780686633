import { ContractsActionTypes } from "../constants/action-types";

const INITIAL_STATE = {
    contracts: []
};

export const contractsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ContractsActionTypes.GET_CONTRACTS:
            return { contracts: action.payload };
        case ContractsActionTypes.GET_CONTRACT:
            return { contracts: action.payload };

        default:
            return state;
    }
};

