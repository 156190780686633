  import React, { useEffect, useState } from "react";
  import axios from "axios";
  import {
    CButton,
    CCol,
    CInput,
    CLabel,
    CRow,
    CSelect,
    CTextarea,
  } from "@coreui/react";
  import Loader from "../../../../Components/loader/index";
  import { toast } from "react-toastify";
  import { useHistory } from "react-router";
  import { ToastContainer } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import "react-datetime/css/react-datetime.css";
  import { useTranslation } from "react-i18next";

  const CreateService = () => {
    const { t } = useTranslation("common");
    const currencyData = [
      { value: "USD", label: `${t("myproduct.ProductForm.usd")} ` },
      { value: "DONG", label: `${t("myproduct.ProductForm.dong")} ` },
    ];
    const [languages, setLanguage] = useState([]);
    const history = useHistory();
    const [cateogory_value, setCateogary_Value] = useState("");
    const [inputList, setInputList] = useState([
      { name: "", language: "1", description: "" },
    ]);
    const [equipList, setEquipList] = useState([{ equipment: "" }]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [service, setService] = useState([]);
    const lang = localStorage.getItem("language");
    const handleGet = () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/my-services-list/?lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setService(res.data.data);
          setEquipList(res.data.data.equipment);
          setCateogary_Value(res.data.data.category);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };

    let bId = [];
    // const handleEquipChange = (e, index) => {
    //   const { name, value } = e.target;
    //   const list = [...equipList];
    //   list[index][name] = value;
    //   setEquipList(list);
    // };
    // const handleEquipRemoveClick = (index) => {
    //   const list = [...equipList];
    //   list.splice(index, 1);
    //   setEquipList(list);
    // };
    // const handleEquipAddClick = () => {
    //   setEquipList([...equipList, { equipment: "" }]);
    // };
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
    const handleRemoveClick = (index) => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
    const handleAddClick = () => {
      setInputList([...inputList, { name: "", language: "", description: "" }]);
    };

    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setData({ ...data, [name]: value });
    };
    let token = localStorage.getItem("accessToken");

    const validate = (values) => {
      let errors = {};

      if (values.price === 0) {
        errors.price = `${t("errorMessages.service.price")}`;
      }

      return errors;
    };
    let getData = [];

    const handleSubmit = async (e, value) => {
      e.preventDefault();
      setFormErrors(validate(data));

      const payload = {
        category: data.cateogories,
        pricing_type: data.price_type || service.pricing_type,
        price_to: Number(data.price) || service.price,
        service: getData || bId,
        price_currency: data.price_currency || "USD",
        equipment: equipList,
        product_name: inputList,
        type: "SERVICE",
        status: value,
        description: data.description,
      };
      if (Object.keys(validate(data)).length === 0) {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}inventory/my-services-list/?lang=${lang}`,
            payload,
            {
              headers: {
                Authorization: ` Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(`${t("errorMessages.success_added")}`);
            handleGet();
            history.push(`/myservice`);
            document.getElementById("form").reset();
          })
          .catch((err) => {
            if (err.response !== undefined) {
              for (let i of err.response.data.message) {
                toast.error(`${i.error}`);
              }
            }
          });
      }
    };

    useEffect(() => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/category-subcategory/?type=SERVICE&lang=${lang}`
        )
        .then((res) => {
          setCateogary_Value(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
      // eslint-disable-next-line
    }, [loading]);
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`)
        .then((res) => {
          setLanguage(res.data.data);
        })
        .catch((err) => console.log(err, "bug"));
      // eslint-disable-next-line
    }, [loading]);

    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <div className="scroll_div">
            <div className="headingWrap">
              <h3 className="title">{t("myservice.maintitle")}</h3>
            </div>
            <form id="form" noValidate>
              <div className="card dash_edit_card">
                <>
                  {inputList.map((x, i) => {
                    return (
                      <div className="row">
                        <div className="col-lg-3 form-group">
                          <CLabel className="product-label" htmlFor="phone">
                            {t("myservice.label11")}*
                          </CLabel>
                          <CSelect
                            value={x.language}
                            onChange={(e) => handleInputChange(e, i)}
                            onClick={(e) => handleInputChange(e, i)}
                            name="language"
                            id="language"
                            className="form-control"
                          >
                            <option selected>
                              {t("myproduct.ProductForm.select")}{" "}
                            </option>
                            {languages.map((e) => (
                              <option value={e.id} className="text-capitalize">
                                {e.language}({e.code})
                              </option>
                            ))}
                          </CSelect>
                        </div>

                        <div className="col-lg-3 form-group">
                          <CLabel className="product-label" htmlFor="phone">
                            {t("myservice.label1")}*
                          </CLabel>
                          <CInput
                            value={x.name}
                            onChange={(e) => handleInputChange(e, i)}
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Enter your Service Name"
                          />
                        </div>
                        {/* <div className="col-lg-3 form-group">
                          <CLabel className="product-label" htmlFor="phone">
                            {t("myservice.label13")}*
                          </CLabel>
                          <CInput
                            value={x.description}
                            onChange={(e) => handleInputChange(e, i)}
                            name="description"
                            id="description"
                            className="form-control"  
                            placeholder="Enter your Product description"
                          />
                        </div> */}
                        <div className="col-lg-3 form-group">
                          <CLabel className="product-label" htmlFor="phone">
                            {t("myservice.label12")}
                          </CLabel>
                          <div className="btn-box">
                            {inputList.length !== 1 && (
                              <span
                                className="remove-btn"
                                onClick={() => handleRemoveClick(i)}
                              >
                                -
                              </span>
                            )}
                            {inputList.length - 1 === i &&
                              inputList.length < 2 && (
                                <span
                                  className="add-btn"
                                  onClick={handleAddClick}
                                >
                                  +
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              </div>
              <div>
                <div className="card dash_edit_card">
                  <CRow>
                    <CCol md={6}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("myservice.label2")}*
                      </CLabel>
                      <CSelect
                        onChange={handleChange}
                        name="price_type"
                        id="price_type"
                        className="form-control"
                        // onClick={Price}
                      >
                        <option disabled selected>
                          Choose a Price
                        </option>
                        <option value="HOURLY">Hourly</option>
                        <option value="DAY">Day</option>
                      </CSelect>
                    </CCol>
                    <CCol md={6}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("myservice.label3")}
                        <span>*</span>
                      </CLabel>
                      <CInput
                        type="number"
                        onChange={handleChange}
                        name="price"
                        id="price"
                        className="form-control"
                        placeholder="Enter your Price"
                        min="1"
                      />
                      {formErrors.price && (
                        <span className="error">{formErrors.price}</span>
                      )}
                    </CCol>
                    <CCol md={6}>
                      <div className="form-group mt-3">
                        <CLabel htmlFor="" className="product-label">
                          {t("myservice.label4")}
                        </CLabel>

                        <CSelect
                          custom
                          name="price_currency"
                          id="price_currency"
                          onChange={handleChange}
                          className="form-control"
                        >
                          {currencyData.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </CSelect>
                      </div>
                    </CCol>
                    <CCol className="form-group mt-3" md={6}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("myproduct.ProductForm.categories")}*
                      </CLabel>
                      <CSelect
                        onChange={handleChange}
                        name="cateogories"
                        id="cateogories"
                        //   onChange={onClick}
                      >
                        <option value="KILOGRAMS" disabled selected>
                          {t("myproduct.ProductForm.select")}
                        </option>
                        {cateogory_value.map((el) => {
                          return (
                            <option value={el.id}>{el.title[0].name}</option>
                          );
                        })}
                      </CSelect>
                      {formErrors.cateogories && (
                        <span className="error">{formErrors.cateogories}</span>
                      )}
                    </CCol>

                    <CCol className="form-group" md={12}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("myproduct.ProductForm.description")}*
                      </CLabel>
                      <CTextarea
                        rows="6"
                        onChange={handleChange}
                        name="description"
                        id="description"
                        className="form-control"
                        placeholder="Enter Description...."
                        maxLength={200}
                      ></CTextarea>
                      {formErrors.description && (
                        <span className="error">{formErrors.description}</span>
                      )}
                    </CCol>
                  </CRow>
                  {/* <div className="card service-card">
                    <>
                      {equipList.map((x, i) => {
                        return (
                          <div className="equiment  w-100">
                            <div className="form-group w-100">
                              <CLabel className="product-label" htmlFor="phone">
                                {t("myservice.label5")}
                              </CLabel>
                              <CInput
                                value={x.equipment}
                                onChange={(e) => handleEquipChange(e, i)}
                                name="equipment"
                                id="equipment"
                                className="form-control"
                                placeholder="Enter your equipment Name"
                              />
                              {formErrors.equipment && (
                                <span className="error">
                                  {formErrors.equipment}
                                </span>
                              )}
                            </div>

                            <div className="text-center">
                              <CLabel className="product-label" htmlFor="phone">
                                {t("myservice.label6")}
                              </CLabel>
                              <div className="btn-box m-auto text-center">
                                {equipList.length !== 1 && (
                                  <span
                                    className="remove-btn"
                                    onClick={() => handleEquipRemoveClick(i)}
                                  >
                                    -
                                  </span>
                                )}
                                {equipList.length - 1 === i && (
                                  <span
                                    className="add-btn ml-4"
                                    onClick={handleEquipAddClick}
                                  >
                                    +
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div> */}
                </div>
              </div>

              <div className="form-group float-right mb-4">
                <CButton
                  className="btn btn-light"
                  onClick={() => history.push({ pathname: "/myproducts" })}
                >
                  {t("myproduct.ProductForm.cancel")}
                </CButton>

                <CButton
                  className="btn cstm_btn ml-4"
                  type="Submit"
                  onClick={(e) => handleSubmit(e, "PUBLISHED")}
                  // disabled={disable}
                >
                  {t("myproduct.ProductForm.submit")}
                </CButton>
              </div>
            </form>
            <ToastContainer />
          </div>
        )}
      </>
    );
  };
  export default CreateService;
