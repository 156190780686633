import React, { useState } from "react";
import { useHistory } from "react-router";
import { CButton, CInput, CLabel, CLink } from "@coreui/react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Auth from "../../../Auth";
import { useTranslation } from "react-i18next";

const CheckoutLogin = (props) => {
    const { t } = useTranslation('common');
    const history = useHistory();
    const lang = localStorage.getItem("language");
    const [data, setData] = useState({
        name: "",
        password: "",
    });
    const [formErrors, setFormErrors] = useState({});
    const [fixed, setFixed] = useState(false)

    const validate = (values) => {
        let errors = {};
        if (!values.name) {
            errors.name = "This field is required";
        }
        if (!values.password) {
            errors.password = "This field is required";
        }

        return errors;
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = async (event) => {
        setFormErrors(validate(data))
        event.preventDefault();
        document.getElementById("form").reset();
        const payload = {
            username: (data.name).replace(/\s/g, ''),
            password: data.password,
        };

        if (Object.keys(validate(data)).length === 0) {
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}auth/login/?lang=${lang}`, payload)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success("You are logged in");
                        localStorage.setItem("accessToken", res.data.data.token);
                        Auth.authenticate();
                        localStorage.setItem("accessToken", res.data.data.token);
                        localStorage.setItem("permissions", res.data.data.permissions[0])
                        let productType = localStorage.getItem("productType");
                        setData({
                            name: "",
                            password: "",
                        });
                        if (productType === "RANGE") {
                            history.push({ pathname: "/create-offer" });
                        }
                        else if (productType === "FIXED") {
                            setFixed(true);
                            localStorage.setItem("fixed", fixed)
                            history.push({ pathname: "/Checkout" });
                        }
                        else {
                            history.push({ pathname: "/dashboard" })
                        }
                        props.OnNext();
                    }
                })
                .catch((err) => {
                    if (err.response !== undefined) {
                        for (let i of err.response.data.message) {
                            toast.error(`${i.error}`);
                        }
                    } else {
                        toast.error("an error occured");
                    }
                });
        }
    };

    return (
        <div>

            <div className="">
                <h5 className="border-bottom w-100">HAVE AN ACCOUNT? LOGIN HERE{t("LoginForm.headerTitle")}</h5>

                <div className="steps_login">
                    <form className="dash_edit_card" action="" id="form" onSubmit={handleSubmit} noValidate>
                        <div className="form-group">
                            <CLabel htmlFor="phone">Enter your Username or Mobile No. or Email Id {t("LoginForm.label1")}</CLabel>
                            <CInput
                                name="name"
                                id="name"
                                value={data.name}
                                onChange={handleChange}
                                className="form-control"
                                placeholder={t("LoginForm.label1")}
                            />
                            {formErrors.name && (
                                <span className="error">{formErrors.name}</span>
                            )}
                        </div>
                        <div className="form-group">
                            <CLabel htmlFor="phone">Enter your password {t("LoginForm.label2")}</CLabel>
                            <CInput
                                type="password"
                                name="password"
                                id="password"
                                value={data.password}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Password"
                            />
                            {formErrors.password && (
                                <span className="error">{formErrors.password}</span>
                            )}
                        </div>
                        <div className="form-group">
                            <CButton type="submit" className="btn cstm_btn">
                                Login{t("LoginForm.buttonText")}
                            </CButton>
                        </div>
                        <ToastContainer />
                    </form>
                    <h6>
                        Dont’t have an account?{" "}{t("LoginForm.footer.title1")}
                        <CLink className="head" to="/signup">
                            Sign up{t("LoginForm.footer.signup")}
                        </CLink>
                    </h6>
                    <h6>
                        <CLink to="/forgot" className="head">
                            Forgot Password?{t("LoginForm.footer.forgot_password")}
                        </CLink>
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default CheckoutLogin;
