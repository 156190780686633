import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
 
const AnyReactComponent = ({ text }) => <div>{text}</div>;
 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 14.0583,
      lng: 108.2772
    },
    zoom: 11
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
    
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8"/* YOUR KEY HERE */ }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMapReact>
     
    );
  }
}
 
export default SimpleMap;