import React, { useState, useEffect } from "react";
import Card from "./Card";
import { useSelector, useDispatch } from "react-redux";
import { setProduct } from "../../../../redux/actions/Productactions";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import axios from "axios";
import Dropdown from "./cateogery";
import Loader from "../../../Components/loader/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { subcatId } from "../../../../redux/actions/Productactions";
import { useTranslation } from "react-i18next";

const Body = () => {
  const { t } = useTranslation('common');
  const [drop, setDrop] = useState(false);
  const [data, setData] = useState([]);
  const [cateogory_value, setCateogary_Value] = useState([]);
  const [sorting, setSorting] = useState("");
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState([]);
  let pageNumber = [];
  const [adsLeft, setAdsLeft] = useState([]);
  const state = useSelector((state) => state.product.product);

  const dispatch = useDispatch();

  let token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/category-subcategory/?type=PRODUCT&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        setCateogary_Value(res.data.data);
        setSorting("");
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [loading, token, lang]);

  useEffect(() => {
    if (state === undefined) {
      if (!sorting) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&lang=${lang}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setData(res.data.data);
            setBody(res.data);
            dispatch(setProduct(res.data.data));
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&${sorting}=true&lang=${lang}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setBody(res.data);
            setData(res.data.data);
            dispatch(setProduct(res.data.data));
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&sub_category_id=${state}&lang=${lang}`
        )
        .then((res) => {
          setBody(res.data);
          setData(res.data.data);
          dispatch(setProduct(res.data.data));
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [state, sorting, loading]);

  useEffect(() => {
    if (loading === false && body !== undefined) {
      if (body !== undefined) {
        for (let i = 1; i <= body.pages; i++) {
          pageNumber.push({ number: i });
        }
      }
    }
    // eslint-disable-next-line
  }, [loading, body]);

  const pagination = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/product/?page=${
          value + 1
        }&lang=${lang}`,
        {}
      )
      .then((res) => {
        setBody(res.data);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        // toast.error(err.response.data)
      });
  };
  const previouspagination = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/product/?page=${
          value - 1
        }&lang=${lang}`,
        {}
      )
      .then((res) => {
        setBody(res.data);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        // toast.error(err.response.data)
      });
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}adminpanel/get-advertisement/?title=Product&language=${lang}&position=Left&lang=${lang}`
      )
      .then((res) => {
        setAdsLeft(res.data.data);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    // eslint-disable-next-line
  }, []);
  const reset = () => {
    dispatch(subcatId(""));
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
        setBody(res.data);
        dispatch(setProduct(res.data.data));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const [sort, setSort] = useState(false);
  const [sortPrice, setSortPrice] = useState(false);
  const sortingData = () => {
    console.log(sort, "sort");
    setSort(!sort);
    if (sort) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&order=date&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data);
          setData(res.data.data);
          setDrop(!drop);
          dispatch(setProduct(res.data.data));
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&order=-date&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data);
          setDrop(!drop);
          setData(res.data.data);
          dispatch(setProduct(res.data.data));
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const sortingPrice = () => {
    setSortPrice(!sortPrice);
    if (sortPrice) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&order=price&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data);
          setData(res.data.data);
          setDrop(!drop);
          dispatch(setProduct(res.data.data));
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}inventory/product/?type=PRODUCT&order=-price&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data);
          setDrop(!drop);
          setData(res.data.data);
          dispatch(setProduct(res.data.data));
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className="prds_pagination_info">
              <div className="left">
                <span>{t("myproduct.product.sub_heading")}</span>
              </div>
              <div className="right">
                <p
                  className="body__sortingRight"
                  onClick={() => {
                    setDrop(!drop);
                  }}
                >
                  {t("myproduct.product.sort")}
                  <ArrowDropDownIcon />
                </p>
                {drop && (
                  <div className="slider__options">
                    <ul>
                      <li
                        onClick={() => {
                          sortingData();
                        }}
                      >
                        {t("myproduct.product.new")}
                      </li>
                      <li
                        onClick={() => {
                          sortingPrice();
                        }}
                      >
                        {t("myproduct.product.old")}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="all_products">
              <div className="filters">
                <div className="filter_box categories">
                  <div className="d-flex">
                    <h5>{t("myproduct.product.category")}</h5>
                    <span className="filter_span" onClick={() => reset()}>
                      {t("myproduct.product.reset_filter")}
                    </span>
                  </div>
                  {cateogory_value.map((value, index) => {
                    return value.title.length > 0 ? (
                      <Dropdown
                        key={index}
                        title={value?.title.map((item) => {
                          return item.language.code === lang ? item.name : null;
                        })}
                        subCategories={value?.subcategory.map((item) => {
                          return item;
                        })}
                      />
                    ) : null;
                  })}
                </div>
                <div className="side_add d-none d-lg-block">
                  {adsLeft.map((item, index) => {
                    return (
                      <img
                        width=""
                        height=""
                        src={item.image}
                        alt="cardImage"
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>

              <div className="content">
                <div className="row">
                  {data.map((value, i) => {
                    return (
                      <div className="col-6 col-md-6 col-lg-4" key={i}>
                        <Card object={value} key={i} />
                      </div>
                    );
                  })}
                </div>

                <div className="col-lg-12">
                  <div className="pagination">
                    <>
                      {body.page_number > 1 ? (
                        <button
                          className="paginataion_button"
                          onClick={() => previouspagination(body.page_number)}
                        >
                          &#x3c;
                        </button>
                      ) : null}
                      {body.page_number < body.total_pages ? (
                        <button
                          className="paginataion_button"
                          onClick={() => pagination(body.page_number)}
                        >
                          &#x3e;
                        </button>
                      ) : null}{" "}
                    </>
                  </div>
                </div>
              </div>
              <div className="side_add d-block d-lg-none d-md-none">
                {adsLeft.map((item, index) => {
                  return (
                    <img
                      width="100"
                      height="auto"
                      src={item.image}
                      alt="cardImage"
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Body;
