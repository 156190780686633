import React, { useState } from "react";
import { useHistory } from "react-router";
import { CButton, CInput, CLabel, CLink } from "@coreui/react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Logo from "../../../assets/images/newLogo.png";
import login from "../../../assets/images/login.png";
import { useTranslation } from "react-i18next";

const ChangePassword = (value) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const state = {
    password: "",
    cpassword: "",
  };
  const [initialvalue, setInitialValue] = useState(state);
  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInitialValue({ ...initialvalue, [name]: value });
  };
  const lang = localStorage.getItem("language");
  const validate = (values) => {
    let errors = {};

    // const regex =
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (!values.password) {
      errors.password = `${t("errorMessages.required_field")}`;
    } else if (!regex.test(values.password)) {
      errors.password =
        `${t("errorMessages.signup.password_validation")}`;
    }
    if (!values.cpassword) {
      errors.cpassword = `${t("errorMessages.required_field")}`;
    } else if (values.password !== values.cpassword) {
      errors.cpassword = `${t("errorMessages.signup.password_match")}`;
    }

    return errors;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validate(initialvalue));

    let data;
    if (value.val.value.phone !== null) {
      data = {};
      data.email_or_phone = value.val.value.phone;
      data.new_password = initialvalue.password;
    } else if (value.val.value.email !== null) {
      data = {};
      data.email_or_phone = value.val.value.email;
      data.new_password = initialvalue.password;
    }
    if (Object.keys(validate(initialvalue)).length === 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}auth/newpassword/?token=${value.props}&lang=${lang}`,
          data
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success(`${t("errorMessages.password.success")}`);
            history.push({ pathname: "/login" });
          }
        })
        .catch((err) => toast.error(err));
    }
  };

  return (
    <div className="login_signup" style={{ backgroundColor: "white" }}>
      <div className="left" data-aos="zoom-in">
        <img width="100" height="auto" src={login} alt="timeline" />
      </div>
      <div className="right" data-aos="fade-left">
        <CLink to="/">
          <img width="100" height="auto" src={Logo} alt="Logo" />
        </CLink>
        <h2 className="change">Change Password</h2>

        <form action="" id="form" onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <CLabel htmlFor="phone">Enter your new Password</CLabel>
            <CInput
              name="password"
              type="password"
              id="password"
              value={initialvalue.password}
              onChange={handleChange}
              className="form-control"
              placeholder="New Password"
            />
            {formErrors.password && (
              <span className="error">{formErrors.password}</span>
            )}
          </div>
          <div className="form-group">
            <CLabel htmlFor="phone">Confirm password</CLabel>
            <CInput
              type="password"
              name="cpassword"
              id="cpassword"
              value={initialvalue.cpassword}
              onChange={handleChange}
              className="form-control"
              placeholder="Confirm Password"
            />
            {formErrors.cpassword && (
              <span className="error">{formErrors.cpassword}</span>
            )}
          </div>
          <div className="form-group">
            <CButton type="submit" className="btn cstm_btn">
              Submit
            </CButton>
          </div>
          <ToastContainer />
        </form>
        <h6>
          Dont’t have an account? <CLink to="/signup">Sign up</CLink>
        </h6>
      </div>
    </div>
  );
};

export default ChangePassword;
