import React, { useState, useEffect } from "react";
import axios from "axios";
import Search from "./searchbox";
import ScrollAnimation from "react-animate-on-scroll";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Loader from "src/views/Components/loader";
library.add(faPlay);

const Aboutus = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  let lang = localStorage.getItem("language");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=About us&lang=${lang}`
      )
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  }, [lang]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="cstm_header about_header">
        <Search />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="about_sec">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-lg-8 m-auto">
                  <h2 className="heading text-center">
                    {t("staticPages.about_us")}
                  </h2>
                  {data.map((item) => {
                    return item.language.code === lang
                      ? item.section.map((el, index) => {
                          return (
                            <div key={index}>
                              {el.name === "about_us_header_section" ? (
                                <div key={index}>
                                  <div className="img_outer">
                                    <div>
                                      <iframe
                                        width="770"
                                        height="345"
                                        src={el.youtube_link}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  </div>
                                  <h4 className="heading">{el.subtitle}</h4>
                                  {/* <p>{item.description}</p> */}
                                  <div
                                    className="description_html text-center"
                                    dangerouslySetInnerHTML={{
                                      __html: `${el.description_html}`,
                                    }}
                                  ></div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      : null;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <div className="mission_vision">
                {data.map((item) => {
                  return (
                    <>
                      {item.language.code === lang
                        ? item.section.map((el, index) => {
                            return el.name !== "about_us_header_section" ? (
                              <div className="mission_vision_box" key={index}>
                                <img
                                  width=""
                                  height=""
                                  src={`${el.image_upload}`}
                                  alt="Icon"
                                />
                                <h4>{el.title}</h4>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: `${el.description_html}`,
                                  }}
                                ></p>
                              </div>
                            ) : null;
                          })
                        : null}
                    </>
                  );
                })}
              </div>
            </ScrollAnimation>
            <ToastContainer />
          </div>
        </>
      )}
    </div>
  );
};

export default Aboutus;
