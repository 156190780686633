import React, { useState, useEffect } from "react";
import { CHeaderBrand, CHeaderNav, CLink, CSelect } from "@coreui/react";
import "./TheHeader.css";
import axios from "axios";
import CIcon from "@coreui/icons-react";
import menu_icon from "../../../assets/images/menu_icon.png";
import { TheHeaderDropdown, TheHeaderDropdownNotif } from "./index";
import { useTranslation } from "react-i18next";
const TheHeader = (props) => {
  const token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  const [language, setLanguage] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/language/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLanguage(res.data.data);
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, [lang]);
  const handleLanguage = (e) => {
    const value = JSON.parse(e.target.value);

    let code = value.code;

    localStorage.setItem("language", code);
    i18n.changeLanguage(code);

    const payload = {
      language: code,
    };

    let token = localStorage.getItem("accessToken");

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };
  const { i18n } = useTranslation("common");
  return (
    <div>
      <div className="header" style={props.style}>
        <CLink>
          <img
            width=""
            height=""
            src={menu_icon}
            alt=""
            onClick={props.toggleSidebar}
          />
          <p className="d-block d-sm-none">Menu</p>
        </CLink>
        <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <CIcon name="logo" height="48" alt="Logo" />
        </CHeaderBrand>

        <CHeaderNav className="px-3 ml-auto">
          {/* <div id="google_translate_element"></div> */}
          {/* <div id="translate-a" className="ml-4">
                        <div className="wrap-a">
                            <div id="google_translate_element"></div>
                        </div>
                    </div> */}
          <div className="form-group focused m-0">
            <CSelect
              custom
              name="language"
              id="language"
              className="form-control"
              onChange={handleLanguage}
              defaultValue={lang}
            >
              <option disabled selected>
                Select Language
              </option>
              {language.map((option, index) => (
                <option
                  value={JSON.stringify(option)}
                  key={index}
                  className="text-capitalize"
                  selected={option.code === lang}
                >
                  {option.language}
                </option>
              ))}
            </CSelect>
          </div>

          <TheHeaderDropdownNotif />
          <TheHeaderDropdown />
        </CHeaderNav>
      </div>
    </div>
  );
};

export default TheHeader;
