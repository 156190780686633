import React, { useState, useEffect } from "react";
import Search from "../../searchbox";
import axios from "axios";
import Loader from "../../../../Components/loader/index";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import dummyProfile from "../../../../../assets/images/profile.jpeg";
import { ToastContainer } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import LoginModal from "../../../login/loginModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Abuse from "../../Abuse/abuse";
import { useTranslation } from "react-i18next";
import { socket } from "../../../../../socket";
import ChatDesign from "../../chat/chatDesign";
import { Category } from "@material-ui/icons";

const ServiceCategory = (props) => {
  const { t } = useTranslation("common");
  // let permissions = localStorage.getItem("permissions");
  const paramId = props.match.params;
  console.log("service username category page", props.match);
  console.log("jdsfhksjfkjdjfkjshdkf");
  let token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState([]);
  // const [cateogory_value, setCateogary_Value] = useState("");
  // const [categoryTitle, setCategoryTitle] = useState("");
  const [symbol, setSymbol] = useState("$");
  const [extra, setExtra] = useState([]);
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState();
  const [reportModal, setReportModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [reported, setReported] = useState();
  const [adds, setAdds] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [roomId, setRoomId] = useState("");
  const [participantsName, setParticipantsName] = useState([]);
  const [chatUser, setChatUser] = useState({});
  const [chatModal, setChatModal] = useState(false);
  const getLang = localStorage.getItem("language");
  const [current_user, setCurrent_user] = useState([]);

  const getData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/product/?id=${paramId.id}&lang=${getLang}`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
        // // inventory/service-from-serviceprovider/?username=${paramId.username.trim()}&category_id=${
        //   paramId.id
        // }
      )
      .then((res) => {
        setBody(res.data.data);
        setExtra(res.data.extra);
        setLoading(false);
        setUserName(res.data.data.user.username);
      })
      .catch((err) => {
        toast.error(err.response);
      });
    if (body) {
      if (body.price_currency === "DONG") {
        setSymbol("₫");
      }
    }
  };
  console.log(extra, "extra");
  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${getLang}`, {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfileData(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}adminpanel/get-advertisement/?title=Service&language=${getLang}&position=Right`
      )
      .then((res) => {
        setAdds(res.data.data);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    getData();
    // eslint-disable-next-line
  }, [loading, userName]);


  const onHire = (value) => {
    const payload = {
      username: value,
    };
    let token = localStorage.getItem("accessToken");
    if (!token) {
      setShow(true);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}payment/hire-offer-create/?lang=${getLang}`,
          payload,
          {
            headers: {
              Authorization: ` Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
  };
  const onCancel = () => {
    setShow(false);
  };
  useEffect(() => {
    if (userName !== undefined && token !== null) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}auth/report/?username=${userName}&lang=${getLang}`,
          {
            headers: {
              Authorization: ` Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setReported(res.data.data.is_reported);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [userName]);
  const reportUser = (value) => {
    setModalShow(true);
  };
  const onHide = () => {
    setReportModal(false);
    setModalShow(false);
  };
  const reportUserModal = () => {
    setModalShow(false);
    setReportModal(true);
  };
  const chatOff = () => {
    setChatModal(false);
  };
  const negotiation = () => {
    if (!token) {
      setShow(true);
    } else {
      let data = {
        name: body.user.username,
        participants: [
          {
            user_id: body.user.id.toString(),
            email: body.user.username,
            name: body.user.first_name,
            profile_image: body.user.profile_image,
          },
        ],
        user: {
          user_id: profileData.id.toString(),
          email: profileData.username,
          name: profileData.first_name,
          profile_image: profileData.profile_image,
        },
        is_group: false,
      };

      socket.emit("new_room_create", data);
      socket.on("add_room", (resp) => {
        setCurrent_user(resp.channel.creator_id);
        setParticipantsName(resp.channel.participants);
        setRoomId(resp.channel.id);
        setLoading(false);
      });
      setChatModal(true);
    }
  };

  useEffect(() => {
    if (participantsName !== undefined && participantsName.length > 0) {
      for (let j = 0; j < participantsName.length; j++) {
        if (participantsName[j].user_id === body.user.id.toString()) {
          setChatUser(participantsName[j]);
        }
      }
    }
    // eslint-disable-next-line
  }, [participantsName, current_user, roomId, profileData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="service_detail">
          <div className="cstm_header service_details_header">
            <Search />
          </div>
          <div className="service_provider_cont">
            <div className="container">
              <div className="row"></div>
              <div className="service_provider_details_cont main-product">
                <div className="container position-relative">
                  <div className="row">
                    
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="info_outer aos-init"
                            data-aos="fade-up"
                          >
                            <div className="info">
                              <img
                                width=""
                                height=""
                                src={
                                  body[0].user?.profile_image !== null ||
                                  body[0].user?.profile_image.length > 0
                                    ? body[0].user?.profile_image
                                    : dummyProfile
                                }
                                alt="dummy_image"
                              />
                              <div>
                                {body.user?.license_verified && (
                                  <div className="verified_tag">Verified</div>
                                )}
                                <div className="name">
                                  {body.user?.first_name}
                                </div>
                                <div className="rating d-flex">
                                  <ReactStars
                                    size={15}
                                    count={5}
                                    value={
                                      body.user?.rating.length === 0 ||
                                      body.user?.rating.rate__avg === null
                                        ? 0
                                        : body.user?.rating.rate__avg
                                    }
                                    edit={false}
                                    a11y={true}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={
                                      <i className="fas fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fas fa-star"></i>}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="dets">
                              <div className="price">
                                {symbol}
                                {body.price_to}
                                <sup>{body.pricing_type}</sup>
                              </div>
                              <div className="button_grid">
                                <button
                                  className="btn chat_btn"
                                  onClick={() => negotiation()}
                                >
                                  <i className="fas fa-comment-alt"></i>
                                  {t(
                                    "serviceCategory.serviceCategoryView.chat"
                                  )}
                                </button>
                                {extra.is_hired === true ||
                                extra.is_owner === true ? null : ( // permissions === "SERVICE PROVIDER" ? (
                                  <button
                                    className="btn cstm_btn"
                                    onClick={(e) => onHire(body.user.username)}
                                  >
                                    {t(
                                      "serviceCategory.serviceCategoryView.hire_now"
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 aos-init" data-aos="fade-up">
                          <h3>
                            <span>
                              {t(
                                "serviceCategory.serviceCategoryView.work_information"
                              )}
                            </span>
                          </h3>

                          <p>
                            {
                              // body.user
                              //   .profile_summary
                              body?.description?.length > 0
                                ? body?.description
                                : "No description added"
                            }
                          </p>
                          <h3>
                            <span>
                              {t(
                                "serviceCategory.serviceCategoryView.equipments"
                              )}
                            </span>
                          </h3>
                          <ol>
                            {body?.equipment?.map((body) => {
                              return (
                                <>
                                  {body.equipment.length > 0 ? (
                                    <li>{body.equipment}</li>
                                  ) : null}
                                </>
                              );
                            })}{" "}
                          </ol>
                        </div>

                        {/* <div
                                  className="col-md-12 aos-init"
                                  data-aos="fade-up"
                                >
                                  <h3>
                                    <span>
                                      {" "}
                                      {t(
                                        "serviceCategory.serviceCategoryView.service"
                                      )}
                                    </span>
                                  </h3>

                                  <div className="row service-relate">
                                    {body.service?.map((body, index) => (
                                      <div className="col-lg-2">
                                        <div
                                          className="text-center"
                                          key={index}
                                        >
                                          <div className="">
                                            <img width="100" height="auto"
                                              src={`https://cho.direct${body.logo}`}
                                              alt="dummy_image"
                                              className="max_w_70"
                                            />
                                            <h5 className="mb-0 mt-3">
                                              {body.title.name}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div> */}
                      </div>
                    </div>
                    {/* ); */}
                    {/* })
                      : null} */}
                  </div>
                  <div className="side_add_cont">
                    {adds.map((body) => {
                      return (
                        <>
                          <img
                            width=""
                            height=""
                            src={body.image}
                            alt="ads_image"
                          />
                        </>
                      );
                    })}
                  </div>
                  {token && reported === false ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {" "}
                          {t("serviceCategory.serviceCategoryView.report_tool")}
                        </Tooltip>
                      }
                    >
                      <span
                        className="report ml-auto float-right"
                        onClick={() => reportUser(userName)}
                      >
                        {" "}
                        {t("serviceCategory.serviceCategoryView.report_user")}
                      </span>
                    </OverlayTrigger>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}

      {chatModal && (
        <ChatDesign
          onClick={chatOff}
          chat={chatUser}
          roomId={roomId}
          currentUser={current_user}
          profileUser={profileData}
        />
      )}
      <LoginModal displayModal={show} closeModal={() => onCancel()} />
      <Abuse
        show={modalShow}
        onHide={onHide}
        abuse_modal={reportModal}
        setAbuse_modal={setReportModal}
        onAbuse={reportUserModal}
        username={userName}
      />
    </>
  );
};
export default ServiceCategory;
