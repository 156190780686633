import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TestTable from "../../../Components/table/testTable";
import axios from "axios";
import deleteImg from "../../../../assets/images/delete.svg";
import editImg from "../../../../assets/images/edit.svg";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import Loader from "../../../Components/loader/index";
import Modal from "../confirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";

const MyProduct = () => {
  const { t } = useTranslation("common");
  const token = localStorage.getItem("accessToken");
  // const [productId, setProductId] = useState();
  const [productdata, setProductData] = useState("");
  const [loading, setLoading] = useState(true);
  const [bodyData, setBodyData] = useState([]);
  const [show, setShow] = useState(false);
  const [bulkShow, setBulkShow] = useState(false);
  const getLang = localStorage.getItem("language");
  const handleGetData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?type=PRODUCT&&lang=${getLang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductData(res.data.data);
        // setProductId(res.data.data.id);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };
  useEffect(() => {
    handleGetData();
    if (productdata !== undefined && productdata.length > 0) {
      let bData = [];
      productdata.forEach((e) => {
        let result = {};
        let checked = {};
        result = e;
        e.checked = false;
        checked = e.checked;
        let data = { ...result, checked };
        bData.push(data);
        return null;
      });
      setBodyData(bData);
    }
    //eslint-disable-next-line
    console.log(bodyData, "proooooooooooooooooooooooo");
  }, [loading]);

  const history = useHistory();
  const [bulkDeleteBtn, setBulkDeleteBtn] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const isChecked = (id) => {
    setBodyData(() => {
      const data = bodyData.map((el) => {
        if (el.id === id && el.checked === false) {
          el.checked = true;
          return el;
        } else if (el.id === id && el.checked === true) {
          el.checked = false;
          return el;
        } else return el;
      });

      return data;
    });
    const deleteBulk = bodyData.some((el) => el.checked === true);
    setBulkDeleteBtn(deleteBulk);
  };
  const headingData = [
    {
      id: "0",
      Header: <span className="checkbox">{t("myproduct.header.header1")}</span>,
      accessor: (props) => (
        <input
          checked={props.checked}
          onClick={() => isChecked(props.id)}
          type="checkbox"
        />
      ),
    },
    {
      id: "1",
      Header: `${t("myproduct.header.header2")}`,
      accessor: (props) =>
        props.product_name.map((item) =>
          item.language.code === getLang ? (
            <span>
              {" "}
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ),
    },
    {
      id: "2",
      Header: `${t("myproduct.header.header3")}`,
      accessor: "status",
    },

    {
      id: "10",
      Header: <span className="checkbox">{t("myproduct.header.header4")}</span>,
      accessor: (props) => (
        <>
          <img
            width=""
            height=""
            src={editImg}
            alt="timeline"
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => handleEdit(props.id)}
          />
          <img
            width=""
            height=""
            src={deleteImg}
            alt="timeline"
            style={{
              width: "25px",
              height: "25px",
              cursor: "pointer",
              marginLeft: "30px",
            }}
            onClick={() => removeAccountHandler(props.id)}
          />
        </>
      ),
    },
  ];
  const removeAccountHandler = (id) => {
    setDeleteId(id);
    setShow(true);
  };
  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?id=${deleteId}&lang=${getLang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(`${t("errorMessages.success_deleted")}`);
        handleGetData();
        setShow(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };
  const handleEdit = (id) => {
    history.push(`/editproduct/${id}`);
  };
  const handleId = (productId) => {
    history.push(`/myproduct/${productId}`);
  };
  const handleAdd = () => {
    history.push({ pathname: "/addproducts" });
  };
  const [bulkDeleteId, setBulkDeleteId] = useState();
  const bulkDeletePop = (bodyData) => {
    const data = bodyData.filter((el) => el.checked === true);
    let bId = [];
    for (let i of data) {
      bId.push(i.id);
    }
    let bulk = bId.join(",");
    setBulkDeleteId(bulk);
    setBulkShow(true);
  };
  const bulkDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?id=${bulkDeleteId}&lang=${getLang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(`${t("errorMessages.success_deleted")}`);
        handleGetData();
        setBulkShow(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };

  const onHide = () => {
    setShow(false);
    setBulkShow(false);
    setBulkDeleteBtn(false);
  };
  return (
    <div className="myproduct">
      <div className={bulkDeleteBtn ? " handleGrid" : "headingWrap"}>
        <h3 className="title">{t("myproduct.title")}</h3>
        <div className="ml-auto">
          <button className="btn cstm_btn" onClick={handleAdd}>
            {t("myproduct.addButton")}
          </button>
        </div>
        {bulkDeleteBtn ? (
          <button
            className="btn btn-light"
            onClick={() => bulkDeletePop(bodyData)}
          >
            {t("myproduct.bulkDelete")}
          </button>
        ) : null}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {productdata && productdata.length > 0 ? (
            <div className="cursor_table">
              <TestTable
                headData={headingData}
                bodyData={productdata}
                rowPerPage="5"
                rowId={handleId}
                paginationStatus={true}
              />
            </div>
          ) : (
            <div className="not-found">
              <h2>{t("myproduct.notFound")}</h2>
            </div>
          )}
        </>
      )}

      <ToastContainer />

      <Modal
        displayModal={show}
        closeModal={onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <div>
                  <img
                    width=""
                    height=""
                    src={deleteImg}
                    alt=""
                    className="icon_wrap"
                  />
                </div>
                <h2 className="text-center">{t("deletemodal.title")}</h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={onHide}
                  >
                    {t("deletemodal.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleDelete}
                  >
                    {t("deletemodal.delete")}
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />

      <Modal
        displayModal={bulkShow}
        closeModal={onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <div>
                  <img
                    width=""
                    height=""
                    src={deleteImg}
                    alt=""
                    className="icon_wrap"
                  />
                </div>
                <h2>{t("deletemodal.title")}</h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={onHide}
                  >
                    {t("deletemodal.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={bulkDelete}
                  >
                    {t("deletemodal.delete")}
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default MyProduct;
