import React, { useEffect, useState } from "react";
import { CButton, CInput, CLabel, CSelect } from "@coreui/react";
import { useHistory } from "react-router";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import contries from "./coutry.json";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const ChangePhoneNumber = () => {
    const token = localStorage.getItem("accessToken");
    const { t } = useTranslation('common');
    const [userName, setUserName] = useState("");
    const lang = localStorage.getItem("langugae");
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setUserName(res.data.data.username);
      })
            .catch((err) => toast.error(err));
    }, [lang, token]);
    const history = useHistory();
    const initalValue = {
        name: "",
        phone: "",
        country_code: "",
        password: "",
        role: "",
        otp: "",
        cpassword: "",
        email: "",
    };
    const [User, setUser] = useState(initalValue);
    const [current_value, setCurrent_value] = useState("Phone");

    const [formErrors, setFormErrors] = useState({});

    const [fixed, setFixed] = useState(false);

    const prev = () => {
        setCurrent_value("Phone");
    };

    const valid = (user) => {
        let errors = {};
        if (!user.phone) {
            errors.phone = `${t("errorMessages.required_field")}`;
    }

        return errors;
    };
    const otp = async () => {
        setFormErrors(valid(User));

      let data;

      data = {};
      data.username = userName;
      data.email_or_phone = User.phone.replace(/\s/g, "");
      data.phone_code = User.country_code;
      data.verification_type = "sms";
      data.lang = lang;

      if (Object.keys(valid(User)).length === 0) {
          await axios
              .post(
                  `${process.env.REACT_APP_BASE_URL}auth/get-phone-otp/?lang=${lang}`,
                  data,
                  {
                  headers: {
                      Authorization: `Bearer ${token}`,
            },
                  }
              )
          .then((res) => {
              if (res.status === 200) {
                  setCurrent_value("Otp");
                  toast.success(`${t("errorMessages.signup.verification_success")}`);
              }
        })
                .catch((err) => {
                    if (err.response !== undefined) {
                        for (let i of err.response.data.message) {
                            toast.error(`${i.error}`);
                        }
                    } else {
                        toast.error("an error occured");
                    }
                });
        }
    };
    const verify = async () => {
        let data;
        if (User.email) {
            data = {};
            data.otp = User.otp;
            data.email_or_phone = User.email;
            data.page = "signup";
            data.verification_type = "email";
            data.username = User.username;
    }

      if (User.phone) {
          data = {};
          data.otp = User.otp.replace(/\s/g, "");
          data.page = "signup";
          data.email_or_phone = User.phone;
          data.phone_code = User.country_code;
          data.verification_type = "sms";
          data.username = User.username;
    }

      await axios
          .post(
              `${process.env.REACT_APP_BASE_URL}auth/verify-otp/?lang=${lang}`,
              data
          )
          .then((res) => {
              if (res.status === 200) {
                  axios
                      .post(
                          `${process.env.REACT_APP_BASE_URL}auth/updatephonenumber/?lang=${lang}`,
                          {
                              email_or_phone: User.phone,
                              country_code: User.country_code,
                              verification_type: "sms",
                              username: userName,
                    token: res.data.token,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
              }
          )
              .then((res) => {
                  toast.success(`${t("errorMessages.success")}`);
                  history.push("/profile");
            });
              }
      })
            .catch((err) => {
                if (err.response !== undefined) {
                    for (let i of err.response.data.message) {
                        toast.error(`${i.error}`);
                    }
                } else {
                    toast.error("an error occured");
                }
            });
    };
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUser({ ...User, [name]: value });
    };
    const validate = (values) => {
        let errors = {};

        //   const regex =
        //       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      if (!values.password) {
          errors.password = `${t("errorMessages.required_field")}`;
    } else if (!regex.test(values.password)) {
          errors.password = `${t("errorMessages.password.password_validation")}`;
      }
      if (!values.cpassword) {
          errors.cpassword = `${t("errorMessages.required_field")}`;
      } else if (values.password !== values.cpassword) {
        errors.cpassword = `${t("errorMessages.password.password_match")}`;
    }

      return errors;
  };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormErrors(validate(User));

      let data;
      if (User.email) {
          data = {};

        data.username = User.name;
        data.email_or_phone = User.email;
        data.password = User.password;
        data.role = User.role;
        data.token = token;
        if (User.role === "CUSTOMER") {
            data.permissions = "BUYER";
        } else {
            data.permissions = "SERVICE PROVIDER";
        }
    }

      if (User.phone) {
          data = {};

        data.email_or_phone = User.phone;
        data.country_code = User.country_code;
        data.username = User.username;
        data.password = User.password;
        data.role = User.role;
        data.token = token;
        if (User.role === "CUSTOMER") {
            data.permissions = "BUYER";
        } else {
            data.permissions = "SERVICE PROVIDER";
        }
    }

      if (Object.keys(validate(User)).length === 0) {
          await axios
              .post(
                  `${process.env.REACT_APP_BASE_URL}auth/register/?lang=${lang}`,
                  data
              )
              .then((res) => {
                  if (res.status === 201) {
                      toast.success("you are registered");
                      setUser({
                          name: "",

                password: "",
                cpassword: "",
            });
                      localStorage.setItem("accessToken", res.data.data.token);
                      localStorage.setItem("permissions", res.data.data.permissions[0]);
                      // history.push({ pathname: "/dashboard" });
                      let productType = localStorage.getItem(
                          "productType",
                          "productType"
                      );
                      if (productType === "RANGE") {
                          history.push({ pathname: "/create-offer" });
                      } else if (productType === "FIXED") {
                          setFixed(true);
                          localStorage.setItem("fixed", fixed);
                          history.push({ pathname: "/Checkout" });
                      } else {
                          history.push({ pathname: "/profile" });
                      }
                  }
        })
              .catch((err) => toast.error(err));
      }
  };

    return (
        <div>
            <div>
                <div className="headingWrap">
                    <h3 className="title">{t("Change_phoneNumber.title")}</h3>
                </div>

              <form
                  action="/action_page.php"
                  id="form"
                  onSubmit={handleSubmit}
                  className="dash_edit_card"
              >
                  {current_value === "Phone" && (
                        <div>
                            <div className="form-group">
                              {
                                    <div>
                                      <div className="country_select">
                                          <CSelect
                                              className="select"
                                              name="country_code"
                                              id="country_code"
                                                onChange={handleChange}
                                          >
                                              {" "}
                                              {contries.map((e) => (
                                                  <option value={e.dial_code}>
                                                      {e.name}({e.dial_code})
                                                  </option>
                                              ))}
                                          </CSelect>
                                          <CInput
                                              name="phone"
                                              id="phone"
                                              value={User.phone}
                                              onChange={handleChange}
                                              className="input"
                                              placeholder="999 888 7774"
                                          />{" "}
                                            {formErrors.phone && (
                                                <span className="error">{formErrors.phone}</span>
                                            )}
                                      </div>

                                      <div className="form-group float-right">
                                          <CButton onClick={otp} className="cstm_btn otp">
                                              {t("Change_phoneNumber.buttonText")}
                                            </CButton>
                                      </div>
                                  </div>
                              }
                          </div>{" "}
                      </div>
                  )}
                  {current_value === "Otp" && (
                      <div className="form-group">
                            {" "}
                            <CLabel htmlFor="name">{t("SignupForm.otp.code")}</CLabel>
                          <CInput
                              name="otp"
                                id="otp"
                              onChange={handleChange}
                              className="form-control"
                              placeholder="------"
                          />{" "}
                          <CButton className="next_btn" onClick={verify}>
                              {t("SignupForm.next")}
                          </CButton>
                          <CButton className="next_btn" onClick={prev}>
                              {t("SignupForm.prev")}
                          </CButton>
                          <CButton className="resend_btn" onClick={otp}>
                              {t("SignupForm.resend")}
                          </CButton>
                      </div>
                  )}
                  <ToastContainer />
              </form>
          </div>
      </div>
  );
};

const mapStateToProps = (state) => ({
    user: state.username.user_name,
});

export default connect(mapStateToProps)(ChangePhoneNumber);
