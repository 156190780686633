import React, { useState, useEffect } from "react";
import axios from "axios";
import Search from "./searchbox";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMapMarkerAlt,
	faPhoneAlt,
	faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import SimpleMap from "./map";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import useForm from "./validation/useForm";
import { CInput, CLabel } from "@coreui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
library.add(faMapMarkerAlt);
library.add(faPhoneAlt, faEnvelope);

const Contactus = () => {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  let getLang = localStorage.getItem("language");
  // const recaptchaRef = React.createRef();
  const [captchaValue, setCaptchaValue] = useState("");
  const [address, setAddress] = useState([]);

  let adrrText = [];
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=Contact us&lang=${getLang}`
      )
      .then((res) => {
        setData(res.data.data);
        for (let k = 0; k < res.data.data.length; k++) {
          if (res.data.data[k].language.code === getLang) {
            for (let i = 0; i < res.data.data[k].section.length; i++) {
              if (res.data.data[k].section[i].name === "contact_us_address") {
                const newText = res.data.data[k].section[i].title
                  .split(",")
                  .map((item) => <p>{item.trim()}</p>);

                for (let i = 0; i < newText.length; i++) {
                  adrrText.push(newText[i].props.children);
                }
              }
            }
          }
        }

        setAddress(adrrText);

        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    //eslint-disable-next-line
  }, [loading, getLang]);

  const initialState = {
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    message: { value: "", error: "" },
  };
  const stateValidatorSchema = {
    name: {
      required: true,
      validator: {
        //eslint-disable-next-line
        func: (value) => /^[a-zA-Z]/.test(value),
        error: `${t("errorMessages.required_field")}`,
      },
    },
    email: {
      required: true,
      validator: {
        func: (value) =>
          //eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          ),
        error: `${t("errorMessages.email_validation")}`,
      },
    },
    message: {
      required: true,
      validator: {
        func: (value) => /^[a-zA-Z]/.test(value),
        error: `${t("errorMessages.required_field")}`,
      },
    },
  };
  const onSubmitForm = (state) => {
    let payload = {
      name: state.name,
      email: state.email,
      message: state.message,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}website/contact/?lang=${getLang}`,
        payload
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(`${t("errorMessages.contact_success")}`);
          document.getElementById("form").reset();
          setCaptchaValue("");
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        } else {
          toast.error("an error occured");
        }
      });
  };
  const { errors, dirty, handleChange, handleSubmit, disable } = useForm(
    initialState,
    stateValidatorSchema,
    onSubmitForm
  );

  function onChange(value) {
    setCaptchaValue(value);
  }
  // function NewlineText(props) {
  //   const text = props.text;
  //   const newText = text.split(",").map((item) => <p>{item.trim()}</p>);
  //   let adrrText = [];
  //   for (let i = 0; i < newText.length; i++) {
  //     adrrText.push(newText[i].props.children);
  //   }

  //   setAddress(adrrText);
  //   return adrrText;
  // }

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="cstm_header contact_header">
        <Search />
      </div>
      <div className="contact_cont">
        <div className="container">
          <div className="row">
            <div className="col-lg-5" data-aos="fade-up">
              <div className="contact_info">
                {data.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.language.code === getLang
                        ? item.section.map((el, index) => {
                            return el.name === "contact_us_header_section" ? (
                              <div key={index}>
                                <h2 className="heading">{el.title}</h2>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: `${el.description}`,
                                  }}
                                ></p>
                              </div>
                            ) : null;
                          })
                        : null}
                    </div>
                  );
                })}
                <ul>
                  <div className="d-flex">
                    <span className="icon">
                      <FontAwesomeIcon icon="map-marker-alt" />
                    </span>
                    <div>
                      {address.map((item, index) => {
                        return <li key={index}>{item}</li>;
                      })}
                    </div>
                  </div>
                </ul>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="map">
                      <SimpleMap />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1" data-aos="fade-up">
              <div className="contact_form">
                <form
                  action="file.php"
                  id="form"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="form-group">
                    <CLabel htmlFor="name">{t("ContactForm.label1")}*</CLabel>
                    <CInput
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="form-control"
                      placeholder={t("ContactForm.placeholder_name")}
                      onChange={handleChange}
                    />
                    {errors.name && dirty.name && (
                      <p className="error">{errors.name}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="email">{t("ContactForm.label2")}*</CLabel>
                    <CInput
                      type="email"
                      name="email"
                      id="email"
                      required
                      className="form-control"
                      placeholder={t("ContactForm.placeholder_email")}
                      onChange={handleChange}
                    />
                    {errors.email && dirty.email && (
                      <p className="error">{errors.email}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <CLabel htmlFor="message">{t("ContactForm.label3")}</CLabel>
                    <textarea
                      name="message"
                      id="message"
                      required
                      className="form-control"
                      placeholder={t("ContactForm.label3")}
                      rows="6"
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && dirty.message && (
                      <p className="error">{errors.message}</p>
                    )}
                  </div>

                  <ReCAPTCHA
                    sitekey="6Lep0-IbAAAAADomJwKXZtUiBXxm2JrzVgZjc_Yh"
                    id="recaptchaValue"
                    name="recaptchaValue"
                    onChange={onChange}
                  />
                  <div className="form-group">
                    {captchaValue.length === 0 ? (
                      <button
                        htmltype="submit"
                        type="submit"
                        className="btn cstm_btn"
                        disabled
                      >
                        {t("ContactForm.buttonText")}
                      </button>
                    ) : (
                      <button
                        htmltype="submit"
                        type="submit"
                        className="btn cstm_btn"
                        disabled={disable}
                      >
                        {t("ContactForm.buttonText")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Contactus;
