import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouterComp from "./Router";
import "./scss/style.scss";
import "./style.css";
import "./views/pages/dashboard/responsive.css";
import { socket } from "./socket";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Auth from "./Auth";
import { toast } from "react-toastify";
function App() {
  const Loader = () => (
    <div className="App">
      <div>loading...</div>
    </div>
  );
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [userOnline, setUserOnline] = useState([]);
  let token = localStorage.getItem("accessToken");
  const userName = localStorage.getItem("username");
  const getLang = localStorage.getItem("language");
  const { i18n } = useTranslation("common");
  useEffect(() => {
    if (getLang === null) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}get-lang/`, {})
        .then((res) => {
          localStorage.setItem("language", res.data.data.code);
          i18n.changeLanguage(res.data.data.code);
          setLoading(false);
        })
        .catch((err) => toast.error(err));
    }
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}auth/profile/`, {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfileData(res.data.data);
          setLoading(false);
        })
        .catch((err) => toast.error(err));
    }
    socket.connect();
    socket.on("error", function (err) {});
    socket.on("connect_error", function (err) {});
    //eslint-disable-next-line
  }, [socket, loading]);

  useEffect(() => {
    if (
      Object.keys(profileData).length > 0 &&
      profileData !== undefined &&
      profileData !== null
    ) {
      let connectdata = {
        user: {
          user_id: profileData.id.toString(),
          email: profileData.username,
          name: profileData.first_name,
        },
      };
      socket.emit("connected", connectdata);
    }
  }, [profileData]);

  function clearStorage() {
    let session = sessionStorage.getItem("ref");
    if (session == null) {
      Auth.signout();
      sessionStorage.clear();
      localStorage.clear();
      // socket.on("user_offline_show", (data) => {
      //   setUserOnline(data);
      // });
    }
    axios
      .get(`${process.env.REACT_APP_BASE_URL}get-lang/`, {
        headers: {
          'Access-Control-Allow-Origin': '*,https://www.cho.direct/',
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        localStorage.setItem("language", res.data.data.code);
        i18n.changeLanguage(res.data.data.code);
        setLoading(false);
      })
      .catch((err) => toast.error(err));
    sessionStorage.setItem("ref", 1);
  }
  window.addEventListener("load", clearStorage);

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Router>
          <RouterComp />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
