import React from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CCol, CLabel, CRow, CTextarea } from "@coreui/react";
import Modal from "../confirmModal/ConfirmModal";
import deleteImg from "../../../../assets/images/delete.svg";
import useForm from "../validation/useForm";
import { useTranslation } from "react-i18next";
const Abuse = (props) => {
  const { t } = useTranslation("common");
  let token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  const initialState = {
    abuse_reason: { value: "", error: "" },
  };
  const stateValidatorSchema = {
    abuse_reason: {
      required: true,
      validator: {
        func: (value) => /^[a-zA-Z]/.test(value),
        error: `${t("errorMessages.required_field")}`,
      },
    },
  };
  const onSubmitForm = (state) => {
    let payload = {
      username: props.username,
      message: state.abuse_reason,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}auth/report/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${t("errorMessages.report_success")}`);
          props.setAbuse_modal(false);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        } else {
          toast.error("an error occured");
        }
      });
  };
  const { errors, dirty, handleChange, handleSubmit, disable } = useForm(
    initialState,
    stateValidatorSchema,
    onSubmitForm
  );

  return (
    <>
      <Modal
        displayModal={props.show}
        closeModal={props.onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <div>
                  <img
                    width=""
                    height=""
                    src={deleteImg}
                    alt=""
                    className="icon_wrap"
                  />
                </div>
                <h2 className="text-center"> {t("abuseForm.title")}</h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={props.onHide}
                  >
                    {t("abuseForm.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={props.onAbuse}
                  >
                    {t("abuseForm.report")}
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
      <Modal
        displayModal={props.abuse_modal}
        closeModal={props.onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <form id="form" onSubmit={handleSubmit}>
                  <CRow>
                    <CCol md={12}>
                      <CLabel className="product-label" htmlFor="phone">
                        {t("abuseForm.abuse_reason")}
                      </CLabel>
                      <CTextarea
                        style={{ width: "100%", height: "100%" }}
                        rows="7"
                        onChange={handleChange}
                        name="abuse_reason"
                        id="abuse_reason"
                      ></CTextarea>
                      {errors.abuse_reason && dirty.abuse_reason && (
                        <p className="error">{errors.abuse_reason}</p>
                      )}
                    </CCol>
                  </CRow>

                  <div
                    className="modal-button"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "100px",
                    }}
                  >
                    <button
                      htmltype="submit"
                      type="submit"
                      className="btn btn-primary"
                      disabled={disable}
                    >
                      {t("abuseForm.submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
export default Abuse;