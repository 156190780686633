import { useState, useEffect } from "react";
import {
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CDropdown,
} from "@coreui/react";
import { useHistory } from "react-router";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Loader from "../../Components/loader/index";
import deleteImg from "../../../assets/images/delete.svg";
import { useTranslation } from "react-i18next";
import Modal from "./confirmModal/ConfirmModal";
import ReactPaginate from "react-paginate";

const Dashboard = (props) => {
  const { t } = useTranslation("common");
  let history = useHistory();
  let token = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [notTitle, setNotTitle] = useState([]);
  let pageNumber = [];
  const [notificationModal, setNotificationModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const lang = localStorage.getItem("language");
  let paypalAccount = localStorage.getItem("paypalAccount");
  const userName = localStorage.getItem("username");
  const [show, setShow] = useState(false);
  const [deletenotificationId, setDeletenotificationId] = useState("");
  const [allDeleteNotification, setAllDeleteNotification] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  let itemsPerPage = 10;

  //   let permissions = localStorage.getItem("permissions");
  const formatDate = (date) =>
    `${new Date(date).toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
    })} `;

  const apiCall = {
    action: "all_notification",
    request_id: userName,
    token: token,
    close: false,
  };
  useEffect(() => {
    const ws = new WebSocket("wss://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(apiCall));
    };
    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      try {
        if ((json.event = "data")) {
          setNotTitle(json.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    //clean up function
    return () => ws.close();
  }, []);
  useEffect(() => {
    const ws = new WebSocket("wss://cho.direct/api/v1/notification/update/");
    const apiCall = {
      action: "subscribe_to_notification_activity",
      request_id: userName,
      token: token,
      // close: false,
    };
    ws.onopen = (event) => {
      ws.send(JSON.stringify(apiCall));
    };
    ws.onmessage = function (event) {
      const json = JSON.parse(event.data);
      try {
        if ((json.event = "data")) {
          setNotTitle(json.data);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    //clean up function
    return () => ws.close();
    //eslint-disable-next-line
    //eslint-disable-next-line
  }, []);
  const getData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}event/notification/?lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setNotTitle(res.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("not found");
      });
    if (notTitle) {
      for (let i = 1; i <= notTitle.number_of_pages; i++) {
        pageNumber.push({ number: i });
      }
      // setPageNumbers(pageNumber);
      // setCurrentPage(Number(notTitle.current_page));
    }
  };
  const updateNotification = () => {
    const payload = {};
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}event/notification/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onHide = () => {
    setShow(false);
  };
  const deleteNotification = (id) => {
    setShow(true);
    setDeletenotificationId(id);
  };
  const handleDelete = (id) => {
    const deleteNotification = {
      action: "remove_by_id_notification",
      request_id: userName,
      token: token,
      close: false,
      id: deletenotificationId,
    };
    const ws = new WebSocket("ws://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(deleteNotification));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setNotTitle(json.data);
            setShow(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
  };
  const deleteAllNotification = (id) => {
    setAllDeleteNotification(true);
    setShow(true);
    setDeletenotificationId(id);
  };
  const handleDeleted = () => {
    const deleteNotification = {
      action: "remove_all_notification",
      request_id: userName,
      token: token,
      close: false,
    };
    const ws = new WebSocket("ws://https://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(deleteNotification));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setNotTitle(json.data);
            setShow(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
  };
  const handlePage = (value) => {

    updateNotificationById(value);
    if (
      (value.type === "OFFER" && value.data.user_type === "FARMER") ||
      (value.type === "OFFER" && value.data.user_type === "BUYER")
    ) {
      history.push({ pathname: `/offer` });
    } else if (value.type === "ORDER" && value.data.user_type === "BUYER") {
      history.push({ pathname: `/order-b/${value.link}` });
    } else if (value.type === "ORDER" && value.data.user_type === "FARMER") {
      history.push({ pathname: `/order-s/${value.link}` });
    } else if (
      value.type === "OFFER" &&
      value.data.user_type === "SERVICEPROVIDER"
    ) {
      history.push({ pathname: `/offer` });
    } else if (value.type === "BACKOUT" && value.data.user_type === "BUYER") {
      history.push({ pathname: `/order-b/${value.link}` });
    } else if (value.type === "SHOP" && value.data.user_type === "FARMER") {
      history.push({ pathname: `/order-b/${value.link}` });
    }
  };
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    // setCurrentItems(notTitle.slice(itemOffset, endOffset));
    setCurrentItems(notTitle);
    setPageCount(Math.ceil(notTitle.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, notTitle]);
  
  const updateNotificationById = (value) => {
    const notificationUpdate = {
      action: "mark_as_read_notification",
      request_id: userName,
      token: token,
      close: false,
      id: value.id,
    };
    const ws = new WebSocket("ws://cho.direct/api/v1/notifications/");
    ws.onopen = (event) => {
      ws.send(JSON.stringify(notificationUpdate));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        try {
          if ((json.event = "data")) {
            setNotTitle(json.data);
            // setLoading(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
    };
  };

  const onCancel = () => {
    setNotificationModal(false);
  };
  useEffect(() => {
    if (paypalAccount === "false") {
      setNotificationModal(true);
    }
  }, [paypalAccount]);
  const onAddAccount = () => {
    history.push("./bankDetail");
  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % notTitle.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="scroll_div">
          {/* <h5>Dashboard</h5> */}
          <div className="dashboard-page">
            <div className="headingWrap">
              <h3 className="title">
                {/* Notification */}
                {t("dashboard.notification.title")}
              </h3>
              <div className="ml-auto">
                <CDropdown
                  // inNav
                  className="c-header-nav-item mx-2"
                >
                  <CDropdownToggle className="c-header-nav-link" caret={false}>
                    <MoreHorizIcon className="icon-size" />
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={deleteAllNotification}>
                      {/* Clear all */} {t("dashboard.notification.clear")}
                    </CDropdownItem>
                    <CDropdownItem onClick={updateNotification}>
                      {/* Mark all as read */}{" "}
                      {t("dashboard.notification.markasread")}
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>
            </div>
            <ul className="notification_ul">
              {currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <div className="notification_grid">
                    <li
                      className={item.read === false ? "bold" : "normal"}
                      onClick={() => handlePage(item)}
                    >
                      {item.title}

                      <span className="float-right ml-4">
                        {formatDate(item.data.updated)}
                        {/* {Moment(item.data.updated).format("DD MMM hh:mm A")} */}
                      </span>
                    </li>
                    {/* <div className="ml-auto">
                      {" "}
                      <img
                        width=""
                        height=""
                        src={deleteImg}
                        alt="timeline"
                        className="float-right"
                        style={{
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          marginLeft: "30px",
                        }}
                        onClick={() => deleteNotification(item.id)}
                      />
                    </div> */}
                  </div>
                ))
              ) : (
                <p className="text-center m-auto">
                  {" "}
                  {t("dashboard.notification.no_notification")}
                </p>
              )}
            </ul>

            {/* </div> */}

            <div className="card-footer">
              <>
                {pageCount > 1 ? (
                  <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                ) : null}
              </>
            </div>
          </div>
          <ToastContainer />
          <Modal
            displayModal={notificationModal}
            closeModal={onCancel}
            modalBody={
              <>
                <div className="modal-body">
                  <div className="modalcontent">
                    <h2 className="text-center">
                      {" "}
                      {t("dashboard.modal.title")}
                    </h2>
                    <div className="modal-button">
                      <button
                        type="submit"
                        className="btn btn-primary m-auto"
                        onClick={onAddAccount}
                      >
                        {t("dashboard.modal.buttonText")}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}{" "}
      <Modal
        displayModal={show}
        closeModal={onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <div>
                  <img
                    width=""
                    height=""
                    src={deleteImg}
                    alt=""
                    className="icon_wrap"
                  />
                </div>
                <h2>Are you sure you want to delete</h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={onHide}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={
                      allDeleteNotification ? handleDeleted : handleDelete
                    }
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Dashboard;
