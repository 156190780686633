import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { CButton, CInput, CLabel } from "@coreui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Loader from "../../../Components/loader/index";
import ImageGallery from "react-image-gallery";
import NoImage from "../../../../assets/images/no-image.jpg";
import { offer_state } from "src/redux/actions/Productactions";
import Modal from "../confirmModal/ConfirmModal";
import Search from "../searchbox";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import dummyProfile from "../../../../assets/images/profile.jpeg";
import ReactStars from "react-rating-stars-component";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Abuse from "../Abuse/abuse";
import ChatDesign from "../chat/chatDesign";
import { socket } from "../../../../socket";
import { useTranslation } from "react-i18next";
import jQuery from "jquery";

const ProductDetail = (props) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const [productimg, setProductimg] = useState([
    {
      original: NoImage,
      thumbnail: NoImage,
    },
  ]);
  const [productname, setProductname] = useState([]);
  const [productdes, setProductDes] = useState("");
  const [productdata, setProductdata] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [symbol, setSymbol] = useState("$");
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [offerModalShow, setOfferModalShow] = useState(false);
  let token = localStorage.getItem("accessToken");
  const [perQuantityPrice, setPerQuantityPrice] = useState("");
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [currentUser, setCurrentUser] = useState(false);
  const [userName, setUserName] = useState();
  const [reportModal, setReportModal] = useState(false);
  const [reported, setReported] = useState();
  let k = window.location.href.split("/").length - 1;
  let id = window.location.href.split("/")[k];
  const [chatModal, setChatModal] = useState(false);
  const [offerData, setOfferData] = useState({
    earnest_amount: "",
    final_amount: "",
    currency: "",
    quantity: "",
    unit: "",
  });
  const lang = localStorage.getItem("language");
  const [adsRight, setAdsRight] = useState([]);
  const [current_user, setCurrent_user] = useState([]);
  const [participantsName, setParticipantsName] = useState([]);
  const [chatUser, setChatUser] = useState({});
  const [roomId, setRoomId] = useState("");

  jQuery(".open_popup_login").click((e) => {
    jQuery(".my_test").click();
  });

  useEffect(() => {
    socket.connect();
    //eslint-disable-next-line
  }, [socket, lang]);
  let arr = [];
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/product/?id=${id}&extra_limit=6&lang=${lang}`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductdata(res.data.data);
        setCurrentUser(res.data.data.current_user_product);
        setRelatedProduct(res.data.extra);
        setProductname(res.data.data.product_name);
        setProductDes(res.data.data.product_name[0].description);
        setUserName(res.data.data.user.username);
        setLoading(false);
        dispatch(offer_state(false));
      })
      .catch((err) => console.log(err));
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${lang}`, {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfileData(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    if (productdata !== -1 && productdata) {
      if (productdata.image.length === 0) {
        arr.push({
          original: NoImage,
          thumbnail: NoImage,
        });

        setLoading(false);
      } else {
        for (let i = 0; i < productdata.image.length; i++) {
          arr.push({
            original: productdata.image[i],
            thumbnail: productdata.image[i],
          });
          setLoading(false);
        }
      }
      setProductimg(arr);
      if (productdata.price_currency === "DONG") {
        setSymbol("₫");
      }
    }
    if (productdata.pricing_type === "FIXED") {
      setPerQuantityPrice(productdata.price_to);
    }
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}auth/report/?username=${userName}&lang=${lang}`,
          {
            headers: {
              Authorization: ` Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setReported(res.data.data.is_reported);
        })
        .catch((err) => console.log(err));
    }
    //eslint-disable-next-line
  }, [
    loading,
    lang,
    token,
    setProductdata,
    setCurrentUser,
    setPerQuantityPrice,
    setProductimg,
  ]);

  const [data, setData] = useState({
    quantity: "",
  });
  let quantityPrice;
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };
  const [formErrors, setFormErrors] = useState({});
  const validate = (values) => {
    let errors = {};
    console.log(Number(values.quantity) !== 0, "quantity");
    if (!values.quantity) {
      errors.quantity = "This field is required";
    }
    if (
      Number(values.quantity) > Number(productdata.total_quantity) ||
      Number(values.quantity) === 0
    ) {
      errors.quantity = "This Quanitity is not available.";
    }

    return errors;
  };

  const handleOffer = () => {
    localStorage.setItem("productCurrency", productdata.price_currency);
    localStorage.setItem("productId", productdata.id);
    localStorage.setItem("totalQuantity", productdata.total_quantity);
    localStorage.setItem("productType", productdata.pricing_type);
    localStorage.setItem("productUnit", productdata.total_quantity_unit);
    if (!token) {
      setShow(true);
    } else {
      setOfferModalShow(true);
    }
  };

  const handleBuy = () => {
    setFormErrors(validate(data));
    localStorage.setItem("productId", productdata.id);
    localStorage.setItem("productCurrency", productdata.price_currency);
    localStorage.setItem("totalQuantity", productdata.total_quantity);
    localStorage.setItem("productType", productdata.pricing_type);
    localStorage.setItem("productUnit", productdata.total_quantity_unit);
    if (Object.keys(validate(data)).length === 0) {
      localStorage.setItem("productQuantity", data.quantity);
      quantityPrice = data.quantity * perQuantityPrice;
      localStorage.setItem("quantityPrice", quantityPrice);
      if (!token) {
        setShow(true);
      } else {
        history.push({ pathname: "/Checkout" });
      }
    }
  };
  const onCancel = () => {
    setShow(false);
    setFormErrors({});
    setOfferModalShow(false);
  };
  const relatedView = (id) => {
    history.push(`/products/${id}`);
    window.location.href = `/products/${id}`;
  };
  const pagination = (value) => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }inventory/product/?id=${id}&extra_page=${
          value + 1
        }&extra_limit=6&lang=${lang}`,
        {}
      )
      .then((res) => {
        setRelatedProduct(res.data.extra);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        // toast.error(err.response.data)
      });
  };
  const previouspagination = (value) => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }inventory/product/?id=${id}&extra_page=${
          value - 1
        }&extra_limit=6&lang=${lang}`,
        {}
      )
      .then((res) => {
        setRelatedProduct(res.data.extra);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setOfferData({ ...offerData, [name]: value });
  };
  let product_id = localStorage.getItem("productId");
  let product_unit = localStorage.getItem("productUnit");
  let product_currency = localStorage.getItem("productCurrency");
  let totalQuantity = localStorage.getItem("totalQuantity");

  const validateOffer = (values) => {
    let errors = {};
    if (!values.earnest_amount) {
      errors.earnest_amount = "This field is required";
    }
    if (!values.final_amount) {
      errors.final_amount = "This field is required";
    }

    if (!values.quantity) {
      errors.quantity = "This field is required";
    }
    if (
      Number(values.quantity) > Number(productdata.total_quantity) ||
      Number(values.quantity) === 0
    ) {
      errors.quantity =
        "This Quanitity is not available.Please enter less quantity";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    setFormErrors(validateOffer(offerData));

    event.preventDefault();
    const payload = {
      product: Number(product_id),
      earnest_amount: Number(offerData.earnest_amount),
      final_amount: Number(offerData.final_amount),
      currency: product_currency,
      quantity: Number(offerData.quantity),
      unit: product_unit,
      type: "PRODUCT",
    };

    let token = localStorage.getItem("accessToken");
    if (Object.keys(validateOffer(offerData)).length === 0) {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}payment/offer-create/?lang=${lang}`,
          payload,
          {
            headers: {
              Authorization: ` Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Succesfully added");
            history.push({ pathname: "/offer" });

            setOfferData({
              earnest_amount: "",
              final_amount: "",
              currency: "",
              quantity: "",
              unit: "",
            });
            document.getElementById("form").reset();
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            for (let i of err.response.data.message) {
              toast.error(`${i.error}`);
            }
          }
        });
    }
  };
  const onWheel = (event) => {
    event.currentTarget.blur();
  };
  const onHide = () => {
    setReportModal(false);
    setModalShow(false);
  };
  const reportUser = (value) => {
    setUserName(value);
    setModalShow(true);
  };
  const reportUserModal = () => {
    setModalShow(false);
    setReportModal(true);
  };
  const chatOff = () => {
    setChatModal(false);
  };
  const negotiation = () => {
    if (!token) {
      setShow(true);
    } else {
      let data = {
        name: productdata.user.username,
        participants: [
          {
            user_id: productdata.user.id.toString(),
            email: productdata.user.username,
            name: productdata.user.first_name,
            profile_image: productdata.user.profile_image,
          },
        ],
        user: {
          user_id: profileData.id.toString(),
          email: profileData.username,
          name: profileData.first_name,
          profile_image: profileData.profile_image,
        },
        is_group: false,
      };

      socket.emit("new_room_create", data);
      socket.on("add_room", (resp) => {
        setCurrent_user(resp.channel.creator_id);
        setParticipantsName(resp.channel.participants);
        setRoomId(resp.channel.id);
        setLoading(false);
      });
      setChatModal(true);
    }
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}adminpanel/get-advertisement/?title=Product&language=${lang}&position=Right`
      )
      .then((res) => {
        setAdsRight(res.data.data);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    if (participantsName !== undefined && participantsName.length > 0) {
      for (let j = 0; j < participantsName.length; j++) {
        if (participantsName[j].user_id === productdata.user.id.toString()) {
          setChatUser(participantsName[j]);
        }
      }
    }
    //eslint-disable-next-line
  }, [participantsName, current_user, roomId, profileData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="cstm_header product_details_header">
            <Search />
          </div>
          <div className="container ">
            <div className="row">
              <div className="col-md-7 height_sticky">
                <ImageGallery
                  items={productimg}
                  thumbnailPosition="left"
                  showPlayButton={false}
                  disableKeyLeft={false}
                />
              </div>
              <div className="col-md-5">
                <div className="border-bottom">
                  {productname.map((item) =>
                    item.language.code === lang ? <h2>{item.name}</h2> : null
                  )}
                  <p>{productdes}</p>
                </div>
                <div className="border-bottom">
                  <ul className="mt-2">
                    <li className="a-span3">
                      {/* <span className="font-weight-bold">Price:</span>{" "}
                  <span className="price">$79</span>{" "} */}
                      <span className="font-weight-bold">
                        {productdata.pricing_type === "FIXED" ? (
                          <>
                            {t("productView.priceFixed")}:
                            <span className="price">
                              {" "}
                              {symbol} {productdata.price_to}
                              <sup>{productdata.total_quantity_unit}</sup>
                            </span>
                          </>
                        ) : (
                          <>
                            {t("productView.priceRange")}:
                            <span className="price">
                              {" "}
                              {symbol} {productdata.price_from}
                              <sup>{productdata.total_quantity_unit}</sup>-{" "}
                              {symbol} {productdata.price_to}
                              <sup>{productdata.total_quantity_unit}</sup>
                            </span>
                          </>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="border-bottom">
                  <table className="a-normal a-spacing-micro mt-2">
                    <tbody>
                      <tr className="a-spacing-small po-digital_storage_capacity">
                        <td className="a-span3">
                          <span className="a-size-base font-weight-bold">
                            {t("productView.sizeFarm")}:
                          </span>{" "}
                        </td>{" "}
                        <td className="a-span9">
                          <span className="a-size-base">
                            {" "}
                            {productdata.area} {productdata.area_unit}
                          </span>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr className="a-spacing-small po-compatible_devices">
                        <td className="a-span3">
                          <span className="a-size-base font-weight-bold">
                            {t("productView.weight")}
                          </span>{" "}
                        </td>{" "}
                        <td className="a-span9">
                          <span className="a-size-base">
                            {" "}
                            {productdata.total_quantity}{" "}
                            {productdata.total_quantity_unit}
                          </span>{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>
                  </table>
                </div>
                <div className="border-bottom">
                  <div className="quality_form_outer">
                    {currentUser === false && (
                      <>
                        {productdata.pricing_type === "FIXED" && (
                          <form className="quality_form" id="form" noValidate>
                            <label className=" font-weight-bold">
                              Quantity:{" "}
                            </label>
                            <div className="d-flex align-items-center form-group">
                              {" "}
                              <input
                                type="number"
                                name="quantity"
                                maxLength={productdata.total_quantity}
                                id="quantity"
                                onChange={onChange}
                                className="form-control"
                                placeholder="Enter Quantity here"
                                style={
                                  !formErrors.quantity
                                    ? {
                                        width: "60%",
                                        marginBottom: "0",
                                      }
                                    : {
                                        width: "60%",
                                        marginBottom: "0",
                                        borderColor: "red",
                                      }
                                }
                              />
                              &nbsp; / {productdata.total_quantity_unit}
                            </div>

                            {formErrors.quantity && (
                              <span className="error">
                                {formErrors.quantity}
                              </span>
                            )}
                          </form>
                        )}
                      </>
                    )}

                    {currentUser === false && (
                      <div className="fixed_range_button">
                        {productdata.pricing_type === "FIXED" ? (
                          <>
                            <p
                              className={
                                productdata.total_quantity <= 0
                                  ? "d-block out_of_stock"
                                  : "d-none"
                              }
                            >
                              {t("productView.outofstock")}
                            </p>
                            <CButton
                              className="btn cstm_btn open_popup_login"
                              onClick={handleBuy}
                              disabled={
                                productdata.total_quantity <= 0 ? true : false
                              }
                            >
                              {t("productView.buy")}
                            </CButton>
                          </>
                        ) : (
                          <>
                            <p
                              className={
                                productdata.total_quantity <= 0
                                  ? "d-block out_of_stock"
                                  : "d-none"
                              }
                            >
                              {t("productView.outofstock")}
                            </p>
                            <CButton
                              className="btn cstm_btn buy_now open_popup_login"
                              onClick={() => negotiation()}
                              disabled={
                                productdata.total_quantity <= 0 ? true : false
                              }
                            >
                              {" "}
                              {t("productView.negotiation")}
                            </CButton>
                            <CButton
                              className="btn cstm_btn ml-4 open_popup_login"
                              onClick={handleOffer}
                              disabled={
                                productdata.total_quantity <= 0 ? true : false
                              }
                            >
                              {t("productView.make_an_offer")}
                            </CButton>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <h6 className="d-block d-lg-none d-md-none d-sm-block font-weight-bold">
                    Posted by:
                  </h6>
                  <div className="user_detail">
                    <img
                      width=""
                      height=""
                      src={
                        productdata.user?.profile_image !== null
                          ? productdata.user?.profile_image
                          : dummyProfile
                      }
                      alt="profile"
                      className="user-img"
                    />
                    <div className="user_detail_info">
                      {productdata.user?.license_verified && (
                        <div className="verified_tag">Verified</div>
                      )}
                      <h3>
                        {productdata.user?.first_name !== null &&
                        productdata.user?.first_name.length > 0
                          ? productdata.user?.first_name
                          : productdata.user?.username}
                      </h3>
                      <div className="d-flex">
                        <div className="rating d-flex">
                          <ReactStars
                            size={15}
                            count={5}
                            value={
                              productdata.user?.rating.length === 0
                                ? 0
                                : productdata.user?.rating.rate__avg
                            }
                            edit={false}
                            a11y={true}
                            isHalf={true}
                            emptyIcon={<i className="fas fa-star"></i>}
                            halfIcon={<i className="fas fa-star-half-alt"></i>}
                            fullIcon={<i className="fas fa-star"></i>}
                            activeColor="#fcd804"
                          />
                        </div>
                        {token && reported === false ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                {t("productView.report_tool")}
                              </Tooltip>
                            }
                          >
                            <span
                              className="report ml-auto"
                              onClick={() =>
                                reportUser(productdata.user?.username)
                              }
                            >
                              {t("productView.report_user")}
                            </span>
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom"></div>
            <div className="border-bottom">
              <div>
                <div className="mt-2">
                  <h5 className="a-size-base font-weight-bold">
                    {" "}
                    {t("productView.description")}
                  </h5>
                  <p>{productdata.description}</p>
                </div>
              </div>
            </div>
            <div className="container related_products">
              {relatedProduct.data && relatedProduct?.data.length > 0 ? (
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="heading">
                      {t("productView.related_product")}
                    </h2>
                  </div>
                  {relatedProduct?.data.map((item) => {
                    return (
                      <div className="col-6 col-md-6 col-lg-3">
                        <div
                          className="product-card"
                          onClick={() => relatedView(item.id)}
                        >
                          <div className="img-outer">
                            <img
                              width=""
                              height=""
                              className="product_img"
                              src={
                                item.image !== null && item.image.length > 0
                                  ? item.image[0]
                                  : NoImage
                              }
                              alt="product"
                            />
                            {/* <div className="time">60 Days</div> */}
                          </div>
                          <div className="name">
                            <span>
                              {item?.product_name.map((el) =>
                                el.language.code === lang ? (
                                  <span>
                                    {el.name.length > 10
                                      ? el.name.substring(0, 10) + "..."
                                      : el.name}
                                  </span>
                                ) : null
                              )}
                            </span>
                          </div>
                          {/* <div className="average">1 hecta/9 tones</div> */}

                          {item.pricing_type === "FIXED" ? (
                            <>
                              <div className="price">
                                {" "}
                                {symbol} {item.price_to}
                                <sup>{item.total_quantity_unit}</sup>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="price">
                                {" "}
                                {symbol} {item.price_from}
                                <sup>{item.total_quantity_unit}</sup>- {symbol}{" "}
                                {item.price_to}
                                <sup>{item.total_quantity_unit}</sup>
                              </div>
                            </>
                          )}
                          {/* <i className="fav_icon far fa-heart"></i> */}
                        </div>
                      </div>
                      // </div>
                    );
                  })}
                  <div className="col-lg-12">
                    <div className="pagination">
                      <>
                        {relatedProduct.page_number > 1 ? (
                          <button
                            className="paginataion_button"
                            onClick={() =>
                              previouspagination(relatedProduct.page_number)
                            }
                          >
                            &#x3c;
                          </button>
                        ) : null}
                        {relatedProduct.page_number <
                        relatedProduct.total_pages ? (
                          <button
                            className="paginataion_button"
                            onClick={() =>
                              pagination(relatedProduct.page_number)
                            }
                          >
                            &#x3e;
                          </button>
                        ) : null}{" "}
                      </>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {chatModal && (
            <ChatDesign
              onClick={chatOff}
              chat={chatUser}
              roomId={roomId}
              currentUser={current_user}
              profileUser={profileData}
            />
          )}
          <Modal
            displayModal={offerModalShow}
            closeModal={() => onCancel()}
            modalBody={
              <>
                <div className="modal-body p-4">
                  <div className="modalcontent text-left">
                    <div className="product_name">
                      <h4>
                        {t("myoffers.createOffer.productName")}:{" "}
                        <span>
                          {productname.map((item) =>
                            item.language.code === lang ? (
                              <h1>{item.name}</h1>
                            ) : null
                          )}
                        </span>
                      </h4>
                    </div>
                    <div>
                      <h2> {t("myoffers.createOffer.makeanoffer")}</h2>
                      <form
                        actžon="/action_page.php"
                        id="form"
                        onSubmit={handleSubmit}
                        noValidate
                      >
                        <div className="form-group">
                          <CLabel htmlFor="">
                            {t("myoffers.createOffer.quantity")}
                          </CLabel>
                          <input
                            type="number"
                            name="quantity"
                            id="quantity"
                            className="form-control"
                            placeholder={t("myoffers.createOffer.quantity")}
                            onChange={handleChange}
                            onWheel={onWheel}
                          />
                          {formErrors.quantity && (
                            <span className="error">{formErrors.quantity}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <CLabel htmlFor="">
                            {t("myoffers.createOffer.currency")}{" "}
                          </CLabel>
                          <CInput
                            type="text"
                            name="currency"
                            id="currency"
                            className="form-control"
                            placeholder={t("myoffers.createOffer.currency")}
                            onChange={handleChange}
                            onWheel={onWheel}
                            value={product_currency}
                          />

                          {formErrors.currency && (
                            <span className="error">{formErrors.currency}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <CLabel htmlFor="">
                            {t("myoffers.createOffer.earnest_amount")}
                          </CLabel>
                          <CInput
                            type="number"
                            name="earnest_amount"
                            id="earnest_amount"
                            className="form-control"
                            placeholder={t(
                              "myoffers.createOffer.earnest_amount"
                            )}
                            onChange={handleChange}
                            // onWheel={onWheel}
                          />
                          {formErrors.earnest_amount && (
                            <span className="error">
                              {formErrors.earnest_amount}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <CLabel htmlFor="">
                            {t("myoffers.createOffer.final_amount")}
                          </CLabel>
                          <CInput
                            type="number"
                            name="final_amount"
                            id="final_amount"
                            className="form-control"
                            placeholder={t("myoffers.createOffer.final_amount")}
                            onChange={handleChange}
                            onWheel={onWheel}
                          />
                          {formErrors.final_amount && (
                            <span className="error">
                              {formErrors.final_amount}
                            </span>
                          )}
                        </div>

                        <div className="form-group text-right">
                          <input
                            type="submit"
                            className="btn cstm_btn open_popup_login"
                          />
                          {/* Submit your Offer */}
                          {/* </input> */}
                        </div>
                        <ToastContainer />
                      </form>
                    </div>
                  </div>
                </div>
              </>
            }
          />
          {/* <LoginModal displayModal={show} closeModal={() => onCancel()} /> */}
          <Abuse
            show={modalShow}
            onHide={onHide}
            abuse_modal={reportModal}
            setAbuse_modal={setReportModal}
            onAbuse={reportUserModal}
            username={userName}
          />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  OrderId: state,
});
export default connect(mapStateToProps)(ProductDetail);
