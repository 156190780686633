import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import TestTable from "../../../../Components/table/testTable";
import Loader from "../../../../Components/loader/index";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap-tabs";
import "./contracts.css";
import { useTranslation } from "react-i18next";

const Contracts = (props) => {
  const { t } = useTranslation("common");
  const [buyerData, setBuyerData] = useState();
  const [sellerData, setSellerData] = useState();
  const [loading, setLoading] = useState(true);
  // const moment = require("moment");
  const { getContracts } = props;
  const history = useHistory();
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  useEffect(() => {
    BuyerGetData();
    SellerGetData();
    // eslint-disable-next-line
  }, []);
  const [tabValue, SetTabvalue] = useState(false);
  const BuyerGetData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/contract-b/?lang=${lang}&type=ALL`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBuyerData(res.data.data);
        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };

  const SellerGetData = () => {
    SetTabvalue(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/contract-s/?lang=${lang}&type=ALL`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSellerData(res.data.data);
        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };
  const formatDate = (date) =>
    `${new Date(date).toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })} `;

  const handlePayment = (value) => {
    localStorage.setItem("Order_id", value.id);
    localStorage.setItem("productId", value.product.id);
    history.push({ pathname: "/checkout" });
  };

  const BuyerHeading = [
    {
      id: "1",
      Header: `${t("contracts.contractsList.headingData.created")}`,
      accessor: (props) => formatDate(props.created),
    },
    {
      id: "2",
      Header: `${t("contracts.contractsList.headingData.type")}`,
      accessor: "type",
    },
    {
      id: "3",
      Header: `${t("contracts.contractsList.headingData.productName")}`,
      accessor: (props) =>
        props.product.product_name.map((item) =>
          item.language.code === lang ? (
            <span>
              {" "}
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ),
    },

    {
      id: "4",
      Header: `${t("contracts.contractsList.headingData.quantity")}`,
      accessor: (props) => {
        return (
          <>
            {props.quantity !== 0 ? (
              <span>
                {props.quantity}
                &nbsp; {props.unit}
              </span>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
    },
    {
      id: "5",
      Header: `${t("contracts.contractsList.headingData.earnest_amount")}`,
      accessor: (d) => {
        if (d.is_earnest_paid === true) {
          return <span>{d.earnest_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "6",
      Header: `${t("contracts.contractsList.headingData.commission_paid")}`,
      accessor: "cut_off_payment",
    },
    {
      id: "7",
      Header: `${t("contracts.contractsList.headingData.final_amount")}`,
      accessor: (d) => {
        if (d.is_final_paid === true) {
          return <span>{d.final_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "8",
      Header: `${t("contracts.contractsList.headingData.total_amount")}`,
      accessor: "total_paid",
    },

    {
      id: "10",
      Header: (
        <span className="checkbox">
          {t("contracts.contractsList.headingData.payments")}
        </span>
      ),
      accessor: (props) => {
        return props.is_earnest_paid === false ||
          props.is_final_paid === false ? (
          //   props.is_earnest_paid === true
          //   props.progress === "IN-PROGRESS"
          // ||
          //     props.is_final_paid === false
          props.is_refund_paid === false ? (
            <>
              <button
                className="btn btn-outline-info w-50"
                onClick={() => handlePayment(props)}
              >
                {t("contracts.contractsList.headingData.pay")}
              </button>
            </>
          ) : (
            <p>{t("contracts.contractsList.headingData.refund")}</p>
          )
        ) : (
          <p>{t("contracts.contractsList.headingData.paid")}</p>
        );
      },
    },
  ];

  const SellerHeading = [
    {
      id: "1",
      Header: `${t("contracts.contractsList.headingData.created")}`,
      accessor: (props) => formatDate(props.created),
    },
    {
      id: "2",
      Header: `${t("contracts.contractsList.headingData.type")}`,
      accessor: "type",
    },
    {
      id: "3",
      Header: `${t("contracts.contractsList.headingData.productName")}`,
      accessor: (props) =>
        props.product.product_name.map((item) =>
          item.language.code === lang ? (
            <span>
              {" "}
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ),
    },
    {
      id: "4",
      Header: `${t("contracts.contractsList.headingData.quantity")}`,
      accessor: (props) => {
        return (
          <>
            {props.quantity !== 0 ? (
              <span>
                {props.quantity}
                &nbsp; {props.unit}
              </span>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
    },
    {
      id: "5",
      Header: `${t("contracts.contractsList.headingData.earnest_amount")}`,
      accessor: (d) => {
        if (d.is_earnest_paid === true) {
          return <span>{d.earnest_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "6",
      Header: `${t("contracts.contractsList.headingData.commission_paid")}`,
      accessor: "cut_off_payment",
    },
    {
      id: "7",
      Header: `${t("contracts.contractsList.headingData.final_amount")}`,
      accessor: (d) => {
        if (d.is_final_paid === true) {
          return <span>{d.final_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "8",
      Header: `${t("contracts.contractsList.headingData.total_amount")}`,
      accessor: "total_paid",
    },

    {
      id: "10",
      Header: (
        <span className="checkbox">
          {t("contracts.contractsList.headingData.status")}
        </span>
      ),
      accessor: "status",
    },
  ];

  const handleId = (id) => {
    history.push(`/contracts-b/${id}`);
  };
  const sellerId = (id) => {
    history.push(`/contracts-s/${id}`);
  };
  return (
    <div className="dashboardtabs ">
      <Tabs className="tabs">
        <Tab label={t("contracts.contractsList.tab1")} disabled={tabValue}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="headingWrap">
                <h3 className="m-4 title">
                  {t("contracts.contractsList.buyercontracts")}
                </h3>
              </div>
              {buyerData && buyerData.length > 0 ? (
                <div className="cursor_table">
                  <TestTable
                    headData={BuyerHeading}
                    bodyData={buyerData}
                    rowPerPage="7"
                    rowId={handleId}
                    paginationStatus={true}
                  />
                </div>
              ) : (
                <div>{t("contracts.contractsList.no_contracts")}</div>
              )}
            </>
          )}
        </Tab>
        <Tab label={t("contracts.contractsList.tab2")} disabled={tabValue}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="headingWrap">
                <h3 className="m-4 title">
                  {t("contracts.contractsList.sellercontracts")}
                </h3>
              </div>
              {sellerData && sellerData.length > 0 ? (
                <div className="cursor_table">
                  <TestTable
                    headData={SellerHeading}
                    bodyData={sellerData}
                    rowPerPage="7"
                    rowId={sellerId}
                    paginationStatus={true}
                  />
                </div>
              ) : (
                <div className="not-found">
                  <h2>{t("contracts.contractsList.no_contracts")}</h2>
                </div>
              )}
            </>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};
export default Contracts;
