import React, { useEffect, useState } from "react";
import TestTable from "../../../Components/table/testTable";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from "../../../Components/loader/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./myOffer.css";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap-tabs";
import actionImg from "../../../../assets/images/tick-mark.png";
import deleteImg from "../../../../assets/images/remove.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Rating from "../Rating/Rating";
import Modal from "../confirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";

const OfferList = () => {
  const { t } = useTranslation("common");
  const [body, setBody] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const [tabValue, SetTabvalue] = useState(false);
  const [buyerData, setBuyerData] = useState();
  const [sellerData, setSellerData] = useState();
  const [serviceProvider, setServiceProvider] = useState();
  const [ratingPop, setRatingPop] = useState(false);
  const [userName, setUserName] = useState();
  // const [userProfile, setUserProfile] = useState();
  const lang = localStorage.getItem("language");
  const headingData = [
    {
      id: "1",
      Header: `${t("myoffers.headingData.created")}`,
      accessor: (d) => {
        return d.user.first_name !== null && d.user.first_name.length > 0
          ? d.user.first_name
          : `@${d.user.username}`;
      },
    },
    {
      id: "2",
      Header: `${t("myoffers.headingData.productName")}`,
      accessor: (props) =>
        props.product.product_name.map((item) =>
          item.language.code === lang ? (
            <span>
              {" "}
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ), // accessor is the "key" in the data
    },
    {
      id: "3",
      Header: `${t("myoffers.headingData.quantity")}`,
      accessor: (props) => {
        return (
          <>
            {props.quantity !== 0 ? (
              <span>
                {props.quantity}
                &nbsp; {props.unit}
              </span>
            ) : (
              <span>-</span>
            )}
          </>
        );
      },
    },
    {
      id: "4",
      Header: `${t("myoffers.headingData.earnest_amount")}`,
      accessor: (d) => {
        if (d.is_earnest_paid === true) {
          return <span>{d.earnest_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "5",
      Header: `${t("myoffers.headingData.final_amount")}`,
      accessor: "final_amount",
    },
    {
      id: "6",
      Header: `${t("myoffers.headingData.currency")}`,
      accessor: "currency",
    },
    {
      id: "7",
      Header: <span>{t("myoffers.headingData.type")}</span>,
      accessor: "type",
    },
    {
      id: "10",
      Header: <span>{t("myoffers.headingData.status")}</span>,
      accessor: "status",
    },
  ];
  const sellerHeadingData = [
    {
      id: "1",
      Header: `${t("myoffers.headingData.created")}`,
      accessor: (d) => {
        return d.user.first_name !== null && d.user.first_name.length > 0
          ? d.user.first_name
          : `@${d.user.username}`;
      },
    },
    {
      id: "2",
      Header: `${t("myoffers.headingData.productName")}`,
      accessor: (props) =>
        props.product.product_name.map((item) =>
          item.language.code === lang ? (
            <span>
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ), // accessor is the "key" in the data
    },
    {
      id: "3",
      Header: `${t("myoffers.headingData.quantity")}`,
      accessor: (props) => {
        return (
          <>
            <span>
              {props.quantity}
              &nbsp; {props.unit}
            </span>
          </>
        );
      },
    },
    {
      id: "4",
      Header: `${t("myoffers.headingData.currency")}`,
      accessor: "currency",
    },
    {
      id: "5",
      Header: `${t("myoffers.headingData.earnest_amount")}`,
      accessor: (d) => {
        if (d.is_earnest_paid === true) {
          return <span>{d.earnest_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "6",
      Header: `${t("myoffers.headingData.final_amount")}`,
      accessor: "final_amount",
    },
    {
      id: "7",
      Header: <span>{t("myoffers.headingData.type")}</span>,
      accessor: "type",
    },
    {
      id: "10",
      Header: (
        <span className="checkbox">{t("myoffers.headingData.action")}</span>
      ),
      accessor: (props) => {
        return props.status === "CREATED" ? (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Accepted</Tooltip>}
            >
              <button onClick={(e) => acceptOffer(e, "ACCEPTED", props.id)}>
                <img
                  width=""
                  height=""
                  src={actionImg}
                  alt="timeline"
                  style={{ width: "25px", height: "25px", cursor: "pointer" }}
                />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Reject</Tooltip>}
            >
              <button onClick={(e) => acceptOffer(e, "REJECT", props.id)}>
                <img
                  width=""
                  height=""
                  src={deleteImg}
                  alt="timeline"
                  style={{
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                />
              </button>
            </OverlayTrigger>
          </>
        ) : (
          <p>{props.status}</p>
        );
      },
    },
  ];


  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    BuyerGetData();
    SellerGetData();
    SetTabvalue(false);
    let newList = [];
    if (body !== undefined) {
      newList = body.filter(
        (obj) => obj.status === "ACCEPT" || obj.status === "CREATED"
      );
    }
    setData(newList);
    // eslint-disable-next-line
  }, [loading, token]);
  const BuyerGetData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}payment/offer-b/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBuyerData(res.data.data);
        setLoading(false);
      })
      .catch((err) => toast.error(err));
  };
  const SellerGetData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}payment/offer-s/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSellerData(res.data.data);
        setBody(res.data.data);
        setLoading(false);
      })
      .catch((err) => toast.error(err));
  };

  const acceptOffer = (e, value, id) => {
    const payload = {
      id: id,
      status: value,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}payment/offer-s/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Offer is Accepted");
        history.push({ pathname: "/dashboard" });
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };

  const onCancel = () => {
    setRatingPop(false);
  };
  return (
    <>
      <div className="dashboardtabs">
        <Tabs className="tabs">
          <Tab label={`${t("myoffers.tab1")}`} disabled={tabValue}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="headingWrap mt-4">
                  <h3 className="title">{t("myoffers.tab1")}</h3>
                </div>
                {sellerData && sellerData.length > 0 ? (
                  <div className="cursor_table">
                    <TestTable
                      headData={sellerHeadingData}
                      bodyData={sellerData}
                      rowPerPage="6"
                      paginationStatus={true}
                    />
                  </div>
                ) : (
                  <div className="not-found">
                    <h2>{t("myoffers.no_offer")}</h2>
                  </div>
                )}
              </>
            )}
          </Tab>

          <Tab label={`${t("myoffers.tab2")}`} disabled={tabValue}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="headingWrap mt-4">
                  <h3 className="title">{t("myoffers.tab2")}</h3>
                </div>
                {buyerData && buyerData.length > 0 ? (
                  <TestTable
                    headData={headingData}
                    bodyData={buyerData}
                    rowPerPage="6"
                    paginationStatus={true}
                  />
                ) : (
                  <div className="not-found">
                    <h2>{t("myoffers.no_offer")}</h2>
                  </div>
                )}
              </>
            )}
          </Tab>
        </Tabs>
      </div>
      <ToastContainer />
      <Modal
        displayModal={ratingPop}
        closeModal={() => onCancel()}
        modalBody={
          <>
            <div className="m-auto p-4 service_modal">
              <h4 className="mt-3 mb-3">Rate your experience</h4>
              <h4>to</h4>

              <h4 className="user_name">{userName}</h4>
              <div className="react-star">
                <Rating username={userName} closeModal={() => onCancel()} />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
export default OfferList;
