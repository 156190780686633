import { UserActionTypes } from "../constants/action-types"
export const userReducer = (state = {}, { type, payload }) => {

    switch (type) {
        case UserActionTypes.USER_NAME:
            return { ...state, user_name: payload }


        default:
            return state;



    }

}