import React, { useEffect, useState } from "react";
import Search from "../../searchbox";
import dummyProfile from "../../../../../assets/images/profile.jpeg";
import { CInput, CLabel } from "@coreui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import Loader from "../../../../Components/loader/index";
import { socket } from "../../../../../socket";
import ChatDesign from "../../chat/chatDesign";
import LoginModal from "../../../login/loginModal";
import Modal from "../../confirmModal/ConfirmModal";
import { ToastContainer } from "react-toastify";
import jQuery from "jquery";

const ServiceProviderCategoryList = (props) => {
  const MAX_LENGTH = 150;
  const { t } = useTranslation("common");
  const paramId = props.match.params.id;
  const [body, setBody] = useState([]);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("language");
  const history = useHistory();
  const [symbol, setSymbol] = useState("$");
  let token = localStorage.getItem("accessToken");
  const [show, setShow] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const getLang = localStorage.getItem("language");
  const [current_user, setCurrent_user] = useState([]);
  const [participantsName, setParticipantsName] = useState([]);
  const [chatModal, setChatModal] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [extra, setExtra] = useState([]);
  const [chatUser, setChatUser] = useState({});
  const [offerModalShow, setOfferModalShow] = useState(false);
  const [productname, setProductname] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [offerData, setOfferData] = useState({
    final_amount: "",
    description: "",
  });
  const [product_currency, setProductCurrency] = useState("");
  const [product_id, setProduct_id] = useState("");
const getData=()=>{
  axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/service-from-serviceprovider/?username=${paramId}&lang=${lang}`, {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBody(res.data.data);
        setExtra(res.data.extra);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response);
      });
}

  useEffect(() => {
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}auth/profile/?lang=${getLang}`, {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        })
        .then((res) => {
          setProfileData(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    getData();
    if (body) {
      if (body.price_currency === "DONG") {
        setSymbol("₫");
      }
    }
    // eslint-disable-next-line
  }, [loading, lang, token, setBody, setExtra]);
  jQuery(".open_popup_login").click((e) => {
    jQuery(".my_test").click();
jQuery(".login_test").click(function(){
getData();
});
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setOfferData({ ...offerData, [name]: value });
  };
  const onHire = (value) => {
    console.log("on hire value");
    setProductname(value.product_name);
    setProduct_id(value.id);
    setProductCurrency(value.price_currency);
  
    if (!token) {
      setShow(true);
    } else {
      setOfferModalShow(true);
    }
  };
  const handleOffer = (e) => {
    e.preventDefault();
    const payload = {
      product: product_id,
      final_amount: Number(offerData.final_amount),
      currency: product_currency,
      type: "SERVICE",
      description: offerData.description,
    };
    console.log(payload, "payload");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}payment/offer-create/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success("Succesfully added");
          history.push({ pathname: "/offer" });

          setOfferData({
            final_amount: "",
            currency: "",
            description: "",
          });
          document.getElementById("form").reset();
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };

  const onCancel = () => {
    setShow(false);
    setOfferModalShow(false);
  };
  useEffect(() => {
    getData();
    if (participantsName !== undefined && participantsName.length > 0) {
      for (let j = 0; j < participantsName.length; j++) {
        if (participantsName[j].user_id === body[0].user.id.toString()) {
          setChatUser(participantsName[j]);
        }
      }
    }
    // eslint-disable-next-line
  }, [participantsName, current_user, roomId, profileData,token]);
  const serviceView = (category, username) => {
    history.push({ pathname: `/servicecategory/${username}/${category}` });
  };
  const chatOff = () => {
    setChatModal(false);
  };
  const negotiation = (item) => {
    if (!token) {
      setShow(true);
    } else {
      let data = {
        name: item.user.username,
        participants: [
          {
            user_id: item.user.id.toString(),
            email: item.user.username,
            name: item.user.first_name,
            profile_image: item.user.profile_image,
          },
        ],
        user: {
          user_id: profileData.id.toString(),
          email: profileData.username,
          name: profileData.first_name,
          profile_image: profileData.profile_image,
        },
        is_group: false,
      };

      socket.emit("new_room_create", data);
      socket.on("add_room", (resp) => {
        setCurrent_user(resp.channel.creator_id);
        setParticipantsName(resp.channel.participants);
        setRoomId(resp.channel.id);
        setLoading(false);
      });
      setChatModal(true);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="service_detail">
          <div className="cstm_header service_details_header">
            <Search />
          </div>
          <div className="service_provider_cont">
            <div className="container">
              <div className="row"></div>
              <div className="service_provider_details_cont main-product">
                <div className="container position-relative">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="info_outer aos-init"
                            data-aos="fade-up"
                          >
                            <div className="info ">
                              <img
                                width=""
                                height=""
                                src={
                                  body[0]?.user?.profile_image !== null ||
                                  body[0]?.user?.profile_image !== undefined ||
                                  body[0]?.user?.profile_image.length > 0
                                    ? body[0]?.user?.profile_image
                                    : dummyProfile
                                }
                                alt="dummy_imagse"
                              />
                              <div>
                                <div className="name">
                                  {body[0]?.user?.first_name}
                                </div>
                                <p>{body[0]?.user.profile_summary}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {body.length > 0
                            ? body.map((item, index) => {
                                return (
                                  <div class="col-md-6 mt-4">
                                    <div className="s-provider">
                                      <div className="d-flex align-items-end sp-content">
                                        <div className="img-title d-flex justify-content-start align-items-end">
                                          <img
                                            width=""
                                            height=""
                                            src={
                                              item.category.logo !== null &&
                                              item.category.logo.length > 0
                                                ? `https://cho.direct${item.category.logo}`
                                                : dummyProfile
                                            }
                                            alt="dumy"
                                          />
                                          <h2 className="ms-3">
                                            {item.product_name.map((item) =>
                                              item.language.code === lang ? (
                                                <h4>{item.name}</h4>
                                              ) : null
                                            )}
                                          </h2>
                                        </div>
                                        <div className="price">
                                          {symbol} {item.price_to}
                                          <sup>{item.pricing_type}</sup>
                                        </div>
                                      </div>

                                      {item.description ? (
                                        <p>
                                          {`${item.description.substring(
                                            0,
                                            MAX_LENGTH
                                          )}...`}
                                        </p>
                                      ) : (
                                        <p>No description added</p>
                                      )}
                                      {extra.is_hired === false ||
                                        (extra.is_owner === false && (
                                          <>
                                            <div className="dets">
                                              <div className="button_grid">
                                                <button
                                                  className="btn chat_btn  open_popup_login"
                                                  onClick={() =>
                                                    negotiation(item)
                                                  }
                                                >
                                                  <i className="fas fa-comment-alt me-2"></i>
                                                  NAGOTIATE
                                                </button>

                                                <button
                                                  className="btn cstm_btn open_popup_login"
                                                  onClick={(e) => onHire(item)}
                                                >
                                                  HIRE NOW
                                                </button>
                                              </div>
                                            </div>
                                          </>
                                        ))}
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        displayModal={offerModalShow}
        closeModal={() => onCancel()}
        modalBody={
          <>
            <div className="modal-body p-4">
              <div className="modalcontent text-left">
                <div className="product_name">
                  <h4>
                    {/* {t("myoffers.createOffer.productName")}:{" "} */}
                    Service Name:
                    <span>
                      {productname.map((item) =>
                        item.language.code === lang ? (
                          <h1>{item.name}</h1>
                        ) : null
                      )}
                    </span>
                  </h4>
                </div>
                <div>
                  <h2> {t("myoffers.createOffer.makeanoffer")}</h2>
                  <form
                    actžon="/action_page.php"
                    id="form"
                    onSubmit={handleOffer}
                    noValidate
                  >
                    <div className="form-group">
                      <CLabel htmlFor="">
                        {/* {t("myoffers.createOffer.quantity")} */}
                        Description
                      </CLabel>
                      <input
                        type="text"
                        name="description"
                        id="description"
                        className="form-control"
                        // placeholder={t("myoffers.createOffer.quantity")}
                        placeholder="Enter description"
                        onChange={handleChange}
                        // onWheel={onWheel}
                      />
                      {formErrors.quantity && (
                        <span className="error">{formErrors.quantity}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <CLabel htmlFor="">
                        {/* {t("myoffers.createOffer.final_amount")} */}
                        Total Amount
                      </CLabel>
                      <CInput
                        type="number"
                        name="final_amount"
                        id="final_amount"
                        className="form-control"
                        // placeholder={t("myoffers.createOffer.final_amount")}
                        placeholder="Enter your total amount"
                        onChange={handleChange}
                        // onWheel={onWheel}
                      />
                      {formErrors.final_amount && (
                        <span className="error">{formErrors.final_amount}</span>
                      )}
                    </div>

                    <div className="form-group text-right">
                      <input type="submit" className="btn cstm_btn" />
                      {/* Submit your Offer */}
                      {/* </input> */}
                    </div>
                    <ToastContainer />
                  </form>
                </div>
              </div>
            </div>
          </>
        }
      />

      {chatModal && (
        <ChatDesign
          onClick={chatOff}
          chat={chatUser}
          roomId={roomId}
          currentUser={current_user}
          profileUser={profileData}
        />
      )}
      {/* <LoginModal displayModal={show} closeModal={() => onCancel()} /> */}
    </>
  );
};
export default ServiceProviderCategoryList;
