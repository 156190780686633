import { PaymentActionTypes } from "../constants/action-types"

const initialState = {
    payments: []
}



export const paymentReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case PaymentActionTypes.GET_PAYMENTS:
            return { ...state, payments: payload }
        default:
            return state;
    }
}