import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import TestTable from "../../../../Components/table/testTable";
import Loader from "../../../../Components/loader/index";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../contracts/contracts.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import reset from "../../../../../assets/images/reload.svg";
import "./hireHistory.css";
import { useTranslation } from "react-i18next";
import Modal from "../../confirmModal/ConfirmModal";
import Rating from "../../Rating/Rating";

const HireHistroy = (props) => {
  const { t } = useTranslation("common");
  const lang = localStorage.getItem("language");
  const [sellerData, setSellerData] = useState();
  const [loading, setLoading] = useState(true);
  const { getContracts } = props;
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const [ratingPop, setRatingPop] = useState(false);
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState("");
  const [show, setShow] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [from, setFrom] = useState("");
  const [end, setEnd] = useState("");
  const [dateModal, setDateModal] = useState(false);
  useEffect(() => {
    SellerGetData();
  }, []);

  const SellerGetData = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}payment/hire-s/?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSellerData(res.data.data);
        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };
  const formatDate = (date) =>
    `${new Date(date).toLocaleString("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })} `;
  // const headingData = [
  //     {
  //         id: "1",
  //         Header: `${t("hireHistory.hireHistoryList.headingData.created")}`,
  //         accessor: (props) => formatDate(props.created),
  //     },
  //     {
  //         id: "2",
  //         Header: `${t("hireHistory.hireHistoryList.headingData.hireCreator")}`,
  //         accessor: (d) => {
  //             return (
  //                 <span>
  //                     {d.user.first_name !== null && d.user.first_name.length > 0
  //                         ? d.user.first_name
  //                         : `@${d.user.username}`}
  //                     &nbsp; is interested to hire you
  //                 </span>
  //             );
  //         },
  //     },

  //     {
  //         id: "10",
  //       Header: (
  //           <span className="checkbox">
  //               {t("hireHistory.hireHistoryList.headingData.actions")}
  //           </span>
  //       ),
  //       accessor: (props) => {
  //           return <span>{props.status}</span>;
  //       },
  //     },
  // ];

  const handleClick = (e, id, username) => {
    setUserId(id);
    setUserName(username);
    setShow(true);
  };
  const onHide = () => {
    setShow(false);
  };
  const handleComplete = (e, value) => {
    const payload = {
      id: userId,
      service_status: value,
    };
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}payment/hire-s/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        // history.push({ pathname: "/dashboard" });
        setRatingPop(true);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  };

  const headingData = [
    {
      id: "1",
      Header: `${t("order.orderList.headingData.created")}`,
      accessor: (props) => formatDate(props.created),
    },
    {
      id: "2",
      Header: `${t("order.orderList.headingData.productName")}`,
      accessor: (props) =>
        props.product.product_name.map((item) =>
          item.language.code === lang ? (
            <span>
              {" "}
              {item.name.length > 10
                ? item.name.substring(0, 10) + "..."
                : item.name}
            </span>
          ) : null
        ),
    },
    {
      id: "3",
      Header: `${t("order.orderList.headingData.currency")}`,
      accessor: "currency",
    },
    // {
    //   id: "5",
    //   Header: `${t("order.orderList.headingData.earnest_amount")}`,
    //   accessor: (d) => {
    //     if (d.is_earnest_paid === true) {
    //       return <span>{d.earnest_amount}</span>;
    //     } else {
    //       return <span>-</span>;
    //     }
    //   },
    // },
    {
      id: "6",
      Header: `${t("order.orderList.headingData.commission_paid")}`,
      accessor: "cut_off_payment",
    },
    {
      id: "7",
      Header: `${t("order.orderList.headingData.final_amount")}`,
      accessor: (d) => {
        if (d.is_final_paid === true) {
          return <span>{d.final_amount}</span>;
        } else {
          return <span>-</span>;
        }
      },
    },
    {
      id: "8",
      Header: `${t("order.orderList.headingData.amount")}`,
      accessor: "total_paid",
    },
    {
      id: "9",
      Header: `${t("order.orderList.headingData.pay_stat")}`,
      accessor: "pay_stat",
    },
    {
      id: "10",
      Header: `${t("order.orderList.headingData.final_amount")}`,
      accessor: (d) => {
        if (d.service_status === "PENDING") {
          return (
            <button
              className="btn  button_complete"
              onClick={(e) => handleClick(e, d.id, d.user.username)}
            >
              Complete
            </button>
          );
        } else if (
          d.service_status === "COMPLETED_FROM_SERVICE_PROVIDER" ||
          d.service_status === "COMPLETED_FROM_BUYER"
        ) {
          return <span>Completed</span>;
        }
      },
    },
  ];

  const timeFrameModal = () => {
    setDateModal(!dateModal);
  };
  const onCancel = () => {
    setRatingPop(false);
  };
  const handleSelect = (value) => {
    const start = selectionRange[0].startDate;
    const last = selectionRange[0].endDate;

    const startNow =
      start.getFullYear() +
      "-" +
      (start.getMonth() + 1) +
      "-" +
      start.getDate();
    const endNow =
      last.getFullYear() + "-" + (last.getMonth() + 1) + "-" + last.getDate();
    setFrom(startNow);
    setEnd(endNow);
    setDateModal(false);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}payment/hire-s/?date_from=${startNow}&&date_to=${endNow}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success(`${t("errorMessages.filter_success")}`);
        setSellerData(res.data.data);
        setLoading(false);
        dispatch(getContracts(res.data));
      })
      .catch((err) => toast.error(err));
  };
  function formateDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const resetFilter = (value) => {
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substr(0, 10);
    let from = "1980-05-11";
    const tomorrow = new Date();
    let to = formateDate(tomorrow);
    if (value === "seller") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/hire-s/?date_from=${from}&&date_to=${to}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          toast.success(`${t("errorMessages.reset_filter")}`);
          setSellerData(res.data.data);
          setLoading(false);
          dispatch(getContracts(res.data));
        })
        .catch((err) => toast.error(err));
    }
    setFrom(date);
    setEnd();
  };
  return (
    <div className="dashboardtabs  scroll_div">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h3 className="m-4">{t("hireHistory.hireHistoryList.title")}</h3>
            <div className="d-flex">
              <button
                onClick={timeFrameModal}
                className="btn btn-primary m-auto"
              >
                <span>{t("hireHistory.hireHistoryList.datepicker")}</span>
                &nbsp;
              </button>

              {dateModal ? (
                <div className="doneIt">
                  <div className="flex">
                    <DateRangePicker
                      editableDateInputs={true}
                      rangeColors="#32297c"
                      color="#32297c"
                      moveRangeOnFirstSelection={false}
                      ranges={selectionRange}
                      onChange={(item) => setSelectionRange([item.selection])}
                    />
                  </div>
                  <p className="rangeButton">
                    <button
                      onClick={() => handleSelect("seller")}
                      className="rangebtn"
                    >
                      {t("hireHistory.hireHistoryList.apply")}
                    </button>
                  </p>
                </div>
              ) : null}
              <img
                width=""
                height=""
                src={reset}
                alt=""
                className="reset-icon"
                onClick={() => resetFilter("seller")}
              />
            </div>
          </div>
          {sellerData && sellerData.length > 0 ? (
            <TestTable
              headData={headingData}
              bodyData={sellerData}
              rowPerPage="5"
              paginationStatus={true}
            />
          ) : (
            <div className="not-found">
              <h2>{t("hireHistory.hireHistoryList.no_history")}</h2>
            </div>
          )}
        </>
      )}
      <Modal
        displayModal={show}
        closeModal={onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent mt-4">
                <h2 className="text-center">
                  Hope you have completed the service as per client's
                  satisfication
                </h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={onHide}
                  >
                    {t("deletemodal.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) =>
                      handleComplete(e, "COMPLETED_FROM_SERVICE_PROVIDER")
                    }
                  >
                    {/* {t("deletemodal.delete")} */}
                    complete
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />

      <Modal
        displayModal={ratingPop}
        closeModal={() => onCancel()}
        modalBody={
          <>
            <div className="m-auto p-4 service_modal">
              <h4 className="mt-3 mb-3">Rate your experience</h4>
              <h4>to</h4>

              <h4 className="user_name">{userName}</h4>
              <div className="react-star">
                <Rating username={userName} closeModal={() => onCancel()} />
              </div>
            </div>
          </>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default HireHistroy;
