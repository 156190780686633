import React from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { CCol, CLabel, CRow, CTextarea } from "@coreui/react";
import Modal from "../confirmModal/ConfirmModal";
import deleteImg from "../../../../assets/images/delete.svg";
import Moment from "moment";
import useForm from "../validation/useForm";
import { useTranslation } from "react-i18next";

const Backout = (props) => {
  const { t } = useTranslation("common");
  let token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");
  const initialState = {
    backout_reason: { value: "", error: "" },
  };
  const stateValidatorSchema = {
    backout_reason: {
      required: true,
      validator: {
        func: (value) => /^[a-zA-Z\s]*$/.test(value),
        error: `${t("errorMessages.required_field")}`,
      },
    },
  };
  const onSubmitForm = (state) => {
    const formatDate = `${Date().toLocaleString()} `;
    let date = Moment(formatDate).format("YYYY-MM-DD H:mm:ss");
    let payload = {
      id: props.id,
      backout: true,
      backout_reason: state.backout_reason,
      backout_date: date,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}payment/backout/?lang=${lang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(`${t("errorMessages.backout_success")}`);
          props.setBackout_modal(false);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        } else {
          toast.error("an error occured");
        }
      });
  };
  const { errors, dirty, handleChange, handleSubmit, disable } = useForm(
    initialState,
    stateValidatorSchema,
    onSubmitForm
  );

  return (
    <>
      <Modal
        displayModal={props.show}
        closeModal={props.onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <div>
                  <img
                    width=""
                    height=""
                    src={deleteImg}
                    alt=""
                    className="icon_wrap"
                  />
                </div>
                <h2 className="text-center"> {t("backoutForm.title")}</h2>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={props.onHide}
                  >
                    {t("backoutForm.cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={props.onBackout}
                  >
                    {t("backoutForm.backout")}
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
      <div>
        <Modal
          displayModal={props.backout_modal}
          closeModal={props.onHide}
          modalBody={
            <>
              <form id="form" onSubmit={handleSubmit} className="backout_modal">
                <CRow>
                  <CCol md={12}>
                    <CLabel className="product-label" htmlFor="phone">
                      {t("backoutForm.backoutReason")}
                    </CLabel>
                    <CTextarea
                      style={{ width: "100%", height: "100%" }}
                      rows="7"
                      onChange={handleChange}
                      name="backout_reason"
                      id="backout_reason"
                    ></CTextarea>
                    {errors.backout_reason && dirty.backout_reason && (
                      <p className="error">{errors.backout_reason}</p>
                    )}
                  </CCol>
                </CRow>

                <div
                  className="modal-button"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "100px",
                  }}
                >
                  <button
                    htmltype="submit"
                    type="submit"
                    className="btn btn-primary"
                    disabled={disable}
                  >
                    {t("backoutForm.submit")}
                  </button>
                </div>
              </form>
            </>
          }
        />
      </div>
    </>
  );
};
export default Backout;