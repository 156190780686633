import React, { useEffect, useState, useRef } from "react";
import "./chatDesign.css";
import { socket } from "../../../../socket";
import axios from "axios";
import Modal from "../confirmModal/ConfirmModal";
const ChatDesign = (props) => {
  // const [unreadMessage, setUnreadMessage] = useState([]);
  const [loading, setLoading] = useState(true);

  // const [admin, setAdmin] = useState([]);
  // const [typingUserName, setTypingUserName] = useState({});
  const [modalImage, setModalImage] = useState(false);
  const [imageFile, setImageFile] = useState({});
  const [imageUpload, setImageUpload] = useState({});
  // const [chatUserName, setChatUserName] = useState("");
  let token = localStorage.getItem("accessToken");
  const [message, setMessage] = useState({
    messageData: "",
  });
  const [messages, setMessages] = useState([]);
  // const [typing, setTyping] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setMessage({ ...message, [name]: value });
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    // window.scrollTo(0, 0)
    scrollToBottom();
  }, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.messageData.length > 0) {
      let new_message = {
        room: props.roomId,
        message_body: message.messageData,
        type: "USER_TEXT_MESSAGE",
        user: {
          user_id: props.profileUser.id.toString(),
          email: props.profileUser.username,
          name: props.profileUser.first_name,
          profile_image: props.profileUser.profile_image,
        },
      };
      socket.emit("new_message", new_message);
      document.getElementById("form").reset();
      setMessage({ messageData: "" });
      socket.off("message_broadcast").on("message_broadcast", (resp) => {
        setMessages((messages) => [...messages, resp.message_body]);
        setLoading(false);
        if (resp.client_id !== props.currentUser.user_id.toString()) {
          let messageRead = {
            room: resp.roomId,
            user: {
              user_id: props.profileUser.id.toString(),
              email: props.profileUser.username,
              name: props.profileUser.first_name,
              profile_image: props.profileUser.profile_image,
            },
          };

          socket.emit("read_status", messageRead);
          // socket.on("read_status_show", (resp) => {
          //   setUnreadMessage(resp.data);
          // });
        }
      });
    }
  };

  useEffect(() => {
    if (props.roomId.length > 0) {
      let get_messages = {
        room: props.roomId,
        user_id: props.profileUser.id,
      };
      socket.emit("get_messages", get_messages);
      socket.off("set_messages").on("set_messages", (resp) => {
        setMessages(resp.messages.data.messages);
        // setSender(resp.data.data.sender);
        // setAdmin(resp.messages.data.room);
        setLoading(false);
      });
    }
    //eslint-disable-next-line
  }, [loading, props.roomId]);
  const onFocus = () => {
    let start_typing = {
      room: props.roomId,
      user: {
        user_id: props.profileUser.id.toString(),
        email: props.profileUser.username,
        name: props.profileUser.first_name,
        profile_image: props.profileUser.profile_image,
      },
    };

    socket.emit("typing_start", start_typing);
    // socket.on("show_start_typing", (resp) => {
    //   setTyping(true);
    //   setTypingUserName(resp.user);
    // });
  };
  const onBlur = () => {};
  // const onChangeImage = (e) => {
  //     let k = e.target.files[0];
  //     var formdata = new FormData();
  //     formdata.append("file", k, k.name);
  //     axios
  //         .post(`${process.env.REACT_APP_BASE_URL}upload-multiple/`, formdata, {
  //             headers: {
  //                 Authorization: ` Bearer ${token}`,
  //             },
  //         })
  //         .then((res) => {
  //             let new_message = {
  //                 room: props.roomId,
  //                 message_body: res.data.data[0],
  //                 type: "USER_IMAGE_MESSAGE",
  //                 user: {
  //                     user_id: props.chat.user_id.toString(),
  //                     email: props.chat.email,
  //                     name: props.chat.name,
  //                 },
  //             };
  //             socket.emit("new_message", new_message);
  //             setMessage({ messageData: "" });

  //             socket.off("message_broadcast").on("message_broadcast", (resp) => {
  //                 console.log(resp, "chatbox message2")
  //                 setMessage(resp.message_body);
  //                 setMessages((messages) => [...messages, resp.message_body]);

  //                 if (resp.client_id !== props.chat.user_id.toString()) {
  //                     let messageRead = {
  //                         room: props.roomId,
  //                         user: {
  //                             user_id: props.chat.user_id.toString(),
  //                             email: props.chat.username,
  //                             name: props.chat.first_name,
  //                         },
  //                     };

  //                     socket.emit("read_status", messageRead);
  //                     socket.on("read_status_show", (resp) => {
  //                         setUnreadMessage(resp.data);
  //                         // setGets(true);
  //                     });
  //                 }
  //             });
  //         })
  //         .catch((err) => console.log(err.response, "i am errr"));
  // };
  const onChangeImage = (e) => {
    setModalImage(true);
    // if (e.target.files[0]) {

    const reader = new FileReader();
    let k = e.target.files[0];
    var formdata = new FormData();
    formdata.append("file", k, k.name);
    setImageUpload(formdata);
    reader.addEventListener("load", () => {
      setImageFile(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
    // }
  };
  const handleChat = (e) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}upload-multiple/`, imageUpload, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
      })
      .then((res) => {
        setModalImage(false);
        let new_message = {
          room: props.roomId,
          message_body: res.data.data[0],
          type: "USER_IMAGE_MESSAGE",
          user: {
            user_id: props.profileUser.id.toString(),
            email: props.profileUser.username,
            name: props.profileUser.first_name,
            profile_image: props.profileUser.profile_image,
          },
        };

        socket.emit("new_message", new_message);

        setMessage({ messageData: "" });

        socket.off("message_broadcast").on("message_broadcast", (resp) => {
          setMessage(resp.message_body);
          setMessages((messages) => [...messages, resp.message_body]);

          if (resp.client_id !== props.chat.user_id.toString()) {
            let messageRead = {
              room: props.roomId,
              user: {
                user_id: props.chat.user_id.toString(),
                email: props.chat.username,
                name: props.chat.first_name,
              },
            };

            socket.emit("read_status", messageRead);
            // socket.on("read_status_show", (resp) => {
            //   setUnreadMessage(resp.data);
            //   // setGets(true);
            // });
          }
        });
      })
      .catch((err) => console.log(err.response, "i am errr"));
  };
  const onHide = () => {
    setModalImage(false);
  };

  return (
    <div className="">
      <div className="chat_box">
        <div className="chat_header" onClick={props.onClick}>
          <div className="user_details">
            <img
              width=""
              height=""
              src={props.chat.profile_image}
              alt="profile"
            />
            {props.chat.name !== null || props.chat.name !== undefined ? (
              <span>
                {" "}
                {props.chat.name?.length < 0
                  ? props.chat.name
                  : props.chat.email}
              </span>
            ) : (
              <span>{props.chat.email}</span>
            )}
          </div>
          <div className="user_actions">
            <button className="btns" type="button">
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div className="chat_body">
          <div className="inner_chat_data">
            {Object.keys(messages).length > 0
              ? messages.map((item, i) => {
                  return (
                    <>
                      {item.type === "USER_TEXT_MESSAGE" && (
                        <>
                          <div
                            key={i}
                            className={`message ${
                              JSON.parse(item.sender.user_id) ===
                              JSON.parse(props.profileUser.id)
                                ? "incoming_msg"
                                : "outgoing_msg"
                            }`}
                          >
                            <p> {item.message_body}</p>
                          </div>
                        </>
                      )}

                      {item.type === "USER_IMAGE_MESSAGE" && (
                        <>
                          <div
                            className={`message ${
                              JSON.parse(item.sender.user_id) ===
                              JSON.parse(props.profileUser.id)
                                ? "incoming_msg"
                                : "outgoing_msg"
                            }`}
                          >
                            <img
                              width=""
                              height=""
                              src={`${item.message_body}`}
                              alt="message_image"
                              className="w-100 h-auto"
                            />
                          </div>
                        </>
                      )}
                    </>
                  );
                })
              : null}
          </div>
        </div>
        <div className="chat_footer">
          <form
            action="file.php"
            id="form"
            onSubmit={(e) => handleSubmit(e)}
            autoComplete="off"
          >
            <div className="cstm_file_uploader">
              <input
                type="file"
                id="file"
                onChange={onChangeImage}
                accept="image/png, image/gif, image/jpeg"
              />
              <i className="fas fa-plus"></i>
            </div>
            <input
              className="form-control"
              type="text"
              placeholder="Type a message..."
              id="messageData"
              name="messageData"
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <input
              className="send_btn"
              type="submit"
              value="Send"
              placeholder="Type a message..."
              onKeyDown={(e) => handleSubmit(e)}
            ></input>
            <img
              width=""
              height=""
              src="https://cdn-icons-png.flaticon.com/512/736/736212.png"
              className="send_icon"
              onClick={handleSubmit}
              alt="send icon"
            />
          </form>
        </div>
      </div>
      <Modal
        displayModal={modalImage}
        closeModal={onHide}
        modalBody={
          <>
            <div className="modal-body">
              <div className="modalcontent">
                <div>
                  {imageFile !== null &&
                    imageFile.length !== 0 &&
                    imageFile.length > 0}
                  {
                    <img
                      width=""
                      height=""
                      id="blah"
                      src={imageFile}
                      className="w-100"
                      alt="file icon"
                    />
                  }
                </div>
                <div className="modal-button">
                  <button
                    type="submit"
                    className="btn btn-light"
                    onClick={onHide}
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleChat}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default ChatDesign;
