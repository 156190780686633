import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
// import './i18n';
import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './redux/store'
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import common_en from "./translations/en/translation.json"
import common_vi from "./translations/vi/translation.json";
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)


  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    fallbackLng: 'en',
    react: {
      // Turn off the use of React Suspense
      useSuspense: false
    },                           // language to use
    resources: {
      en: {
        common: common_en               // 'common' is our custom namespace
      },
      vi: {
        common: common_vi
      },
    },
  });
React.icons = icons
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
