import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { CLink } from "@coreui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Loader from "../../../Components/loader/index";
import ImageGallery from "react-image-gallery";
import scale from "../../../../assets/images/scale.png";
import size from "../../../../assets/images/size_square.png";
import Modal from "../confirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";

const MyProductView = (props) => {
  const { t } = useTranslation("common");
  const [productimg, setProductimg] = useState("");
  const [productname, setProductname] = useState("");
  const [productdata, setProductdata] = useState("");
  const [loading, setLoading] = useState(true);
  const [productdes, setProductDes] = useState("");
  const [symbol, setSymbol] = useState("$");
  const history = useHistory();
  const [show, setShow] = useState(false);
  let token = localStorage.getItem("accessToken");
  // const [perQuantityPrice, setPerQuantityPrice] = useState("");
  const lang = localStorage.getItem("language");
  useEffect(() => {
    let k = window.location.href.split("/").length - 1;
    let id = window.location.href.split("/")[k];
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/my-products-list/?id=${id}&lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setProductdata(res.data.data);
        setProductname(res.data.data.product_name);
        setProductDes(res.data.data.product_name[0].prodes);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    let arr = [];
    if (productdata) {
      if (productdata.image?.length === 0) {
        arr.push({
          original:
            "https://developersushant.files.wordpress.com/2015/02/no-image-available.png",
          thumbnail:
            "https://developersushant.files.wordpress.com/2015/02/no-image-available.png",
        });

        setLoading(false);
      } else {
        for (let i = 0; i < productdata.image.length; i++) {
          arr.push({
            original: productdata.image[i],
            thumbnail: productdata.image[i],
          });
          setLoading(false);
        }
      }

      if (productdata.price_currency === "DONG") {
        setSymbol("₫");
      }
    }
    //   if (productdata.pricing_type == "FIXED") {
    //       setPerQuantityPrice(productdata.price_to);
    //   }
    setProductimg(arr);
    //eslint-disable-next-line
  }, [loading]);

  const onCancel = () => {
    setShow(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="dash_product_details row mb-4">
          <div className="text-right">
            <button
              htmltype="submit"
              onClick={() => history.push(`/editproduct/${productdata.id}`)}
              className="btn btn-primary"
            >
              {t("productView.editproduct")}
            </button>
          </div>
          <div className="col-lg-6">
            <ImageGallery
              items={productimg}
              thumbnailPosition="left"
              showPlayButton={false}
              disableKeyLeft={false}
            />
          </div>
          <div className="col-lg-6">
            <div className="productDetail">
              <>
                {productname.map((item) =>
                  item.language.code === lang ? <h1>{item.name}</h1> : null
                )}
              </>
              <h6>{productdes}</h6>
              <ul>
                <li>
                  <img
                    width=""
                    height=""
                    src={size}
                    className="icon-img"
                    alt="icon-img"
                  />
                  {t("productView.sizeFarm")}: &nbsp;
                  <span>
                    {productdata.area} {productdata.area_unit}
                  </span>
                </li>
                <li>
                  <img
                    width=""
                    height=""
                    src={scale}
                    className="icon-img"
                    alt="icon-img"
                  />
                  {t("productView.weight")}:&nbsp;
                  <span>
                    {productdata.total_quantity}{" "}
                    {productdata.total_quantity_unit}
                  </span>
                </li>
              </ul>

              {productdata.pricing_type === "FIXED" ? (
                <>
                  <div className="price">
                    {t("productView.priceFixed")}:
                    <span>
                      {" "}
                      {symbol} {productdata.price_to}
                      <sup>{productdata.total_quantity_unit}</sup>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <h6>{t("productView.priceRange")}:</h6>{" "}
                  <h4>
                    {" "}
                    {symbol} {productdata.price_from}
                    <sup>{productdata.total_quantity_unit}</sup>- {symbol}{" "}
                    {productdata.price_to}
                    <sup>{productdata.total_quantity_unit}</sup>
                  </h4>
                </>
              )}
            </div>
            <h3>{t("productView.description")}</h3>
            <p>{productdata.description}</p>
          </div>
        </div>
      )}{" "}
      <Modal
        displayModal={show}
        closeModal={() => onCancel()}
        modalBody={
          <>
            <div className="modal-body p-4">
              <div className="modalcontent">
                <h3>Oops you need to Login/Register for Buy this Product</h3>
                <h5 className="mt-4">
                  Please Check here for{" "}
                  <CLink className="head" to="/login">
                    Login
                  </CLink>
                </h5>
                <h4>OR</h4>
                <h5>
                  {" "}
                  Do you want to{" "}
                  <CLink className="head" to="/signup">
                    Register
                  </CLink>
                </h5>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default MyProductView;
