import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Components/loader/index";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const OrderHistoryView = (props) => {
  const { t } = useTranslation('common');
  const paramId = props.match.params.id;
  const url = props.match.url;
  const currentValue = url.split("/");
  const current_value = currentValue[currentValue.length - 2];
  const [body, setBody] = useState();
  const [loading, setLoading] = useState(true);

  let token = localStorage.getItem("accessToken");
  const lang = localStorage.getItem("language");

  const getData = () => {
    if (current_value === "order-b") {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-b/?id=${paramId}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => toast.error(err.response.data.detail));
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}payment/order-s/?id=${paramId}&lang=${lang}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBody(res.data.data);
          setLoading(false);
        })
        .catch((err) => toast.error(err.response.data.detail));
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row contracts">
            <div className="headingWrap">
              <h3 className="title">{t("order.orderView.title")}</h3>
            </div>

            <div className="contract-grid">
              <p>
                {t("order.orderView.total_paid")}:<span>{body.total_paid}</span>
              </p>
              <p>
                {t("order.orderView.earnest_amount")}:
                <span>{body.earnest_amount}</span>
              </p>

              <p>
                {t("order.orderView.final_amount")}:
                <span>{body.final_amount}</span>
              </p>

              <p>
                {t("order.orderView.status")}:<span>{body.status}</span>
              </p>

              <p>
                {t("order.orderView.cutofPayment")}

                <span>{body.cut_off_payment}</span>
              </p>

              <p>
                {t("order.orderView.currency")}
                <span>{body.currency}</span>
              </p>
              <p>
                {t("order.orderView.unit")}:<span>{body.unit}</span>
              </p>
              <p>
                {t("order.orderView.quantity")}:<span>{body.quantity}</span>
              </p>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <h4>{t("order.orderView.billingAddress")}</h4>
                <p>
                  {t("order.orderView.address")}:
                  <span>{body.billing.address}</span>
                </p>
                <p>
                  {t("order.orderView.city")}:<span>{body.billing.city}</span>
                </p>
                <p>
                  {t("order.orderView.state")}:<span>{body.billing.state}</span>
                </p>
                <p>
                  {t("order.orderView.postal_code")}:
                  <span>{body.billing.postal_code}</span>
                </p>
                <p>
                  {t("order.orderView.country")}:
                  <span>{body.billing.country}</span>
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <h4>{t("order.orderView.shippingAddress")}</h4>
                <p>
                  {t("order.orderView.address")}:
                  <span>{body.shipping.address}</span>
                </p>
                <p>
                  {t("order.orderView.city")}:<span>{body.shipping.city}</span>
                </p>
                <p>
                  State:{t("order.orderView.state")}
                  <span>{body.shipping.state}</span>
                </p>
                <p>
                  {t("order.orderView.postal_code")}:
                  <span>{body.shipping.postal_code}</span>
                </p>
                <p>
                  {t("order.orderView.country")}:
                  <span>{body.shipping.country}</span>
                </p>
              </div>
              {body.backout && (
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <h4> {t("order.orderView.backout")}:</h4>
                  <p>
                    {t("order.orderView.backoutDate")}:
                    <span>{body.backout_date}</span>
                  </p>
                  <p>
                    {t("order.orderView.backoutReason")}:
                    <span>{body.backout_reason}</span>
                  </p>
                </div>
              )}
              <div
                className={
                  body.backout === false
                    ? "col-lg-4 col-md-4 col-sm-6"
                    : "col-12"
                }
              >
                <h4> {t("order.orderView.product")}</h4>
                <div className={body.backout === false ? "d-block" : "d-flex"}>
                  <p>
                    {t("order.orderView.productName")}:
                    {body.product.product_name.map((item) =>
                      item.language.code === lang ? (
                        <span> {item.name}</span>
                      ) : null
                    )}
                  </p>
                  <p>
                    {t("order.orderView.productCode")}:
                    <span>{body.product.product_code}</span>
                  </p>
                  <p>
                    {t("order.orderView.createdby")}
                    <span>
                      {body.product.user.username.charAt(0).toUpperCase() +
                        body.product.user.username.slice(1)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </>
      )}
    </>
  );
};
export default OrderHistoryView;
