import React, { Fragment } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "./testTable.css";

function TestTable(props) {

    const data = props.bodyData;
    const columns = props.headData;
    const paginationStatus = props.paginationStatus || false;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        prepareRow,
        pageCount,
        nextPage,
        gotoPage,
        previousPage,
        state: { pageIndex }
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: props.rowPerPage }
        },
        useSortBy,
        usePagination
    );


    return (
        <Fragment>
            <table {...getTableProps()} className="testTables">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="tableHeadRow">
                            {headerGroup.headers.map(column => (
                                <th
                                    className="tableHead"
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    {column.render("Header")}
                                    {/* <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <span className="dropArrow">{arrowDropDown}</span>
                                            ) : (
                                                <span className="dropArrow">{arrowDropDown}</span>
                                            )
                                        ) : (
                                            <span className="dropArrow">{arrowDropDown}</span>
                                        )}
                                    </span> */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="tableDataRow">
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            onClick={() => {
                                                if (cell.column.id !== "0" && cell.column.id !== "10") {
                                                    props.rowId(row.original.id);
                                                }
                                            }}
                                            className="tableData"
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {paginationStatus ? (
                <div className="TablePagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {"<<"}
                    </button>{" "}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {"<"}
                    </button>
                    <span className="pageCount">
                        <span className="currentPage">{pageIndex + 1}</span> of{" "}
                        {pageOptions.length}
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {">"}
                    </button>{" "}
                    <button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </button>{" "}
                    <span>
                        |&nbsp;&nbsp;Go to page :&nbsp; {"  "}
                        <input
                            className="pageInput"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(page);
                            }}
                            style={{ width: "30px" }}
                        />
                    </span>{" "}
                </div>
            ) : null}
        </Fragment>
    );
}

export default TestTable;
