import React, { useState, useEffect } from "react";
import { CLink } from "@coreui/react";
import { useHistory } from "react-router";
import OwlCarousel from "react-owl-carousel";
import "./index.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Auth from "../../../Auth";
import axios from "axios";
import SearchBar from "./searchbox";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NoImage from "../../../assets/images/no-image.jpg";
import Loader from "src/views/Components/loader";
import author from "../../../assets/images/profile.jpeg";
import star from "../../../assets/images/star.png";
import testiBack from "../../../assets/images/testimonial.png";
import buyer from "../../../assets/images/buyer.png";
import service_Provider from "../../../assets/images/service-provider.png";
import seller from "../../../assets/images/seller.png";
import icon from "../../../assets/images/icon.png";
import iconOne from "../../../assets/images/icon-one.png";
import iconThree from "../../../assets/images/icon-two.png";
import iconFour from "../../../assets/images/icon-three.png";
import ReactStars from "react-rating-stars-component";
import plOne from "../../../assets/images/pl1.png";
import plTwo from "../../../assets/images/pl2.png";
import plThree from "../../../assets/images/pl3.png";
import plFour from "../../../assets/images/pl4.png";
import plFive from "../../../assets/images/pl5.png";
import plSix from "../../../assets/images/pl6.png";
const Index = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  library.add(faPlay);
  const [body, setBody] = useState([]);
  // const [title, setTitle] = useState([]);
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [symbol, setSymbol] = useState("$");
  const [about, setAbout] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [feeds, setFeeds] = useState([]);
  let getLang = localStorage.getItem("language");
  const responsiveOption = {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 4,
    },
    700: {
      items: 5,
    },
    1000: {
      items: 5,
    },
  };
  const responsiveOptionProduct = {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/category-subcategory/?type=SERVICE&lang=${getLang}`
      )
      .then((res) => {
        setBody(res.data.data);
        // setTitle(res.data.data.title);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=Index&lang=${getLang}`
      )
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=Testimonials&lang=${getLang}`
      )
      .then((res) => {
        setTestimonial(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}website/feeds/?lang=${getLang}`)
      .then((res) => {
        setFeeds(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          for (let i of err.response.data.message) {
            toast.error(`${i.error}`);
          }
        }
      });
  }, [getLang, loading]);

  const handleCategoryshow = (value) => {
    history.push({ pathname: `/userList/${value}` });
  };
  const relatedView = (id) => {
    history.push(`/products/${id}`);
  };
  let permissions = localStorage.getItem("permissions");
  const serviceProvider = () => {
    if (permissions === "BUYER") {
      history.push({ pathname: "/signup" });
      localStorage.clear();
      localStorage.removeItem("accessToken");
      Auth.signout();
    } else {
      history.push({ pathname: "/signup" });
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}inventory/product/?limit=10&lang=${getLang}`
      )
      .then((res) => {
        setProduct(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    if (product.price_currency === "DONG") {
      setSymbol("₫");
    }
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}website/page/?title=About us&language=${getLang}`
      )
      .then((res) => {
        setAbout(res.data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="hm-banner d-flex justify-content-center align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="hm-banner-content text-center">
                    {data.map((item, i) => {
                      return (
                        <div key={i}>
                          {item.language.code === getLang
                            ? item.section.map((el, index) => {
                                return el.name === "header_section" ? (
                                  <div className="w-100" key={index}>
                                    <h1 className="ban-heading">{el.title}</h1>
                                    <p className="text-white mt-5">
                                      {el.subtitle}
                                    </p>
                                  </div>
                                ) : null;
                              })
                            : null}
                        </div>
                      );
                    })}
                    <div className="cstm_header">
                      <SearchBar />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="first-hm-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span className="web-title">
                    {" "}
                    {t("indexPage.section2.title")}
                  </span>
                  <h2 className="web-heading mt-3">
                    {t("indexPage.section2.sub_title")}
                  </h2>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="single-user text-center px-4">
                    <img alt="" src={buyer} />
                    <h6 className="mt-3">{t("indexPage.section2.buyer")}</h6>
                    <p className="web-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum su
                    </p>
                    <CLink to="/buyer">
                      <button className="web-btn mt-3">
                        {" "}
                        {t("indexPage.section2.buyerText")}
                      </button>
                    </CLink>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="single-user text-center px-4">
                    <img alt="" src={service_Provider} />
                    <h6 className="mt-3">
                      {t("indexPage.section2.service-provider")}
                    </h6>
                    <p className="web-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum su
                    </p>
                    <CLink to="/serviceprovider">
                      <button className="web-btn mt-3">
                        {t("indexPage.section2.serviceText")}
                      </button>
                    </CLink>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="single-user text-center px-4">
                    <img alt="" src={seller} />
                    <h6 className="mt-3">{t("indexPage.section2.seller")}</h6>
                    <p className="web-text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Quis ipsum su
                    </p>
                    <CLink to="/seller">
                      {" "}
                      <button className="web-btn mt-3">
                        {t("indexPage.section2.sellerText")}
                      </button>
                    </CLink>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="how-work">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="web-heading">How It work</h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-4">
                  <div className="hw-content text-start px-5">
                    <h1>01</h1>
                    <b>{t("indexPage.cropSection.title1")}</b>
                    <p className="text">
                      {t("indexPage.cropSection.description1")}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <div className="hw-icon text-center d-flex justify-content-center align-items-center">
                    <img alt="" src={icon} />
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <div className="hw-icon text-center d-flex justify-content-center align-items-center mt-5">
                    <img alt="" src={iconOne} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="hw-content text-start px-5 mt-5">
                    <h1>02</h1>
                    <b>{t("indexPage.cropSection.title2")}</b>
                    <p className="text">
                      {t("indexPage.cropSection.description2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="hw-content text-start px-5">
                    <h1>03</h1>
                    <b>{t("indexPage.cropSection.title3")}</b>
                    <p className="text">
                      {t("indexPage.cropSection.description3")}
                    </p>
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <div className="hw-icon text-center d-flex justify-content-center align-items-center">
                    <img alt="" src={iconThree} />
                  </div>
                </div>
                <div className="col-md-2 d-flex justify-content-center align-items-center">
                  <div className="hw-icon text-center d-flex justify-content-center align-items-center mt-5">
                    <img alt="" src={iconFour} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="hw-content text-start px-5 mt-5">
                    <h1>04</h1>
                    <b>{t("indexPage.cropSection.title4")}</b>
                    <p className="text">
                      {t("indexPage.cropSection.description4")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className=" align-items-center">
            {about.map((item, index) => {
              return (
                <div key={index}>
                  {item.language.code === getLang
                    ? item.section.map((el) => {
                        return (
                          <div className=" justify-content-between align-items-center">
                            {el.name === "about_us_header_section" ? (
                              <>
                                <div className="" data-aos="fade-up">
                                  <div className="img_outer">
                                    <div className="ab_img">
                                      <iframe
                                        className="responsive-iframe"
                                        src={el.youtube_link}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="about_text col-md-5 ml-auto"
                                  data-aos="fade-up"
                                >
                                  <div className="web_heading">
                                    <div className="web-heading">
                                      {t("indexPage.section2.about_heading")}{" "}
                                    </div>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: `${el.description_html}`,
                                      }}
                                    ></p>
                                    <CLink
                                      href="/aboutus"
                                      className="btn web-btn"
                                    >
                                      {t("indexPage.section2.about_button")}
                                    </CLink>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })}
            {/* <video autoplay muted loop id="myVideo">
              <source
                src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
                type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </video> */}
          </div>

          {/* <section className="tracktor-ban-sec d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <img  alt="" src={play} />
                </div>
                <div className="col-md-5">
                  <h2 className="web-heading">
                    The right farm marketplace for all needs.
                  </h2>
                  <p className="mt-3">
                    Cho.direct is an effective platform and application that
                    helps farmers consult and exchange with the community, feel
                    secure to find more output for consumption products at the
                    best competitive prices in the market. To create a unified
                    national market for agricultural commodities{" "}
                  </p>
                  <button className="web-btn mt-5">
                    Read About Us <FontAwesomeIcon icon={faRightLong} />
                  </button>
                </div>
              </div>
            </div>
          </section> */}

          <section className="service-provider">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <span className="web-title">
                    {" "}
                    {t("indexPage.section2.service_category")}
                  </span>
                  <h2 className="web-heading mt-3">
                    Various services <br />
                    facilitating your farming needs.
                  </h2>
                </div>
                <div className="col-md-3 d-flex align-items-end text-end ">
                  <button className="web-btn" onClick={() => serviceProvider()}>
                    {t("indexPage.section2.service_category_button")}
                  </button>
                </div>
                <div className="col-md-12 mt-5">
                  <OwlCarousel
                    className="owl-theme service"
                    margin={10}
                    items={4}
                      loop={true}
                    dots={true}
                    autoplayTimeout={3000}
                    autoplay={true}
                    responsiveClass={true}
                    responsive={responsiveOption}
                  >
                    {body.map((value, index) => {
                      return value?.title.map((item) => {
                        return item.language.code === getLang ? (
                          <div
                            className="item"
                            onClick={() => handleCategoryshow(value.id)}
                          >
                            <div className="provider text-center mt-5">
                              <div className="provider-img d-flex justify-content-center align-items-center mx-auto">
                                <img
                                  alt=""
                                  src={`${value.logo}`}
                                />
                              </div>
                              <div className="provider-text mt-3">
                                <p>{item.name}</p>
                              </div>
                            </div>
                          </div>
                        ) : null;
                      });
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>

          <section className="greenery d-flex justify-content-center align-items-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  {data.map((item, i) => {
                    return (
                      <div key={i}>
                        {item.language.code === getLang
                          ? item.section.map((el, index) => {
                              return el.name === "analytic_header" ? (
                                <div
                                  className="col-lg-12"
                                  data-aos="fade-up"
                                  key={index}
                                >
                                  <h2 className="web-heading mt-3">
                                    {el.title}
                                  </h2>
                                </div>
                              ) : null;
                            })
                          : null}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row mt-5">
                {data.map((item, i) => {
                  return (
                    <>
                      {item.language.code === getLang
                        ? item.section.map((el, index) => {
                            return (
                              <div
                                className="col-md-4 col-4 text-center"
                                key={index}
                              >
                                {el.name === "analytic_business_owner" && (
                                  <div className="facts_box" data-aos="zoom-in">
                                    <h1 className="web-heading">
                                      {el.title} +
                                    </h1>
                                    <h6>{el.subtitle}</h6>
                                  </div>
                                )}

                                {el.name === "analytic_farmer" && (
                                  <div className="facts_box" data-aos="zoom-in">
                                    <h1 className="web-heading">
                                      {el.title} +
                                    </h1>
                                    <h6>{el.subtitle}</h6>
                                  </div>
                                )}

                                {el.name === "analytic_service_provider" && (
                                  <div className="facts_box" data-aos="zoom-in">
                                    <h1 className="web-heading">
                                      {el.title} +
                                    </h1>
                                    <h6>{el.subtitle}</h6>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        : null}
                    </>
                  );
                })}
              </div>
            </div>
          </section>

          <section className="products">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span className="web-title">
                    {" "}
                    {t("indexPage.productSection.title")}
                  </span>
                  <h2 className="web-heading mt-3">
                    {" "}
                    {t("indexPage.productSection.sub_title")}
                  </h2>
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-12">
                  <OwlCarousel
                    className="owl-theme product"
                    loop
                    margin={10}
                    items={3}
                    autoplay={true}
                    responsiveClass={true}
                    responsive={responsiveOptionProduct}
                  >
                    {product.map((item, index) => {
                      return (
                        <>
                          <div className="item">
                            <div
                              className="single-product p-4 mt-5"
                              onClick={() => relatedView(item.id)}
                            >
                              <img
                                alt=""
                                className="latest_product_image"
                                src={
                                  item.image !== null && item.image.length > 0
                                    ? item.image[0]
                                    : NoImage
                                }
                              />
                              <div className="d-flex justify-content-between align-items-center mt-4">
                                <div className="d-flex justify-content-start align-items-center">
                                  <img alt="" src={author} className="author" />
                                  <div className="d-block auth-detail ms-2">
                                    <p>{item?.user.first_name}</p>
                                  </div>
                                </div>
                                <div className="auth-star d-flex justify-content-start align-items-center">
                                  {/* <img className="me-2" src={star} /> */}
                                  <ReactStars
                                    size={15}
                                    count={5}
                                    value={
                                      item.user?.rating.length === 0
                                        ? 0
                                        : item.user?.rating.rate__avg
                                    }
                                    edit={false}
                                    a11y={true}
                                    isHalf={true}
                                    emptyIcon={<i className="fas fa-star"></i>}
                                    halfIcon={
                                      <i className="fas fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fas fa-star"></i>}
                                    activeColor="#fcd804"
                                  />
                                  <span className="text">
                                    {item.user?.rating.rate__avg !== null
                                      ? Number(
                                          item.user?.rating.rate__avg
                                        ).toFixed(2)
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <h6 className="mt-4">
                                {item?.product_name.map((el, index) =>
                                  el.language.code === getLang ? (
                                    <span key={index}>
                                      {" "}
                                      {el.name.length > 10
                                        ? el.name.substring(0, 10) + "..."
                                        : el.name}
                                    </span>
                                  ) : null
                                )}
                              </h6>
                              {/* <p className="product-detail">
                                <span>1 Hecta</span> / <span>6 tons</span>
                              </p> */}
                              <div className="product-price-bar d-flex justify-content-between align-items-center">
                                <h5>
                                  {item?.pricing_type === "FIXED" ? (
                                    <span>
                                      {" "}
                                      {symbol} {item?.price_to} /KG
                                    </span>
                                  ) : (
                                    <>
                                      <span>
                                        {" "}
                                        {symbol} {item?.price_from} - {symbol}{" "}
                                        {item?.price_to} /KG
                                      </span>
                                    </>
                                  )}
                                </h5>
                                <button onClick={() => relatedView(item.id)}>
                                  View Product
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>

          <section className="testimonials">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <span className="web-title">
                    {t("indexPage.testimonial_title")}
                  </span>
                  <h2 className="web-heading">Customer Testimonials</h2>
                </div>
              </div>
              <div className="row testimonial-back align-items-center justify-content-center mt-5">
                <img alt="" src={testiBack} />
                <img className="plone" src={plOne} />
                <img className="plTwo" src={plTwo} />
                <img className="plThree" src={plThree} />
                <img className="plFour" src={plFour} />
                <img className="plFive" src={plFive} />
                <img className="plSix" src={plSix} />
                <div className="col-md-6 testi-tp-col">
                  <div className="testi">
                    <OwlCarousel
                      className="owl-theme testi"
                      nav={true}
                      loop
                      margin={10}
                      items={1}
                      autoplay={true}
                    >
                      {testimonial?.map((item, index) => {
                        return (
                          <>
                            {item?.language.code === getLang
                              ? item?.section?.map((el) => {
                                  return (
                                    <div className="item" key={index}>
                                      <div className="single-testi">
                                        <div className="d-flex justify-content-start align-items-center">
                                          <img
                                            alt=""
                                            className="me-4"
                                            src={`${el?.image_upload}`}
                                          />
                                          <div>
                                            <p>{el.title}</p>
                                            <img alt="" src={star} />
                                          </div>
                                        </div>
                                        <p
                                          className="text mt-4"
                                          dangerouslySetInnerHTML={{
                                            __html: `${el?.description_html}`,
                                          }}
                                        ></p>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="feeds">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="web-heading"> {t("indexPage.feed_title")}</h2>
                </div>
                <div className="col-md-12 nmm mt-5">
                  <OwlCarousel
                    className="owl-theme"
                    autoplay={true}
                    loop
                    margin={10}
                    // items={3}
                    dots={false}
                    responsive={responsiveOptionProduct}
                    nav
                  >
                    {feeds.length > 0 ? (
                      <>
                        {feeds?.map((item, index) => {
                          return (
                            <>
                              {/* {item?.language.code === getLang ? ( */}
                              <div className="item" key={index}>
                                <div className="single-feed py-4">
                                  <h6 className="mb-4">{item.title}</h6>
                                  <p className="text">{item.description}</p>
                                  <div className="feeder-btn d-flex justify-content-between align-items-center mt-4">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <img
                                        alt=""
                                        src={author}
                                        className="author"
                                      />
                                      <div className="ms-3">
                                        <p>{item.author}</p>
                                        <span>Admin</span>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="feed-date">Jan 02 2022</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* ) : null} */}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <p>No feeds </p>
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Index;
